import {BuildBasics} from 'Common/BuildBasics';

//import { Home as OldFrontendHome } from 'Shared/view/frontend/Home';
import { Home as NewFrontendHome} from 'Shared/view/frontend/Home2';
//import { Home as DifferentDrummerFrontendHome} from 'Shared/view/frontend/Home2';
//import { Home as OldBackendHome } from 'Shared/view/backend/Home';
import { Home as NewBackendHome} from 'Shared/view/backend/Home2';
//import { Home as DifferentDrummerBackendHome} from 'Shared/view/backend/DifferentDrummerHome';

import { Event as FrontendEvent } from 'Shared/view/frontend/Event';
import { Events as FrontendEvents } from 'Shared/view/frontend/Events';
import { Menu as FrontendMenu } from 'Shared/view/frontend/Menu';
import { Hire as FrontendHire } from 'Shared/view/frontend/Hire';
import { Contact as FrontendContact} from 'Shared/view/frontend/Contact';
import { Reservations as FrontendReservations } from 'Shared/view/frontend/Reservations';
import { HireEnquiry as FrontendHireEnquiry } from 'Shared/view/frontend/HireEnquiry';
import {StaffResource as FrontendStaffResource} from 'Shared/view/frontend/StaffResource';
import {StaffResources as FrontendStaffResources} from 'Shared/view/frontend/StaffResources';
//import { NavDemos as Nav } from 'Shared/view/NavDemos';

import { Venue as BackendVenue } from 'Shared/view/backend/Venue';
import { Event as BackendEvent } from 'Shared/view/backend/Event';
import { Events as BackendEvents } from 'Shared/view/backend/Events';
import {ListMenu as BackendListMenu} from 'Shared/view/backend/ListMenu';
import {Menus as BackendMenus} from 'Shared/view/backend/Menus';
import {MenuDesigner as BackendMenuDesigner} from 'Shared/view/backend/MenuDesigner';
import { MenuSection as BackendMenuSection } from 'Shared/view/backend/MenuSection';
import { MenuItem as BackendMenuItem } from 'Shared/view/backend/MenuItem';
import { Hire as BackendHire } from 'Shared/view/backend/Hire';
import {Specials as BackendSpecials} from 'Shared/view/backend/Specials';
import {Special as BackendSpecial} from 'Shared/view/backend/Special';
import { Contact as BackendContact } from 'Shared/view/backend/Contact';
import {StaffResource as BackendStaffResource} from 'Shared/view/backend/StaffResource';
import {StaffResources as BackendStaffResources} from 'Shared/view/backend/StaffResources';
import {Settings} from 'Shared/view/backend/Settings';
import { PageNotFound as BackendPageNotFound } from 'Shared/view/backend/PageNotFound';


import {VenueBasics} from 'Common/VenueBasics';
//import { Poster } from 'Shared/view/backend/Poster';
//import { Venue } from 'Shared/view/backend/Venue';
//import { Course } from 'Shared/view/backend/Course';
import {IPageData, IPageParams, PageConfig} from 'Common/PageConfig';
import {IRoutesProvider} from 'Common/IRoutesProvider';
import {PageSettings} from 'Shared/view/VenueSettingsType';
import {Login} from 'Shared/view/backend/Login';


export type VenuePageConfigClass = new (inBrowser:boolean,build:BuildBasics,venue:VenueBasics) => PageConfig<any,IPageParams>	//XXX any


type VenueRouteDetails = [ 
	VenuePageConfigClass,
	(stringParams:{[key:string]:string}) => IPageData['params']
];

export type VenueRoutesType = {[name:string]:VenueRouteDetails}; 



export class VenueRoutes implements IRoutesProvider
{
	constructor(
		protected inBrowser:boolean,
		protected build:BuildBasics,
		protected venue:VenueBasics
	)
	{ }

	routes(): VenueRoutesType
	{
//TODO add redirect route for all trailing /s here. Currently a hack is fixed in Server.resolveRoute()

		return <VenueRoutesType>({
			...this.frontend(),
			...this.backend(),
			...this.navDemos(),

//TODO want a frontend page not found page. Doing this prevents '/logout' (and probably other routes from reaching the server router though).
//     Could possibly render and call the page not found page directly from the Server.ts file
//			'/*':	[new FrontendPageNotFound(this.inBrowser,this.urls,this.times),() => ({}) ],
		});
	}

//TODO maybe trim trailing / from all...

	frontend()
	{
//XXX mabe use the view to create the pages using closures...	
//		const view = (new View()).view();

//XXX cf returning closures here instead, or using a switch statement
//TODO remove the anys... need to hook up the page param types somehow

		return <VenueRoutesType>({
			'/':					[frontendHomePage(this.venue.document.settings.pages.home), ()=>({})],
			'/menu':				[FrontendMenu,() => ({slug:'menu'})],
			'/events':				[FrontendEvents,() => ({})],
			'/event/:slug':			[FrontendEvent, params=>({slug:params.slug})],
			'/contact':				[FrontendContact,() => ({})],
			'/hire':				[FrontendHire,() => ({})],
			'/reservation':			[FrontendReservations,() => ({})],
			'/hire-enquiry':		[FrontendHireEnquiry,() => ({})],
			'/staffResources':		[FrontendStaffResources, ()=>({})],
			'/staffResource/:id':	[FrontendStaffResource, params=>({id:params.id})] 
		});
	}

	backend()
	{
		return <VenueRoutesType>({
			...this.backendEvents(),
			...this.backendMenu(),
			...this.backendSpecials(),

//XXX Required for Vite...
//			'/login': 				[Login, ()=>({})],

			'/admin': 				[BackendVenue, ()=>({})],

			'/admin/home': 			[backendHomePage(this.venue.document.settings.pages.home), ()=>({})],
			'/admin/home/:tab': 	[backendHomePage(this.venue.document.settings.pages.home), params=>({tab:params.tab})],

			//'/admin/poster':		[Poster, ()=>({})],

			'/admin/hire': 			[BackendHire, ()=>({})],
			'/admin/hire/:tab':		[BackendHire, params=>({tab:params.tab})],
			'/admin/contact': 		[BackendContact, ()=>({})],
			'/admin/contact/:tab':	[BackendContact, params=>params],


			'/admin/staffResources':	[BackendStaffResources, ()=>({})],
			'/admin/staffResource/:id':	[BackendStaffResource, params=>({id:params.id})],

			'/admin/settings': 		[Settings, ()=>({})],
/*
{ route:'/admin/people', page:'backend/people' },
{ route:'/admin/person/:id', page:'backend/person' },
*/


//TODO return 404			
			'/admin/*':				[BackendPageNotFound,() => ({})],
		});
	}

	backendEvents()
	{
		return <VenueRoutesType>({
			'/admin/events': [BackendEvents, ()=>({})],

			'/admin/event/:slug': [BackendEvent, params=>({slug:params.slug})],

			'/admin/event/:slug/:topTab':
				[BackendEvent, params => ({...params,slug:params.slug})],

			'/admin/event/:slug/:topTab/:secondTab':
				[BackendEvent, params => ({...params,slug:params.slug})],

			'/admin/event/:slug/:topTab/:secondTab/:thirdTab':
				[BackendEvent, params => ({...params,slug:params.slug})],
		});
	}

	backendMenu()
	{
		return <VenueRoutesType>({
			'/admin/listMenu/:slug': 	[BackendListMenu, params=>({slug:params.slug}) ],

			'/admin/menus': 			[BackendMenus, ()=>({})],
			'/admin/menu-designer': 	[BackendMenuDesigner, ()=>({})],

			/* Unclassified items: */
			'/admin/menus/:slug':		[BackendMenuSection, params=>({slug:params.slug,sectionIds:[]})],

			'/admin/menus/:slug/*':		[BackendMenuSection, 
				params => { return {slug:params.slug,sectionIds:extractSectionIds(params),tail:params._ ?? params[0]} }],

			'/admin/menu-item/:slug/*':		[BackendMenuItem, 
				params => {
					const sectionIds = extractSectionIds(params);
					return {slug:params.slug,sectionIds:sectionIds.slice(0,-1),item:sectionIds[sectionIds.length-1],tail:params._ ?? params[0]};
				} ],
		});
	}

	backendSpecials()
	{
		return <VenueRoutesType>({
			'/admin/specials': 		[BackendSpecials, ()=>({})],
			'/admin/special/:id':	[BackendSpecial, params=>({id:params.id})],
		});
	}

	navDemos()
	{
		return <VenueRoutesType>({
			//'/navDemos': 									[Nav.index, ()=>{}],
			//'/navDemos/homepageExample1':					[Nav.homepageExample1, ()=>{}],
			//'/navDemos/homepageExample2':					[Nav.homepageExample2, ()=>{}],
			//'/navDemos/logoToSideOfTitleAndNav':			[Nav.logoToSideOfTitleAndNav, ()=>{}],
			//'/navDemos/logoToSideOfTitleAndNavCollapsed':	[Nav.logoToSideOfTitleAndNavCollapsed, ()=>{}],
			//'/navDemos/shrinkingNavOverflowingLogo':		[Nav.shrinkingNavOverflowingLogo, ()=>{}],
			//'/navDemos/shrinkingNavOverflowingLogoCollapsed': [Nav.shrinkingNavWithLogoCollapsed, ()=>{}],
			//'/navDemos/shrinkingNavWithLogo':				[Nav.shrinkingNavWithLogo, ()=>{}],
			//'/navDemos/shrinkingNavWithLogoCollapsed':		[Nav.shrinkingNavWithLogoCollapsed, ()=>{}],
			//'/navDemos/stickyNavOnParallaxBanner':			[Nav.stickyNavOnParallaxBanner, ()=>{}],
			//'/navDemos/stickyNavOnParallaxBannerCollapsed':	[Nav.stickyNavOnParallaxBannerCollapsed, ()=>{}],
		});
	}
}


function extractSectionIds(params)
{
	/* params._ is used server side for the 'rest', params.0 is used browser side */
	const tail = params._ ?? params[0];

	const parts = [];
	for (const bit of tail.split('/')) {
		const segments = bit.split('-');
		parts.push({name:segments[0],index:Number(segments[1])});
	}
	return parts;
}

//TODO probably move to different file

export function frontendHomePage(homeSettings:PageSettings)
{
	switch (homeSettings.type) {
		case 'standardHome':			return NewFrontendHome;					//TODO rename to FrontendHome
		case 'differentDrummerHome':	return NewFrontendHome;	//FIXME CJ replaced with standardHome but still needed to be mapped to differentDrummerHome to work 
		case 'oldHome':					return NewFrontendHome;
	}
}

export function backendHomePage(homeSettings:PageSettings)
{
	switch (homeSettings.type) {
		case 'standardHome':			return NewBackendHome;					//TODO rename
		case 'differentDrummerHome':	return NewBackendHome;		//FIXME replace
		case 'oldHome':					return NewBackendHome;
	}
}

