import FileTypes from 'Browser/FileTypes';
import {ILinks,Urls,hostUrl} from 'Common/Urls';


export class ArtistUrls extends Urls
{
	/* Some test systems use a mix of dev images and production images (readonly). */
	imageUrl(assetFolder:string,hash:string,size:string,fileType:string,useProduction:boolean)
	{
		const suffix = FileTypes.suffix(fileType);
		const prefix = useProduction ? '_assets' : '_devAssets';
		return `${hostUrl()}/${prefix}/artists/`+assetFolder+'/'+hash+'-'+size+'.'+suffix;
	}

	urls():ILinks
	{
		const jsUrls = [
			{url: this.buildUrl('backend.js')},
			{url: this.buildUrl('frontend.js')}
		];

		const cssUrls = [
			{url: this.buildUrl('resources/fontawesome/css/all.min.css')},
			{url: this.buildUrl('resources/nano.min.css')},
			{url: this.buildUrl('resources/tom-select.min.css')},

			//XXX used in artist profiles:
			{url: this.buildUrl('resources/cropper.min.css')},
		];

		const prefixes = {
			resources: this.buildUrl('resources')
		};

		return {js:jsUrls,css:cssUrls,prefixes:prefixes};
	}
}

