
export function AnnounceCircle(props)
{
	return (
		<svg id="announce" class="icon" style={`fill:${props.fillColour}; stroke:${props.strokeColour};`} 
				viewBox="0 0 64 64" enable-background="new 0 0 64 64"  xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">

			<g transform="matrix(0.117187, 0, 0, 0.117187, 0.710938, 2.000017)" bx:origin="-0.179592 0.000002">
				<circle xmlns="http://www.w3.org/2000/svg" style="" cx="256" cy="267" r="245" fill="#272a31" data-original="#1a2b63" class=""></circle>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M254.027,385.066c-0.37-0.642-1.043-1.001-1.735-1.001c-0.338,0-0.681,0.086-0.996,0.267  c-1.332,0.767-2.849,1.172-4.385,1.172c-3.174,0-6.122-1.692-7.695-4.416l-4.489-7.776c-2.439-4.227-0.986-9.65,3.24-12.09  c0.957-0.552,1.284-1.775,0.732-2.732c-0.37-0.642-1.042-1.001-1.734-1.001c-0.339,0-0.683,0.086-0.998,0.269  c-1.334,0.77-2.854,1.177-4.395,1.177c-3.174,0-6.123-1.692-7.696-4.417l-4.489-7.775c-2.439-4.226-0.987-9.65,3.238-12.09  c0.956-0.552,1.284-1.775,0.731-2.732c-0.371-0.642-1.042-1-1.734-1c-0.339,0-0.683,0.086-0.998,0.269l-4.249,2.453  c-1.327,0.766-2.842,1.171-4.381,1.171c-0.441,0-0.887-0.033-1.326-0.099c-0.098-0.014-0.197-0.021-0.294-0.021  c-0.655,0-1.277,0.323-1.653,0.875c-0.432,0.635-0.462,1.46-0.078,2.125l2.895,5.013c2.408,4.171,0.973,9.524-3.197,11.933  l-11.899,6.87c-1.316,0.76-2.816,1.162-4.336,1.162c-3.133,0-6.043-1.67-7.596-4.359l-3.917-6.784l-1.694,0.978l44.309,76.745  l29.094-16.797c-0.786-0.654-1.472-1.433-1.995-2.339l-4.489-7.775c-2.44-4.227-0.987-9.651,3.24-12.092l4.239-2.447  C254.25,387.246,254.578,386.023,254.027,385.066z" fill="#ffe2b8" data-original="#ffe2b8" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M375.777,159.217l23.728-13.699c2.381-1.375,3.205-4.448,1.83-6.83l-41.058-71.114  c-1.375-2.382-4.449-3.205-6.83-1.83L329.72,79.442L375.777,159.217z" fill="#44c4a1" data-original="#ffffff" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M183.716,303.316c3.133,0,6.043,1.67,7.595,4.359l4.35,7.533c0.359,0.622,1.021,1,1.732,1  c0.049,0,0.099-0.002,0.148-0.005c0.766-0.057,1.431-0.547,1.712-1.261c0.743-1.884,2.051-3.408,3.782-4.407l35.409-20.443  c0.069-0.04,0.144-0.067,0.214-0.105l-23.572-40.828l-48.92,28.244l15.16,26.259C182.101,303.439,182.904,303.316,183.716,303.316z" fill="#99f1cc" data-original="#445ea0" class=""></path>
				<g xmlns="http://www.w3.org/2000/svg">
				<path style="" d="M183.716,303.316c3.133,0,6.043,1.67,7.595,4.359l4.35,7.533c0.359,0.622,1.021,1,1.732,1   c0.049,0,0.099-0.002,0.148-0.005c0.766-0.057,1.431-0.547,1.712-1.261c0.743-1.884,2.051-3.408,3.782-4.407l0.328-0.189   L179.8,269.532l-13.634,7.871l15.16,26.259C182.101,303.439,182.904,303.316,183.716,303.316z" fill="#99f1cc" data-original="#293d7c" class=""></path>
				<path style="" d="M245.815,402.336c-2.44-4.227-0.987-9.651,3.24-12.092l0.328-0.189l-2.634-4.563   c-3.112-0.058-5.987-1.729-7.533-4.405l-4.489-7.776c-1.611-2.792-1.515-6.101-0.053-8.732l-3.265-5.655   c-3.111-0.058-5.988-1.728-7.534-4.406l-4.489-7.775c-1.612-2.792-1.516-6.103-0.053-8.734l-2.63-4.556l-0.328,0.189   c-1.327,0.766-2.842,1.171-4.381,1.171c-0.441,0-0.887-0.033-1.326-0.099c-0.098-0.014-0.197-0.021-0.294-0.021   c-0.655,0-1.277,0.323-1.653,0.875c-0.432,0.635-0.462,1.46-0.078,2.125l2.895,5.013c2.046,3.545,1.309,7.938-1.517,10.658   l28.169,48.79c1.375,2.381,4.449,3.205,6.83,1.83l1.311-0.756L245.815,402.336z" fill="#99f1cc" data-original="#293d7c" class=""></path>
				</g>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M117.258,224.867l-26.514,15.309c-2.381,1.375-3.205,4.448-1.83,6.83l21.347,36.974  c1.375,2.382,4.449,3.205,6.83,1.83l26.515-15.308L117.258,224.867z" fill="#44c4a1" data-original="#ffffff" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M103.85,244.357l18.495-10.679l-5.087-8.811l-26.514,15.309c-2.381,1.375-3.205,4.448-1.83,6.83  l21.347,36.974c1.375,2.382,4.449,3.205,6.83,1.83l3.689-2.13l-18.759-32.492C100.645,248.806,101.469,245.732,103.85,244.357z" fill="#99f1cc" data-original="#d9eafc" class=""></path>
				<polygon xmlns="http://www.w3.org/2000/svg" style="" points="273.369,38.748 186.918,171.028 225.061,237.095 382.845,228.366 " fill="#44c4a1" data-original="#ffffff" class=""></polygon>
				<polygon xmlns="http://www.w3.org/2000/svg" style="" points="273.369,38.748 186.918,171.028 198.551,191.178 285.002,58.897 " fill="#99f1cc" data-original="#d9eafc" class=""></polygon>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M229.872,237.683c1.375,2.382,0.551,5.455-1.83,6.83L155.665,286.3  c-2.381,1.375-5.455,0.551-6.83-1.83l-41.058-71.114c-1.375-2.381-0.551-5.455,1.83-6.83l72.377-41.787  c2.381-1.375,5.455-0.552,6.83,1.83L229.872,237.683z" fill="#99f1cc" data-original="#fe3745" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M127.11,218.689c-1.375-2.381-0.551-5.455,1.83-6.83l64.517-37.248l-4.643-8.042  c-1.375-2.382-4.449-3.205-6.83-1.83l-72.377,41.787c-2.381,1.375-3.205,4.449-1.83,6.83l41.058,71.114  c1.375,2.381,4.449,3.205,6.83,1.83l7.86-4.538L127.11,218.689z" fill="#99f1cc" data-original="#b7092b" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M421.203,234.432c1.375,2.382,0.551,5.455-1.83,6.83l-19.608,11.32  c-2.381,1.375-5.455,0.552-6.83-1.83L259.027,18.816c-1.375-2.382-0.551-5.455,1.83-6.83l19.608-11.32  c2.381-1.375,5.455-0.552,6.83,1.83L421.203,234.432z" fill="#99f1cc" data-original="#fe3745" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M276.034,24.485c-1.375-2.382-0.551-5.455,1.83-6.83l13.637-7.873l-4.207-7.286  c-1.375-2.382-4.449-3.205-6.83-1.83l-19.608,11.32c-2.381,1.375-3.205,4.448-1.83,6.83l133.908,231.936  c1.375,2.382,4.449,3.205,6.83,1.83l5.97-3.447L276.034,24.485z" fill="#99f1cc" data-original="#b7092b" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M139.452,378.121L65.374,420.89c18.676,23.105,41.462,42.746,67.254,57.81l48.671-28.1  L139.452,378.121z" fill="#99f1cc" data-original="#d9eafc" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M139.452,378.121L65.374,420.89c3.514,4.347,7.174,8.571,10.971,12.666l71.335-41.185  L139.452,378.121z" fill="#44c4a1" data-original="#aec1ed" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M197.44,363.239c-5.113,2.952-11.712,1.184-14.664-3.93l-20.226-35.03  c-2.952-5.113-1.184-11.712,3.929-14.665l11.899-6.87c5.113-2.952,11.712-1.184,14.664,3.929l20.226,35.03  c2.952,5.114,1.184,11.712-3.929,14.664L197.44,363.239z" fill="#ffe2b8" data-original="#ffe2b8" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M213.269,341.705l-20.226-35.03c-2.041-3.534-5.822-5.467-9.652-5.352l20.215,35.014  c2.952,5.113,1.184,11.712-3.929,14.665l-11.899,6.869c-1.579,0.912-3.3,1.371-5.012,1.423l0.01,0.016  c2.952,5.113,9.551,6.882,14.664,3.93l11.899-6.87C214.452,353.417,216.22,346.818,213.269,341.705z" fill="#ffc987" data-original="#ffc987" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M414.998,124.953c-2.592,0-5.113-1.345-6.502-3.751c-2.071-3.587-0.842-8.174,2.745-10.245  c8.953-5.169,12.031-16.658,6.862-25.611c-5.17-8.954-16.659-12.031-25.611-6.863c-3.587,2.071-8.174,0.842-10.245-2.745  c-2.071-3.587-0.842-8.174,2.745-10.245c16.117-9.305,36.798-3.763,46.102,12.353s3.763,36.797-12.353,46.101  C417.56,124.628,416.27,124.952,414.998,124.953z" fill="#44c4a1" data-original="#ffaf10" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M429.997,150.932c-2.592,0-5.113-1.345-6.502-3.751c-2.071-3.587-0.842-8.174,2.745-10.245  c23.278-13.439,31.282-43.312,17.843-66.59c-6.51-11.276-17.022-19.342-29.6-22.712c-12.578-3.369-25.714-1.641-36.99,4.87  c-3.587,2.072-8.174,0.842-10.245-2.745c-2.071-3.587-0.842-8.174,2.745-10.245c14.746-8.514,31.926-10.775,48.373-6.368  s30.194,14.955,38.708,29.701c17.575,30.441,7.107,69.505-23.333,87.08C432.559,150.608,431.269,150.932,429.997,150.932z" fill="#44c4a1" data-original="#ffd00d" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M256.755,300.107c2.984,5.168,1.197,11.839-3.972,14.823l-35.408,20.442  c-5.168,2.984-11.839,1.197-14.823-3.972l-4.488-7.776c-2.984-5.169-1.197-11.839,3.971-14.823l35.409-20.443  c5.168-2.984,11.839-1.197,14.823,3.972L256.755,300.107z" fill="#ffe2b8" data-original="#ffe2b8" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M213.404,334.657l-4.489-7.776c-2.984-5.168-1.197-11.839,3.972-14.822l35.409-20.443  c0.855-0.494,1.753-0.845,2.667-1.083c-3.35-3.745-9.006-4.777-13.519-2.172l-35.409,20.443c-5.168,2.984-6.955,9.654-3.971,14.823  l4.488,7.776c2.49,4.314,7.546,6.26,12.155,5.056C214.221,335.913,213.783,335.313,213.404,334.657z" fill="#ffc987" data-original="#ffc987" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M276.347,324.224c2.984,5.168,1.197,11.838-3.972,14.823l-35.409,20.443  c-5.168,2.984-11.838,1.197-14.823-3.972l-4.489-7.775c-2.984-5.169-1.197-11.839,3.971-14.823l35.409-20.443  c5.168-2.984,11.839-1.197,14.823,3.972L276.347,324.224z" fill="#ffe2b8" data-original="#ffe2b8" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M232.994,358.772l-4.489-7.776c-2.984-5.169-1.197-11.838,3.972-14.822l35.408-20.443  c0.855-0.494,1.753-0.845,2.668-1.083c-3.349-3.744-9.006-4.777-13.519-2.172l-35.408,20.443c-5.169,2.984-6.955,9.655-3.971,14.823  l4.488,7.775c2.49,4.314,7.546,6.26,12.155,5.055C233.812,360.028,233.373,359.428,232.994,358.772z" fill="#ffc987" data-original="#ffc987" class=""></path>
				<g xmlns="http://www.w3.org/2000/svg">
				<path style="" d="M291.687,350.793c2.984,5.168,1.197,11.838-3.972,14.823l-35.408,20.443   c-5.168,2.984-11.839,1.197-14.823-3.971l-4.489-7.776c-2.983-5.168-1.197-11.838,3.972-14.822l35.409-20.443   c5.169-2.984,11.839-1.197,14.823,3.971L291.687,350.793z" fill="#ffe2b8" data-original="#ffe2b8" class=""></path>
				<path style="" d="M302.775,379.817c2.984,5.169,1.197,11.839-3.972,14.823l-35.408,20.443   c-5.168,2.984-11.839,1.197-14.823-3.972l-4.489-7.775c-2.984-5.168-1.197-11.839,3.972-14.824l35.408-20.442   c5.168-2.985,11.839-1.198,14.823,3.972L302.775,379.817z" fill="#ffe2b8" data-original="#ffe2b8" class=""></path>
				</g>
				<g xmlns="http://www.w3.org/2000/svg">
				<path style="" d="M248.335,385.343l-4.489-7.776c-2.984-5.169-1.197-11.839,3.972-14.823l35.409-20.443   c0.855-0.494,1.753-0.844,2.667-1.083c-3.349-3.744-9.006-4.777-13.519-2.172l-35.409,20.443   c-5.168,2.983-6.955,9.654-3.972,14.822l4.489,7.777c2.49,4.313,7.546,6.259,12.155,5.055   C249.153,386.599,248.714,385.999,248.335,385.343z" fill="#ffc987" data-original="#ffc987" class=""></path>
				<path style="" d="M259.423,414.367l-4.489-7.776c-2.984-5.169-1.197-11.838,3.972-14.822l35.408-20.443   c0.855-0.494,1.753-0.845,2.667-1.084c-3.35-3.744-9.006-4.776-13.519-2.171l-35.408,20.442c-5.169,2.984-6.956,9.654-3.972,14.823   l4.489,7.776c2.49,4.313,7.546,6.259,12.155,5.055C260.241,415.623,259.802,415.023,259.423,414.367z" fill="#ffc987" data-original="#ffc987" class=""></path>
				</g>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M225.738,433.634c1.375,2.382,0.551,5.455-1.83,6.83l-34.435,19.881  c-2.381,1.375-5.455,0.552-6.83-1.83l-49.953-86.521c-1.375-2.382-0.551-5.455,1.83-6.83l34.435-19.881  c2.381-1.375,5.455-0.552,6.83,1.83L225.738,433.634z" fill="#44c4a1" data-original="#52bbef" class=""></path>
				<path xmlns="http://www.w3.org/2000/svg" style="" d="M180.566,355.394l-29.046,16.77c-2.381,1.375-3.205,4.448-1.83,6.83l45.172,78.24l29.046-16.77  c2.381-1.375,3.205-4.448,1.83-6.83L180.566,355.394z" fill="#99f1cc" data-original="#69cdff" class=""></path>
				<circle xmlns="http://www.w3.org/2000/svg" style="" cx="194.56" cy="428.37" r="7.5" fill="#44c4a1" data-original="#ffffff" class=""></circle>
			</g>
		</svg>
	);
}
