import { ClockCircle } from 'Shared/backend/icons/ClockCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { EventCalenderSettings } from 'Shared/model/basic';

export function artistNames(artists)
{
	let ret = '';

	for (let i=0; i < artists.length; i++) {
		if (i > 0) 
			ret += i == artists.length - 1 ? ' and ' : ', ';
		ret += artists[i].name ?? '(name missing)';
	}
	return ret;
}

export function showTimesStatusIcon(times: EventCalenderSettings)
{
	const fill = () => {
		const noTime = times.startTime==null && !times.allDay;
		const noTimeAndStartDate =  noTime || times.startDate==null;
		const noMonthlyDays = times.week == '' || times.weekday == '';
		const noWeekly = noTimeAndStartDate || times.weekdays=='';
		const noMonthly = noTimeAndStartDate || noMonthlyDays;

		 return times.frequency=='once' && times.startSingle==null ||
				times.frequency=='daily' && noTimeAndStartDate ||
				times.frequency=='weekly' && noWeekly ||
				times.frequency=='monthly' && noMonthly
			? IconColour.inactive : IconColour.active;
	};

	return <ClockCircle fill={fill()} />
}
