
/*
	Creates a clamp().

	'unit' here applies to BOTH the screen size and the font size.
*/

export function fluidType(unit:string,minScreen:number,maxScreen:number,minFont:number,maxFont:number)
{
	const slope = (maxFont - minFont) / (maxScreen - minScreen);  
	const yAxisIntersection = minFont - minScreen * slope;

	return `clamp(${minFont}${unit}, ${yAxisIntersection}${unit} + ${slope * 100.0}vw, ${maxFont}${unit})`;

}

