import { IEventProps } from 'Shared/backend/eventPage/EventPage';
import { artistNames } from 'Shared/backend/eventPage/Utils';
import { Text, Wrap } from 'Shared/forms/Inputs'
import { Match,Switch } from 'solid-js';


export function Name(eventProps:IEventProps)
{
	const event = () => eventProps.store;

	return (
		<Switch>
			<Match when={event().eventType=='music'}>
				<Wrap label='Event Name' notes={'You can type above to replace the VOS generated name' } 
					classes={event().name==undefined && (event().lineup ?? 0).length == 0 ? 'alert' : ''}
				>
					<Text {...eventProps} field='name' placeholder={artistNames(event()?.lineup ?? [])} />
				</Wrap>
			</Match>
			<Match when={true}>
				<Wrap label='Event Name' classes={event().name==undefined ? 'alert' : ''} >
					<Text {...eventProps} field='name' />
				</Wrap>
			</Match>
		</Switch>

	);
}

