
/* Create a list of the other artists: */
export function otherFacebookHandles(artists,exclude:number) 
{	
    let first = true;
	let ret = '';
	let j = 0;

	for (let i=0; i<artists.length; i++) {
		if (i==exclude)
			continue;

		if (!first)
			ret += j==artists.length-2 ? ' and ' : ', ';

		ret += artists[i].facebook ? `@${artists[i].facebook}` : artists[i].name;

		first = false;
		j++;
	}
	return ret;
}

/* Create a list of the other artists: */
export function otherInstagramHandles(artists,exclude:number) 
{	
    let first = true;
	let ret = '';
	let j = 0;

	for (let i=0; i<artists.length; i++) {
		if (i==exclude)
			continue;

		if (!first)
			ret += j==artists.length-2 ? ' and ' : ', ';

		ret += artists[i].instagram ? `@${artists[i].instagram}` : artists[i].name;

		first = false;
		j++;
	}
	return ret;
}

