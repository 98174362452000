import { DateTitle } from 'Shared/frontend/PosterTitle';
import { css } from '@emotion/css';
import { fonts, palette, theme } from 'Shared/frontend/ThemeProvider';
import { Match, Switch } from 'solid-js';

export const posterStyle = () => css({
	overflow: 'hidden',
	margin: '1.5rem',
	borderRadius: 2,
	boxShadow: '#000000 0px 26px 30px -10px, #000000 0px 16px 10px -10px',
	width: 264,
	height: 'calc(264px * 1.414)',

	/* Most of the main poster theme properties: */
	fontSize: 16,
	color: palette().text,
	backgroundColor: theme().poster.backgroundColor,
	letterSpacing: 1.6,
	fontFamily: fonts().text,
	lineHeight: 1.2,
	textAlign: 'center'
});

export const detailsWrapStyle = () => css({
	height: 'inherit',
	position: 'relative'
});

export const dateWrapStyle = () => css({
	width: 'inherit',
	height: 'inherit',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
});

const dateStyle = () => css({
	fontFamily: fonts().heading,
	width: '100%',
	padding: '0.5em',
	textTransform: 'uppercase',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: 43.2,
	...theme().poster.dateBar
});

export const detailsButtonStyle = () => css({
/*	
	color: palette().feature,
	borderColor: palette().feature,
	backgroundColor: 'rgba(27, 33, 38, 0.88)',
*/	

	color: theme().poster.detailsButton.textColor,
	borderColor: theme().poster.detailsButton.textColor,
	backgroundColor:theme().poster.detailsButton.backgroundColor,

	borderStyle: 'solid',
	borderWidth: '0.1em',
	textAlign: 'center',
	width: '7em',
	padding: '0.2em 0.2em 0.1em 0.2em',
	textTransform: 'uppercase',
	position: 'absolute',
	bottom: 0,
	margin: '5px auto',
	left: 0,
	right: 0,
	fontSize: '0.7em'
});

export const nameStyle = () => css({
	fontFamily: fonts().heading,
	lineHeight: '1.2em',
	textTransform: 'uppercase'
});

export const infoStyle = () => css({
	fontFamily: fonts().text,
	lineHeight: '1.2em',
	textAlign: 'center',
});



export function Poster(props)
{
	return (
		<div class={posterStyle()}>
			<Switch>
				<Match when={props.showDetails}>
					<DetailsWrap {...props}>
						{ props.children }
					</DetailsWrap>
				</Match>
				<Match when={true}>
					{ props.children }
				</Match>
			</Switch>
		</div>
	);
}

export function DetailsWrap(props)
{
	return (
		<div class={detailsWrapStyle()}>
			{ props.children }

			<div class={detailsButtonStyle()}>Details</div>
		</div>
	);
}

export function DateWrap(props)
{
	return (
		<div class={dateWrapStyle()}>
			<div class={dateStyle()}>
				{props.times.customDateLabel ?? <DateTitle {...props.times}/> }
			</div>

			{ props.children }
		</div>
	);
}

export function maxTextLines(maxLines:number) 
{
	/* 
		In time should be able to use unprefixed line-clamp. 
		See: https://caniuse.com/?search=line-clamp for progress 
	*/
	const limitProperties = maxLines==1 ? { whiteSpace: 'nowrap' } : {
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: `${maxLines}`
	};

	return {
		...limitProperties,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	};
}


