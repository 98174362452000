import { css, cx } from '@emotion/css'
import { Switch,Match,Show } from 'solid-js';
import { theme } from 'Shared/frontend/ThemeProvider';


const infoStyle = (layout,isWide:boolean) => css({
	textAlign: leftAlign(layout,isWide) ? 'left' : 'center',
});

const overlayedStyle = () => css({
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '2em',
	backgroundColor: 'rgba(0, 0, 0, 0.56)',
	width: 'fit-content',
	height: 'fit-content',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)'
});

const teaserStyle = (layout) => css({
	textTransform: 'uppercase',
	fontWeight: 700,
	letterSpacing: 2.2,
	margin: '1em 0 0 0',

//XXX going to want to generalise this across widgets and info fields
	...(
		layout=='slideshow' ? theme().widgets.slideshow.teaser : {}
	)
});

export const titleStyle = (leftAlign:boolean) => css(theme().heading,{
	textAlign: leftAlign ? 'left' : 'center',
	/* Needs to sit close to any teaser */
	marginTop: 0
});

const taglineStyle = () => css({
	textTransform: 'uppercase',
	fontWeight: 700,
	letterSpacing: 2.2
});

const detailsStyle = () => css({
	lineHeight: '2em',
});

const linkStyle = () => css({
	/* Likely to be at the bottom, and a call to action so leave a bit of extra space above. */
	/* The container probably looks after any bottom margin. */
	marginTop: '2em'
});

const buttonStyle = () => css(theme().button);


function leftAlign(layout,isWide:boolean|undefined)
{
	return (layout=='ss_imageLeft' || layout=='ss_imageRight') && isWide;
}


export function Info(props)
{
	return (
		<div class={cx(infoStyle(props.layout,props.isWide),props.layout=='slideshow' && overlayedStyle())}>
			<TopInfo {...props} />
			<BottomInfo {...props} />
		</div>
	);
}

export function TopInfo(props)
{
//XXX h3 center to come more from theme... / inherit

	return (<>
		<Show when={props.teaser}>
			<div class={teaserStyle(props.layout)}>{props.teaser}</div>
		</Show>

		<Show when={props.title}>
			<h3 class={titleStyle(leftAlign(props.layout,props.isWide))}>{props.title}</h3>
		</Show>

		<Show when={props.tagline}>
			<div class={taglineStyle()}>{props.tagline}</div>
		</Show>

		{/* Hire fields used instead of tagline */}
		<Show when={props.area || props.capacity}>
			<div class={taglineStyle()}>			{/* XXX taglineStyle() ?? */}
				<Show when={props.area}>
					{props.area}
				</Show>
				{' '}
				<Show when={props.capacity}>
					{props.capacity}
				</Show>
			</div>
		</Show>
	</>);
}


export function BottomInfo(props)
{
	return (<>
		<Show when={props.details || props.rate}>
			<div class={detailsStyle()}>
				<div class={css(theme().embeddedHtml)} innerHTML={props.details} />
				<Show when={props.rate}>
					{props.rate}
				</Show>
			</div>
		</Show>

		{/* Start button with link */}
		<Show when={props.addButton && props.link}>
			<div class={linkStyle()}>
				{/* Sanity check if google doc url doesn't contain /edit?usp=sharing we can't convert it to a PDF */}

				<Switch>
					<Match when={props.gDriveDownloadAsPDF && props.link?.includes('google.com/document/') && props.link?.includes('/edit?usp=sharing')}>
						<a href={props.link.replace('/edit?usp=sharing','/export?format=pdf')} target='_blank'>
							<button class={buttonStyle()}>
								{props.buttonText ?? 'More Info'}
							</button>
						</a>
					</Match>
					<Match when={true}>
						<a href={props.link} target={props.target_self ? '_self' : '_blank'}>
							<button class={buttonStyle()}>
								{props.buttonText ?? 'More Info'}
							</button>
						</a>
					</Match>
				</Switch>
			</div>
		</Show>
	</>);
}

