import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import {crawlerMetaData} from 'Shared/frontend/HeadMacros'
import { lookupFont } from 'Shared/frontend/fonts/lookupFont';
import {EventData} from 'Shared/view/frontend/Event';
import {DateTime} from 'luxon';

/*
	Should there be an option to specify a special image for Facebook?  

{#TODO nickname ==> nickName + postcode ==> postCode + website ==> webSite #}
{# TODO url #}
{# TODO share some of this with other pages (including backend pages) #}

{# TODO? <meta property="og:description" content="How much does culture influence creative thinking?" /> #}
*/

export function headInsert(data:EventData,title:string) 
{
	/* TODO look at recurring events */ 

	const extraGoogle:any = {		//XXX any
		'@type': 'Event',
		name: title
	};

	if (data.event.times?.frequency=='once') {
		extraGoogle['startDate'] = DateTime.fromISO(data.event.times!.startSingle).toFormat('yyyy-LL-dd\'T\'HH:mm:ss');

		if (data.event.times.endSingle)
			extraGoogle['endDate'] = DateTime.fromISO(data.event.times!.endSingle).toFormat('yyyy-LL-dd\'T\'HH:mm:ss');
	}

	if (data.event.eventType=='music') {
		if (data.event.lineup!=undefined && data.event.lineup.length > 0) {
			extraGoogle['performer'] = [];

			for (const artist of data.event.lineup) {
				const item:any = {				//XXX any
					'@type' : 'MusicGroup',
					name : artist.name,
				};
				if (artist.website)
					item['sameAs'] = artist.website;

				extraGoogle['performer'].push(item);
			}
		}
	}

	const urls = new VenueUrls(data.build,data.page.site.key);
	const image = handleImage(data.event,urls);


	/* TODO could include price in "offers" */

	return (
		lookupFont(data.venue.settings.frontend.fonts)() + 
		crawlerMetaData((<any>data).url,data.venue,title,<any>image,{google:{extra:extraGoogle } })
	); //XXX anys
}

function handleImage(event:EventData,urls:VenueUrls)
{
	let imageObj,imageFolder;

	/*
		Favouring event image over poster as sharing on FB at least usually includes
		details in text and the image is cleaner. Posters can go out of date.
		XXX may wish to swap these 2 cases around.
	*/
	
	if ((event.imageType=='poster' || event.imageType=='photo') && event.image?.formats?.medium) {
		imageObj = event.image;
		imageFolder = 'Event-image';
	}
	else if (event.poster?.formats?.medium && event.poster.isCurrent) {
		imageObj = event.poster;
		imageFolder = 'Event-poster';
	}
	else {
		log.warn('Missing poster ID:',event._id);
		return undefined;
	}


	const imageUrl = (new PageFunctions()).createImageUrl2(urls,imageObj.hash ?? '',imageObj,imageFolder!,'medium');

	return {
		url: imageUrl,
		format: imageObj.formats.medium.format,
		width: imageObj.formats.medium.width,
		height: imageObj.formats.medium.height
	};
}

