import {CSSInterpolation} from '@emotion/css';

export const bottomBarStyle:CSSInterpolation = {
	position: 'sticky',
	bottom: 0,
	zIndex: 5,
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'white',
	borderTop: '1px solid lightgrey',
	padding: '1em 0 0.5em 0',
}

export const statusBarStyle:CSSInterpolation = {
    display: 'flex',
	flexDirection: 'row',
	gap: 4,
    alignItems: 'center',
    fontSize: '1em',

    '.statusIcons': {
        display: 'flex'
    }
};

