import {id as createId} from 'Common/components/NjkEditComponent';
import {Location} from 'Common/config/PageConfigTypes';
import {InvalidFieldValueError} from 'Browser/InvalidFieldValueError';
import {palette} from 'Shared/artists/Palette';
import {getInput} from 'Browser/Input';
import {IPermissions} from 'Common/PageConfig';
import {Id} from 'Common/Id';
import TomSelect from 'tom-select';
import {TomInput} from 'tom-select/dist/types/types';
import { reconcile, SetStoreFunction} from 'solid-js/store';
import {JSX,Setter,onMount,children,Show,For,createSignal,createMemo, onCleanup} from 'solid-js';
import { UpdateMessage2 } from 'Common/Messages';
import { SolidHtmlEditorWidget } from 'Browser/widgets/SolidHtmlEditorWidget';

/*
	A standard wrapper for inputs.

	I anticipate instructions will usually be placed above <x-field> and can thus be omitted
	from wrap().  This is rather dependant on the layout however, so options could be used to
	insert them just above or beneith the label.

	For problem input types consider creating special wrappers, e.g. 
		imageUploadWrapper(), promptWrap()
*/


export interface IField<D> {
	page: XXX;
	permission: string;
	docId: Id,
	location: Location,
	field:string;
	ref?: HTMLDivElement;
	processInput?: (value:string) => string;
	store: D;
	setStore: SetStoreFunction<D>;
}


interface IWrap {
	label:string|JSX.Element;
//	children: InputType;
	children: any;
	required?:boolean;
	id?: string;
	classes?:string;
	disable?:boolean;
	instructions?:string;
	instructionsAfterLabel?:string;
	notes?:string;
	error?:string;
}

export function Wrap(props:IWrap)
{
	const c = children(() => props.children);
	const childId = (c() as HTMLElement)?.id; 

	const [error,setError] = createSignal(undefined);

	const checkError = (e:Event) =>  setError(e.target!.dataset?.error);

	return (
		<x-field id={`wrap:${childId}`} 
			class={`${props.classes ?? ''}${props.disable ? ' disable' : ''}`}
			onInput={(e:Event) => checkError(e)}
			onChange={(e:Event) => checkError(e)}
		>
			<Show when={props.instructions}>
				<x-notes class='instructionsBeforeLabel'>{props.instructions}</x-notes> 
			</Show>

			<label for={childId}>
				{props.label}
				<Show when={props.required}>&#42;</Show>
			</label>

			<Show when={props.instructionsAfterLabel}>
				<x-notes class='instructionsAfterLabel'>{props.instructionsAfterLabel}</x-notes>
			</Show>

			<Show when={error()}>
				<x-error>
					{ capitalize(error()!)}
				</x-error>
			</Show>

			{c()}

			<Show when={props.notes}>
				<x-notes>{props.notes}</x-notes>
			</Show>
		</x-field>
	);
}	


export interface IText extends IField<string> {
	maxLength?: number;
	placeholder?: string;
	extraAttributes?: any;  //TODO remove or refine if possible
	onInput?: (value:string) => void;
}

export function Text(props:IText)  
{
	//TODO if there is an error probably should re-evaluate it every key press...
	//     separate the onChange code? NB annoying when fields complain too soon though.

	const value = () => props.store ? valueFromStore(props as Required<IText>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (<>
		<input type='text'
			id={id(props)}
			class='bf-text' name='' autocomplete='off'
			maxlength={props.maxLength ?? 255}
			value={value() ?? ''}
			placeholder={props.placeholder}
			ref={props.ref}
			onChange={e => updateField(e.target,props,setError)}
			onInput={e => props.onInput?.(e.target.value)  } 
			style={error() ? 'background-color:'+palette.errorBackground : ''}
			data-error={error() ?? ''}
			{...(props.extraAttributes ?? {})}
		/>
	</>);
}

interface IMultiLineText extends IField<string[]> {
	numRows:number;
	maxLength?:number;
	extraAttributes?:any;
}

/* XXX if I keep using this, probably want to support paragraphs */
export function MultiLineText(props:IMultiLineText)
{
	const value = () => props.store ? valueFromStore(props as Required<IMultiLineText>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<textarea id={id(props)}
			class='bf-multiLineText' name='' autocomplete='off'
			rows={props.numRows}
			maxlength={props.maxLength ?? 2000}
			{...(props.extraAttributes ?? {})}
			onChange={e => updateField(e.target,props,setError)}
			style={error() ? 'background-color:'+palette.errorBackground : ''}
			data-error={error() ?? ''}
		>{value()}</textarea>
	);
}

interface ISingleSelect extends IField<string> {
	options:object;
	required?:boolean;
	value?:string;
	disable?:boolean;
	placeholder?:string;
	class?:string;
	extraAttributes?:any;
}

export function SingleSelect(props:ISingleSelect)
{
	const value = () => props.store ? valueFromStore(props as Required<ISingleSelect>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<select class={props.class ?? 'bf-select'} name='' autocomplete='off' 
			id={id(props)}
			disabled={props.disable}
			onChange={e => updateField(e.target,props,setError)}
			style={error() ? 'background-color:'+palette.errorBackground : ''}
			data-error={error() ?? ''}
			{...(props.extraAttributes ?? {})}
		>
			{/*
				Showing '(none)' when 'required' is safer when there is no value than defaulting to 
				the first option as the DB won't be updated with the first value unless the user 
				changes the selected value. 
					In time maybe use JS to remove (none) on first select.
					There is a default option in DbTables which can/should always/sometimes be used			*/} 
			<Show when={!props.required || !value()}>
				<option value='' selected={value()==undefined} >
					{props.placeholder ?? '(none)'}
				</option>
			</Show>

			<For each={Object.entries(props.options)}>{ item => 
				<option value={item[0]} selected={item[0]==value()}>
					{item[1]}
				</option>
			}</For>
		</select>
	);
}

interface ICheckbox extends IField<boolean> {
	extraAttributes?:any;
}

export function Checkbox(props:ICheckbox)
{
	const value = () => props.store ? valueFromStore(props as Required<ICheckbox>) : props.value;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<input type='checkbox' class='bf-checkbox' name='' 
			id={id(props)}
			checked={value()}
			onChange={e => updateField(e.target,props,setError)}
			{...(props.extraAttributes ?? {})}
		/>
	);
}

interface IInteger extends IField<number> {
	min:number;
	max:number;
	step:number;
	extraInputAttributes:any;
}

export function Integer(props:IInteger)
{
	const value = () => props.store ? valueFromStore(props as Required<IInteger>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<input type='number'
			id={id(props)}
			class='bf-integer' name='' value={value()}
			max={props.max} min={props.min} step={props.step}
			{...(props.extraInputAttributes ?? {})}
			onChange={e => updateField(e.target,props,setError)}
		/>
	);
}

interface IMultiSelect extends IField<string[]> {
	options:any;
}

export function MultiSelect(props:IMultiSelect)
{
	let anchor!: HTMLSelectElement;
	const value = () => props.store ? (valueFromStore(props as Required<IMultiSelect>) ?? []) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	const selectedTagKeys = createMemo(() => {
		const keys:any = {};
		for (const key of value())
			keys[key] = true;
		return keys;
	});

	onMount(() => {
		new TomSelect(anchor as TomInput, {
			plugins: {
				remove_button:{ title:'Remove' }
			},
			onChange: (args:string[]) => {
console.log('Inputs MultiSelect() onChange()  args:',args);				
				updateArrayInStoreAndDb(args,props,setError);
			}
		});
	});

	return (
		<div class='bf-multiSelect' id={id(props)}>
			<select multiple name='' ref={anchor} data-junk='true' >
				<For each={Object.entries(props.options)}>{ item => 
					<option value={item[0]} selected={item[0] in selectedTagKeys()}>
						{item[1]}
					</option>
				}</For>
			</select>
		</div>
	);
}

interface IPrice extends IField<string> {
	maxLength?:number;
	placeholder?:string;
	extraInputAttributes?:any;
}

export function Price(props:IPrice)
{
	const value = () => props.store ? valueFromStore(props as Required<IPrice>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<div class='bf-price' id={id(props)} >
			$ <input type='text' class='bf-value'
				name='' autocomplete='off'
				maxlength={props.maxLength ?? 255}
				value={value() ?? ''}
				placeholder={props.placeholder ?? 'X.XX'}
				{...(props.extraInputAttributes ?? {})}
				onChange={e => updateField(e.target,props,setError)}
			/>
		</div>
	);
}


interface IHtml extends IField<string> { 
	options;
	urls;
}

//TODO merge this function with SolidHtmlEditorWidget
export function Html(props:IHtml)
{
	let htmlEditor;
	let htmlNode!: HTMLDivElement;

	onMount(() => {
		htmlEditor = new SolidHtmlEditorWidget(props.urls,props.options);
		htmlEditor.init(htmlNode)
	});
	onCleanup(() => htmlEditor?.destroy(htmlNode));

	const value = () => props.store ? valueFromStore(props as Required<IHtml>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<div class='bf-html' id={id(props)}
			onChange={e => updateField(e.target,props,setError)}
			ref={htmlNode}
		>
			<div id={`tinymce-${id(props)}`} innerHTML={value()} placeholder={props.placeholder}></div>
		</div>
	);
}

/*
{% macro password(errors,mode,label,id) %}
    {% if mode=='edit' or mode=='create' %}
		{{ instructionsAndLabel(label,id,false,null) }}
        <div>
            <input id={id(props)} type="password" name="{{id}}" autocomplete="off" placeholder="********"/>
            {{ error(errors,id) }}
        </div>
    {% else %}
        <label>{{ label }}</label><div>********</div>
    {% endif %}
{% endmacro %}
*/


/* --- Private functions: --- */

function capitalize(s:string)
{
	return s.charAt(0).toUpperCase() + s.slice(1);
}

interface IId {
	permission: IPermissions;
	location?: Location;
	field: string;
}

export function id(props:IId)
{
	return createId(props.permission, props.location ?? [], props.field);
}

export function valueFromStore(props:{store:any,location?:Location,field:string})
{
	const parentPath = [...subdocument(props), ...(props.location ?? [])];

	let pos = props.store;
	for (const part of parentPath) 
		pos = pos[part];

	return pos==undefined ? undefined : pos[props.field];
}

function subdocument(props:IField<any>)
{
	if (props.permission) 
		return props.page.config.permissions()[props.permission].subdocument ?? [];
	return [];
}

export function updateField<D>(node:HTMLElement,props:IField<D>,setError:Setter<string|undefined>)
{
	try {
		const val = getInput(node);
		const value = val!=undefined && props.processInput ? props.processInput(val) : val;
		updateStoreAndDb(value,props,setError);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			setError(err.message);
		else
			throw err;
	}
}

export function updateFieldWithValue<D>(value:any,props:IField<D>,setError:Setter<string|undefined>)
{
	try {
		updateStoreAndDb(value,props,setError);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			setError(err.message);
		else
			throw err;
	}
}

export function updateStoreAndDb<D>(value:any,props:IField<D>,setError:Setter<string|undefined>)
{
	props.setSignal?.(value);

	const permissionObj = props.page.config.permissions()[props.permission];
	const subdocument = permissionObj?.subdocument ?? [];

	const path = [...subdocument,...(props.location ?? []),props.field];
console.log('Inputs updateStoreAndDb() path:',path);	
console.log('Inputs updateStoreAndDb() value:',value);	
console.log('Inputs updateStoreAndDb() permissionObj:',permissionObj);	
	props.setStore?.(...path,value);

	const docId = props.docId ?? props.page.data[permissionObj.collection]._id; //XXX do we need both when using permissions?

	try {
		const msg = new UpdateMessage2(props.page.name(),props.permission,docId,props.location,{[props.field]:value});
console.log('Inputs updateStoreAndDb()  UPDATING DB');		
		props.page.server.sendOperationOptimistically(msg);
		setError(undefined);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			alert(err.message);
		else
			throw err;
	}
}

function updateArrayInStoreAndDb<D>(array:any[],props:IField<D>,setError:Setter<string|undefined>)
{
//FIXME haven't addressed signals...
	props.setSignal?.(array);

	const path = [...subdocument(props),...(props.location ?? []),props.field];

	props.setStore?.(...path, reconcile(array));

	try {
		const msg = new UpdateMessage2(props.page.name(),props.permission,props.docId,props.location,{[props.field]:array});

		props.page.server.sendOperationOptimistically(msg);
		setError(undefined);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			alert(err.message);
		else
			throw err;
	}
}
