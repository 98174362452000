import CompilationError from 'Common/errors/CompilationError';
import {ITemplateRenderer} from 'Common/ITemplateRenderer';
import {IPageData, IPageParams, PageConfig} from 'Common/PageConfig';


export interface IRenderedStringContent {
	title: string|undefined,
	headContent: string|undefined,
	bodyContent: string
}

/* 
	Could have other rendered segments if necessary.
	TODO maybe add 'title'
*/
export class PageStringRenderer
{
	constructor(
		private renderer:ITemplateRenderer,
		private config:PageConfig<IPageData,IPageParams>
	) { }

//XXX cf creating displayFunctions here instead
	public async renderPageContentAsStrings(data:IPageData):Promise<IRenderedStringContent>
	{
		this.config.beforeDisplay(data);

		/* Compile the template on first use */
		try {
			const title = data.titleTemplate(data);
			const headContent = data.headTemplate({...data,title:title});
			const bodyContent = await this.renderer.render(<string>data.template,{...data,title:title});

			return {
				title: title,
				headContent: headContent,
				bodyContent: bodyContent
			};
		}
		catch(err) {
			const e = <any>err;
			if (e?.template != undefined)
				throw new CompilationError(e.template,e.message,e.line,e.column,null,null);
			else 
				throw err;
		}
	}
}

