import {Time, Date} from 'Shared/forms/DateTimeInputs';
import {Checkbox,  Wrap, Text, SingleSelect, MultiLineText} from 'Shared/forms/Inputs';
import {SettingsData} from 'Shared/view/backend/Settings';
import { VenueSettingsType} from 'Shared/view/VenueSettingsType';
import {Show, createSignal,mergeProps} from 'solid-js';
import { css } from '@emotion/css'
import {PageProvider} from 'Shared/frontend/PageProvider';
import { Location } from 'Common/config/PageConfigTypes';
import { createStore } from 'solid-js/store';


const settingStyle = () => css({
	'x-tree': {
		display: 'block',
		margin: '1em 0 1em 2em',
		borderLeft: '#ddd 1px solid',
		borderTop: '#ddd 1px solid',
		paddingLeft: '0.5em',
	}
});

interface Settings {
	store: VenueSettingsType;
	setStore: any;  //FIXME
	location: Location;
	page: any;  //FIXME
	permission: string;
}


export function SettingsPage(settingProps:SettingsData)
{
	const [settings,setSettings] = createStore(settingProps.venue);

	const props = mergeProps({page:settingProps.page, store:settings,setStore:setSettings,permission:'edit'});

	return (
		<PageProvider page={settingProps.page}>
			<div class={settingStyle()}>
				<General {...props} location={['general']}/>

				<Frontend {...props} location={['frontend']}/>

				<Backend {...props} location={['backend']} />

				<Pages {...props} location={['pages']} />
			</div>
		</PageProvider>
	);
}

function General(props: Settings)
{
	const [show,setShow] = createSignal(false);

//XXX cf passing 'required' thru to the input from the wrap

	return <x-tree>
		{sectionTitle('General settings',show,setShow)} 

		<Show when={show()}>
			<Wrap label='Enabled' required={true}>
				<Checkbox {...props} field='enabled' />
			</Wrap>
			<Wrap label='Timezone' required={true}>
				<Text {...props} field='timezone' />
			</Wrap>
			<Wrap label='Day start' required={true}>
				<Time  {...props} field='dayStart' />
			</Wrap>
			<Wrap label='Day end' required={true}>
				<Time {...props} field='dayEnd' />
			</Wrap>
			<Wrap label='Test now' required={false}>
				<Date {...props} field='testNow' />
			</Wrap>
		</Show>
	</x-tree> ;
}

function Frontend(props: Settings)
{
	//TODO? possibly just allow one peer to be shown at a time
	const [show,setShow] = createSignal(false);

	const typeOptions = {		//TODO type these keys?
		'differentDrummer':	'Different Drummer',
		'theTownie':		'The Townie',
	};

	const fontOptions = {		//TODO type these keys?
		'differentDrummer':	'Different Drummer',
		'questrial':		'Questrial',
		'theDock':			'The Dock',
	};
//TODO add these mappings... fontIncludesTemplate: DifferentDrummerFonts


/*XXX possible future fields:
		palette: 'air',
		variablePalette: true			//XXX would need to include JS in the header to set these to 'air'
*/ 

	return (
		<x-tree>
			{sectionTitle('Frontend settings',show,setShow)} 

			<Show when={show()}>
				<Wrap label='venueClass' required={true}>
					<SingleSelect {...props} field='venueClass' options={typeOptions} required={true} />
				</Wrap>

				<Wrap label='fonts' required={true}>
					<SingleSelect {...props} field='fonts' options={fontOptions} required={true} />
				</Wrap>

				{/*
					XXX 'window' data can be used by these scripts. Useful variables:
					  - window.isFirstPage
					  - window.pageWrapper.page.data.pageName
				*/}

				<Wrap label='Javascript embed code' required={false} instructionsAfterLabel={
					'This code is added after the page is displayed. '+
					"Don\'t include the script tag."
				}>
					<MultiLineText {...props} field='jsEmbedCode' />
				</Wrap>
			</Show>
		</x-tree>
	);
}

function Backend(props: Settings)
{
	const [show,setShow] = createSignal(false);

	const typeOptions = {		//TODO type these keys?
		'App/backend/nav/differentDrummerNav.njk':	'App/backend/nav/differentDrummerNav.njk',
		'App/backend/nav/theDockNav.njk':			'App/backend/nav/theDockNav.njk',
		'App/backend/nav/theTownieNav.njk':			'App/backend/nav/theTownieNav.njk',
		'App/backend/nav/indieIslandNav.njk':		'App/backend/nav/indieIslandNav.njk',
	};

//XXX note the 2 uses of navTemplate here
	return (
		<x-tree>
			{sectionTitle('Backend settings',show,setShow)}

			<Show when={show()}>
				<Wrap label='Nav template' required={true}>
					<SingleSelect {...props} field='navTemplate' options={typeOptions} required={true} />
				</Wrap>
			</Show>
		</x-tree>
	);
}

/*
	TODO Some page types could (optionally) allow certain things to be locked down
         Eg a type that allows a top banner to be specified in this file as JSON.
         Cf adding widgets to a page, some of which are static.
*/

function Pages(props: Settings) 
{
	const [show,setShow] = createSignal(false);

	return <x-tree>
		{sectionTitle('Pages',show,setShow)}

		<Show when={show()}>
			<Home {...props} location={[...props.location,'home']} />
			<Menu {...props} location={[...props.location,'menu']} />
		</Show>
	</x-tree>;
}


function Home(props: Settings)
{
	const [show,setShow] = createSignal(false);

	const typeOptions = {
		standardHome:			'Standard home',
		oldHome:				'Old home',
		differentDrummerHome:	'Different Drummer home'
		//XXX Maybe options...   plainHome|standard|plain|townieHome|dockHome|indieIslandHome | standardEvents | standardEvent
	};

	return <x-tree>
		{sectionTitle('Home',show,setShow)}

		<Show when={show()}>
			<Wrap label='Type' required={true}>
				<SingleSelect {...props} field='type' options={typeOptions} required={true} />
			</Wrap>

			<FrontendHomePage {...props} location={[...props.location,'frontend']} />
		</Show>
	</x-tree>;
}

function Menu(props: Settings)
{
	const [show,setShow] = createSignal(false);

	const itemTypeOptions = {
		standard:	'Standard',
		imageless:	'Imageless',
	};

	return <x-tree>
		{sectionTitle('Menu',show,setShow)}

		<Show when={show()}>
			<Wrap label='Item type' required={true}>
				<SingleSelect {...props} field='itemType' options={itemTypeOptions} required={true} />
			</Wrap>
		</Show>
	</x-tree>;
}

function FrontendHomePage(props:Settings)
{
	const [show,setShow] = createSignal(false);

	return <x-tree>
		{sectionTitle('Frontend',show,setShow)}

		<Show when={show()}>
			<FrontendNav {...props} location={[...props.location,'nav']} />

			<PageDesign {...props} location={[...props.location,'design']} />
		</Show>
	</x-tree>;
}

function FrontendNav(props:Settings)
{
	const [show,setShow] = createSignal(false);

	return <x-tree>
		{sectionTitle('Nav',show,setShow)}

		<Show when={show()}>
			<Wrap label='Shrink on scroll' required={true}>
				<Checkbox {...props} field='shrinkOnScroll' />
			</Wrap>
		</Show>
	</x-tree>;
}

function PageDesign(props: Settings)
{
	const [show,setShow] = createSignal(false);

	const typeOptions = {
		widgetList:		'Widget list',
		eventShowcase:	'Event showcase'
		//XXX cf sections, grid, ... 
	};  

	const entryOptions = {
		backend:	'Via backend',  //XXX maybe a 'tab' option for in a separate tab?
		settings:	'In settings'
		//XXX cf sections, grid, ...
	};

	return <x-tree>
		{sectionTitle('Design',show,setShow)}

		<Show when={show()}>
			<Wrap label='Type' required={true}>
				<SingleSelect {...props} field='type' options={typeOptions} required={true} />
			</Wrap>

			<Wrap label='Entry' required={true}>
				<SingleSelect {...props} field='entry' options={entryOptions} required={true} />
			</Wrap>
		</Show>
	</x-tree>;

/*	XXX if having statically-defined vertical layout could include something like this here...

	widgets: [
		{type: 'banner', / *...* /},
		{type: 'events', daysToShow:90},
		{type: 'sections',name:'myInfo'},	//XXX maybe create a pane in the backend for this dynamic part
		{
			type: 'alternatingGrid',
			items: [
				{type:'imageLeft',caption:'x y z'},
				{type:'imageRight',caption:'x y z'}
			]
		},
	]
*/
}

/*
	XXX Possible backendPage example:
		pages: {
			home: {
				backend: {
					pageName: 'Home Page 2',
					/ * Else, if static entry: * /
					widgets: [ 	//XXX sections
						{
							ref: 'myInfo',
							label: 'A label for enterring sections'
						},
						//... 
					],
					/ * Possibly... * /
					tabs: [
						{name:'Posters', widgets: ['myPosters']}
					]
				}
			},
			events: {
				type: 'standardEvents',
				// image sizes probably not settable here unless special needs arise
			}
*/

function sectionTitle(label:string,show:()=>boolean ,setShow:(show:boolean)=>void)
{
	if (show())
		return <a href='' onClick={() => setShow(false)}><h3>&#x2795; {label}</h3></a>;
	else
		return <a href='' onClick={() => setShow(true)}><h3>&#x2796; {label}</h3></a>;
}

