import { z } from 'zod';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {menuDoc} from 'Shared/model/Menu';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import {MenuDesignerPage} from 'Shared/backend/menu/MenuDesignerPage';


export const menuParams = z.object({}).strict();	
export type MenuParams = z.infer<typeof menuParams>;

export const menuData = backendData.extend({ 
	menu: menuDoc
}).strict();
export type MenuData = z.infer<typeof menuData>;


export class MenuDesigner extends BackendPageConfig<MenuData,MenuParams>
{
	static readonly pageName = 'backend/menuDesigner';
	name() { return MenuDesigner.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: '',
			template: MenuDesignerPage
		};
	}

	documents(params:MenuParams): DocumentQueries 
	{
		return ({
			...super.documents(params),
			menus: {
				type:'array',
				collection: 'menu'
			}
		});
	}

	permissions()
	{
		return ({
			createMenu: { collection: 'menu' },
			deleteMenu: { collection: 'menu' },
			sections: {collection: 'menu' },
			edit: {collection: 'menu' }
		});
	}
}

