import { VenueUrls } from 'Common/VenueUrls';
import { DateTime } from 'luxon';
import { usePage } from 'Shared/frontend/PageProvider';
import { copyToClipboard } from 'Shared/backend/CopyToClipboard';
import { otherInstagramHandles } from 'Shared/backend/eventPage/Handles';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { InstagramCircle } from 'Shared/backend/icons/InstagramCircle';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { Checkbox, Wrap } from 'Shared/forms/Inputs';
import { Match, Show, Switch } from 'solid-js';

export function AnnounceInstagram(props)
{
//TODO	
	const deviceIsDesktop = true;

	const event = () => props.store;
	const lineup = () => props.store.lineup ?? [];
	const firstAct = () => props.store.lineup?.[0];

	let postText!: HTMLDivElement;
	let postLink!: HTMLDivElement;  //XXX appears to be missing...

	const posterUrl = () => (new VenueUrls(usePage().build,usePage().site.key)).imageUrl('Event-poster',event().poster.hash,'tiny','jpeg',!event().poster.dev);

	return (
		<div class='statusAndSection task'>
			<div class='statusIcon'>
				<Switch>
					<Match when={props.store.task_announceInsta}>
						<TickCircle fill={IconColour.green} />
					</Match>
					<Match when={true}>
						<ExclamationCircle fill={IconColour.red} />
					</Match>
				</Switch>
			</div>

{/* 
	TODO get announceInstagramOverlay runOnComponent working to inlcude 
	/backend/eventPage/draftPost/announceInstagram.njk
		then remove that file from being included below
*/}


			<div class='title'>Announce on Instagram 
				<Wrap label='Done' classes='checkboxBeforeLabel'>
					<Checkbox {...props} field='task_announceInsta' />
				</Wrap>
			</div>
			
			<div class='content'>
				<div class='overlayHeading'>
					<InstagramCircle fill={IconColour.black} />
					<p>Draft Post</p>
				</div>

				<div class='draftPost'>
					<div class='postContent'>  
						<Show when={event().poster} fallback={<x-warning>Poster missing</x-warning>}>
							<img class='preview' src={posterUrl()}
								width={event().poster?.formats?.tiny?.width}
								height={event().poster?.formats?.tiny?.height}
							/>
						</Show>
					</div>
					<div ref={postText}>
						Announcing 
						{ firstAct()?.instagram ? '@'+firstAct()?.instagram : firstAct()?.name }

						<Show when={lineup().length > 1}>	
							{' '}
							with 
							{ otherInstagramHandles(lineup(),0) }
							{DateTime.fromISO(event().times.startSingle).toFormat('ccc d LLL')} 
							{' '}
							@{props.venue.instagram}
						</Show>

						{' '}
						{usePage().site.hostUrl}/event/{event().slug}
					</div>
				</div>

				<div class='postInstructions'>
					<br/>
					<b>Make an Instagram 'Story' or 'Post'</b>
					<br/>
					<x-notes>Tip: if you make a Story Instagram lets you include a link to your event page!</x-notes>
					<br/>

{/*
TODO saving an instagram image is handled differently by user on desktop vs mobile/tablet.
Please identify if deviceIsDesktop and add a download to step 1 below
*/}
					<Switch>
						<Match when={deviceIsDesktop}>
							• <a href=''>Click here to download the poster</a>
						</Match>
						<Match when={true}>
							 • Touch and hold the poster until your menu is displayed
							<br/>
							• Select the Download/Save Image option.
						</Match>
					</Switch>

					<br/>
					• <a href='' type='button' onClick={() => copyToClipboard(postText)}>Click here to copy the Post Text</a>
					 <br/>
					• Open Instagram app and add your downloaded image
					 <br/>
					• On Instragram press the screen where you want the Post Text and select 'paste'. 
					<br/>
					<br/>
					<b>If making a 'Story' add a link to the event page</b>
					<br/>
					<br/>
					• <a href='' type='button' onClick={() => copyToClipboard(postLink)}>Click here to copy link</a>    
					<br/>
					 • On your Instagram Story editor, select the sticker icon (smily face in a square)
					<br/>
					 • Select the 🔗 Link sticker
					<br/>
					 • Press and hold the URL box until 'Paste' appears, then paste in your link
					<br/>
					 • Press 'Customise sticker text' and write something like "Event Details Here"
					<br/>       
					<br/>
				</div>
			</div>
		</div>
	);
}

