
import Assert from 'Common/Assert';

export async function copyToClipboard(node:HTMLElement)
{
	window.getSelection()?.removeAllRanges();

	const range = document.createRange();

	range.selectNode(node);
	const selection = Assert.have(window.getSelection());
	selection.addRange(range);

	if (!navigator?.clipboard) {
		console.log('Copy to clipboard is only available in https');		
		return;
	}

	await navigator.clipboard.writeText(selection.toString());

	alert('Copied! Ready for you to paste to social media.')
}

