import { DateTime } from 'Shared/forms/DateTimeInputs';
import { Match, Switch } from 'solid-js';
import { DateTime as Luxon } from 'luxon';
import { AnnounceCircle } from 'Shared/backend/icons/AnnounceCircle';

// TODO ben get announceDate input working below 


export function AnnounceDate(props)
{
	const event = () => props.store;

	return (
		<div class='statusAndSection task'>
			<div class='statusIcon'>
				<AnnounceCircle fill='grey' stroke='white' />
			</div>

			<Switch>
				<Match when={event().scheduleDate && event().status=='scheduled'}>
					<Switch>
						<Match when={event().announceDate}>
							<div class='title'>The VOS dashboard lists this event to be announced on:</div>
						  	<div class='content'> 
								<DateTime {...props} field='announceDate' />
								You can change the announce date above or delete it if you want to announce when the event is published
							</div>
						</Match>
						<Match when={true}>
							<div class='title'>
								The VOS Dashboard lists this event to be announced when it's published on your website on
								{Luxon.fromISO(event().scheduleDate).toFormat('ccc, d/MM/yyyy')}
							</div>

							<div class='content'>
								You can set different announcement date below to be reminded on the Dashboard then:
								<DateTime {...props} field='announceDate' />
							</div>
						</Match>
					</Switch>
				</Match>
				<Match when={event().status=='draft'}>
					<div class='content'>   
						You can't announce event until it's 
						<a target='_parent' href={`/admin/event/${event().id}/publish`}>published (or scheduled to be visible) on your website.</a>
					</div>    
				</Match>
				<Match when={true}>
					<Switch>
						<Match when={event().announceDate}>
							<div class='title'>The VOS dashboard will remind you to announce this event on:</div>
							<div class='content'>
								<DateTime {...props} field='announceDate' />
								You can change the announce date above or delete it if you want to announce on event published date
							</div>
						</Match>
						<Match when={true}>
							<div class='title'>Announce on social media now</div>
							<div class='content'>
								Or set announcement date to be reminded then: 
								<DateTime {...props} field='announceDate' />
							</div>
						</Match>
					</Switch>
				</Match>
			</Switch>
		</div>
	);
}
