import { MusicPoster as LineupPoster } from 'Shared/frontend/eventPosters/MusicPoster';
import { Match, Show, Switch } from 'solid-js';
import { css } from '@emotion/css';
import { palette } from 'Shared/frontend/ThemeProvider';
import { SuppliedPoster } from 'Shared/frontend/eventPosters/SuppliedPoster';
import { PhotoPoster } from 'Shared/frontend/eventPosters/PhotoPoster';
import { DateWrap, DetailsWrap, infoStyle, maxTextLines, nameStyle, Poster } from 'Shared/frontend/Poster';


const imagelessNameStyle = () => css(nameStyle(), {
	fontSize: 42,
	color: palette().feature,
	width: '100%',
	minHeight: '1em',
	margin: '0.5em 0',
	padding: '0 3px',
	wordWrap: 'break-word',
	...maxTextLines(4)
});

const imagelessInfoStyle = () => css(infoStyle(), {
	margin: '0 0.5em',
	...maxTextLines(4)
});

export function EventPoster(props)
{
	return (
		<Poster {...props} showDetails={true}>
			<DetailsWrap {...props}>
				<Switch>
					<Match when={props.eventType == 'music' && props.imageType == 'auto'}>
						<LineupPoster {...props} />
					</Match>
					<Match when={props.eventType != 'music' && props.imageType == 'auto'}>
						<ImagelessPoster {...props} />
					</Match>
					<Match when={props.imageType == 'photo'}>
						<PhotoPoster {...props} folder='Event-image' size='medium' />
					</Match>
					<Match when={props.imageType == 'poster'}>
						<SuppliedPoster {...props} folder='Event-image' size='medium' />
					</Match>
				</Switch>
			</DetailsWrap>
		</Poster>
	);
}

function ImagelessPoster(props)
{
	return (
		<DateWrap {...props}>
			<div class={imagelessNameStyle()}>{props.name ?? <>&nbsp;</>}</div>
				
			<Show when={props.tagline}>
				<div class={imagelessInfoStyle()} >
					{props.tagline}
				</div>
			</Show>
		</DateWrap>
	);
}

