
/*
	Styles to be injected globally.
	This is the last resort.
*/
export const standardRules = {
	'*': {
		boxSizing: 'border-box'
	}
};

/*
	Remove stupid default browser styling.
	Mostly site themes should use these via composition.
	If pages don't want the theme settings they can use the sanitised settings directly.
*/
export const sanitise = {
	body: {
		/* Prevent horizontal movement, especially important for mobile */
		overflowX: 'hidden'
	},

	a: {
		textDecoration: 'none'
	},

	button: {
		cursor: 'pointer',
		appearance: 'none',
		border: 'none',
		fontFamily: 'inherit'
	},

	iframe: {
		border: 'none',
	},

	blockquote: {
		margin: 0
	},

	address: {
		fontStyle: 'normal'
	},


	/* Remove dotted border in select elements in FF: */
/*	
	'select:focusring': {
		color: 'transparent',
		textShadow: '0 0 0 #000'
	},
*/	
};
