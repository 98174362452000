
export function DollarCircle(props)
{
	return (
		<svg id='dollar_circle' class='icon' style={`fill:${props.fill}; stroke:${props.stroke};`} viewBox='0 0 64 64' enable-background='new 0 0 64 64' xmlns='http://www.w3.org/2000/svg'>

		  <g transform='matrix(0.020005, 0, 0, -0.020005, -0.774567, -2999.117187)' style='transform-origin: 0.77458px 2999.12px;'>
			<path d='M1325 2993 c-71 -10 -265 -57 -330 -80 -226 -82 -463 -242 -618 -419 -122 -140 -247 -357 -301 -519 -46 -140 -68 -270 -73 -430 -5 -146 -3 -173 21 -299 35 -180 66 -277 132 -410 123 -247 293 -437 529 -591 187 -122 346 -183 585 -226 209 -38 481 -16 705 57 371 122 729 443 888 799 92 206 137 410 137 625 0 299 -87 582 -260 840 -93 140 -274 318 -420 413 -186 121 -324 176 -560 222 -105 20 -157 25 -268 24 -75 -1 -150 -4 -167 -6z m283 -246 c7 -8 13 -68 14 -142 l3 -128 70 -17 c108 -26 217 -73 289 -124 97 -69 97 -68 30 -194 -62 -116 -80 -142 -101 -142 -7 0 -34 15 -60 34 -65 46 -156 91 -227 111 -141 41 -305 -1 -371 -94 -25 -36 -30 -52 -30 -100 1 -111 63 -168 285 -262 316 -134 367 -160 455 -241 108 -99 152 -218 142 -378 -15 -240 -186 -443 -422 -501 -27 -7 -53 -17 -57 -22 -3 -6 -7 -66 -7 -135 -1 -69 -4 -128 -7 -133 -3 -5 -59 -9 -125 -9 -119 0 -120 0 -129 26 -6 14 -10 78 -10 143 l0 117 -62 13 c-90 18 -197 62 -280 114 -84 53 -168 127 -168 149 0 18 153 224 172 231 17 6 42 -8 102 -55 109 -86 249 -138 373 -138 86 0 148 21 202 67 94 80 103 187 23 275 -37 40 -202 128 -334 177 -79 29 -212 96 -279 142 -60 41 -141 125 -166 172 -135 262 -30 562 246 700 49 25 107 48 130 52 l41 7 0 127 c0 173 -2 171 139 171 78 0 112 -4 119 -13z' style='stroke-width: 49.9875px;'></path>
		  </g>
		</svg>
	);
}

