import { baseSizes, baseTheme } from 'Shared/frontend/BaseTheme';
import { merge } from 'Shared/frontend/merge';
import { ITheme } from 'Shared/frontend/ThemeProvider';

const palette = {
	background: '#1B2126',
	text: '#E2E2E2',
	feature: '#DAB670',
	/* Eg the mobile nav colour */
	dimBackground: '#2E3234'
};

const fonts = {
	text: 'Questrial, sans-serif',
//FIXME Garamond doesn't appear to be actually imported 
	heading: '"EB Garamond", sans-serif',
	buttons: 'Questrial, sans-serif',
	desktopNav: '"EB Garamond", sans-serif',
	mobileNav: 'Questrial, sans-serif',
	subNav: 'Questrial, sans-serif',
}

const layout = {
//TODO cf having a base version for these	
	/* Used in showcases. Useful for negative margins. */
	leftMargin: '16px',
	rightMargin: '16px' 
};

export function theTownieTheme(): ITheme
{
	const base = baseTheme(baseSizes,palette,fonts,layout);

	const settings = merge(base, <Partial<ITheme>> {
		footer: {
			divider: {
				borderColor: palette.feature
			},
			title: {
				color: palette.feature
			}
		},

		poster: {
			dateBar: {
				color: palette.dimBackground
			},

			photoPoster: {
				inlineName: {
					color: palette.feature
				}
			},

			detailsButton: {
				textColor: palette.feature,
				backgroundColor: 'rgba(27, 33, 38, 0.88)'
			}
		},

		events: {
			heading: {
				color: palette.feature
			}
		},

		event: {
			artist: {
				heading: {
					color: palette.feature
				},
				wide: {
					nameAndTime: {
						color: palette.feature
					}
				},
				narrow: {
					nameAndTime: {
						backgroundColor: palette.feature
					}
				}, 
				genresAndLocation: {
					color: palette.feature
				} 
			}
		},

		button: {
			borderRadius: '0.4em',
			backgroundColor: palette.dimBackground
		},

		subNavButton: {
			borderRadius: '0.4em'
		},

		heading: {
			color: palette.feature
		},

		embeddedHtml: {
			strong: {
				fontFamily: 'REM, sans-serif'
			}
		}
	});

	// XXX if we want to entirely remove or overwrite a base setting or two then do so here 
	return settings;
};

