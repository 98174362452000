import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {JsPageWidget} from 'Browser/widgets/JsPageWidget';
import {Components} from 'Common/config/PageConfigTypes';
import {VenuePageConfig, venueData, venueParams} from 'Common/VenuePageConfig';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {VenueUrls} from 'Common/VenueUrls';
import {footerDocuments, footerComponents, footerDisplay, footerData, footerParams} from 'Shared/view/frontend/footer';
import { z } from 'zod';
import {BuildBasics} from 'Common/BuildBasics';
import {VenueBasics} from 'Common/VenueBasics';
import {Title} from 'Shared/frontend/Title';
import {headInsert} from 'Shared/frontend/HeadInsert';
import {PageSettingsType} from 'Shared/view/VenueSettingsType';

export const frontendParams = venueParams.merge(footerParams).extend({}).strict();	
export type FrontendParams = z.infer<typeof frontendParams>;

export const frontendData = venueData.merge(footerData).extend({
	params: frontendParams,
}).strict();
export type FrontendData = z.infer<typeof frontendData>;



export abstract class FrontendPageConfig<PD extends FrontendData,PP extends FrontendParams> 
	extends VenuePageConfig<PD,PP>
{
	protected htmlClasses: string|undefined;

	constructor(inBrowser:boolean,build:BuildBasics,venue:VenueBasics) 
	{ 
		super(inBrowser,build,venue);

		/* Not used for operations, only page generation */
		this.htmlClasses = `frontend ${venue.document.settings.frontend.venueClass}`;
	}

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings() 
	{
		return <PageSettingsType>{
			titleTemplate: Title,
			headTemplate: headInsert,
		};
	}

	includes() { return (new VenueUrls(this.build,this.venue.key)).frontendUrls(); }

	requiresLogin() { return !this.venue.document.settings.general.enabled; }

	widgets(pageWrapper:IPageWrapper):{[name:string]:JsPageWidget} { return {}; }

	documents(params:PP)
	{
		return ({
			...footerDocuments()
		});
	}

	components():Components<PD>
	{
		return ({
			...footerComponents()
		});
	}

	beforeDisplay(data:PD) 
	{
		footerDisplay(data,new VenueUrls(this.build,this.venue.key));
	} 
}

