import { DateTime } from 'luxon';
import { copyToClipboard } from 'Shared/backend/CopyToClipboard';
import { otherFacebookHandles } from 'Shared/backend/eventPage/Handles';
import { musicExists } from 'Shared/backend/eventPage/Plan';
import { MusicCircle } from 'Shared/backend/icons/MusicCircle'
import { FacebookCircle } from 'Shared/frontend/icons/FacebookCircle';
import { usePage } from 'Shared/frontend/PageProvider';
import { For, Match, Switch } from 'solid-js'


/* TODO
	if it's music embedded in website send there otherwise if there's only a link to spotify
	or apple music attach link to those apps
*/

export function WebsiteMusic(props)
{
	const lineup = () => props.store.lineup ?? [];

	return (
		<div class='statusAndSection task'>  
			<div class='statusIcon'>
				<MusicCircle />
			</div>

			<div class='title'>Post Artist Music to Facebook</div>

			<div class='content'>
				<Switch>
					<Match when={!musicExists(lineup())}>
						<div class='title'>
							No artist music
						</div>
						Perhaps ask them to add music to their artist profile at 
						<a href='https://profile.ontoitmedia.com'>profile.ontoitmedia.com</a>{' '} 
						so you can copy the artists content into your VOS.
						<br/>
					</Match>
					<Match when={true}>
						<For each={lineup()}>{ artist => <>
							<a href="#" onClick="runOnComponent(event,'currentAct','setData',{{loop.index0}})
												runOnComponent(event,'overlays','toggle','websiteMusicFacebookOverlay')">
								- Post {artist.name}'s music to Facebook
							</a>
							<br/>
						</>}</For>

						{/* TODO Ben Urgent get the above runOnComponent overlay working with the below INCLUDE file, then remove the below FOR loop */}
						<For each={lineup()}>{ artist =>
							<ArtistItem {...props} artist={artist} />
						}</For>
					</Match>
				</Switch>
			</div>
		</div>
	);
}

function ArtistItem(props)
{
	let postText!: HTMLDivElement;
	const lineup = () => props.store.lineup ?? [];
	const artist = () => props.artist;

	return (
		<div>
			<div class='overlayHeading'>
				<FacebookCircle fill='black' />
				{artist().name} Song draft post
			</div>

			<div class='postInstructions'>
				<div class='buttonAndTip'>
					<inline-content>
						<button onClick={() => copyToClipboard(postText)}>Copy Post</button>
					
						&nbsp; Then paste into Facebook, where you can edit the text before posting.
					</inline-content> 
				</div>
			</div>

			<div class='draftPost'>
				<div ref={postText}>
					Click to hear a Great song from 
					{ artist().facebook ? '@'+artist().facebook : artist().name }.
					See live {DateTime.fromISO(props.store.times.startSingle).toFormat('ccc d LLL')+' '} 
					w/ { otherFacebookHandles(lineup,artist().position) }
					at {props.venue.name}.
				</div>
				
				<div class='postContent'> 
				  {/*
					  Facebook strips line breaks and whitespaces so i've hacked in &#xFEFF; as recommended
					  on stack exchange <p>.</p> draw users attention to events link
					  particularly because we don't want them clicking video link by mistake
				  */}
				  
				  {/* Below will collapse to 1 line of text on draft post */}

{/* TODO ben urgent check that #anchor for artistNameMusic is on frontend event page where music links are listed */}

{/*
FIXME domain
*/}
					<p>
						&#128467;&#127926;
						INFO:
						{usePage().site.hostUrl}/event/{props.store.slug}#{artist().name.toLowerCase().replace(' ','')}music
						&#127926;&#128467;
					</p>
					 {/* <p>&#xFEFF;</p> */}
				</div>
			</div>
		</div>
	);
}
