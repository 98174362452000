
/*
	Performs a deep merge of a 'patches' data tree into 'base'.
	NOT merging arrays.
*/

export function merge(base:any,patch:any)
{
	const ret = {};
	mergeChildren(ret,base,patch);

//console.log('merge  base:',base);	
//console.log('merge  patch:',patch);	
//console.log('merge  ret:',ret);	

	return ret;
}

/*
									PATCH
						undef       basic       object
					+-----------+-----------+-----------+
			undef	|    N/A    | use patch | use patch |
	BASE	basic	| use base  | use patch | use patch |
			object	| use base  | use patch |  recurse  |
					+-----------+-----------+-----------+
*/

function mergeChildren(ret:any,base:any,patch:any)
{
	const keys = Object.keys({...base, ...patch});

	for (const key of keys) 
		if (typeof base[key]=='object' && typeof patch[key]=='object') {
			ret[key] = {};
			mergeChildren(ret[key],base[key],patch[key]);
		}
		else if (patch[key] === undefined)
			ret[key] = base[key];
		else 
			ret[key] = patch[key];
}

/*
export function mergeOLD(base:any,patches:any)
{
	//XXX faster clone procedures probably exist...
	const obj = JSON.parse(JSON.stringify(base));
	mergeChildren(obj,patches);
	return obj;
}

function mergeChildrenOLD(base:any,patches:any)
{
	for (const [key,value] of Object.entries(patches)) 
		if (typeof value == 'object' && base[key] != undefined) 
			mergeChildren(base[key],value);
		else
			base[key] = value;
}
*/


