import {tabsData} from 'Common/components/Tabs';
import {eventDoc} from 'Shared/model/Event';
import { z } from 'zod';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import { EventPage } from 'Shared/backend/eventPage/EventPage';
import { genreKeys, performanceTypeKeys } from 'Shared/ArtistTypes';


export const eventParams = backendParams.extend({
	slug: z.string(),
	topTab: z.string().optional(),
	secondTab: z.string().optional()
}).strict();	
export type EventParams = z.infer<typeof eventParams>;

export const eventData = backendData.extend({
	params: eventParams,
	event: eventDoc,
	outerTabs: tabsData,			//TODO delete out component stuff now?
	previewTabs: tabsData,
	performanceTypes: z.record(performanceTypeKeys,z.string()),   
	genres: z.record(genreKeys,z.string())
}).strict();
export type EventData = z.infer<typeof eventData>;


export class Event extends BackendPageConfig<EventData,EventParams>
{
	static readonly pageName = 'backend/event';
	name() { return Event.pageName; }

	access() { return 'venueEventEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: '',
			template: EventPage
		};
	}

	documents(params:EventParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			event: {
				type:'object',
				collection: 'event',
				aggregate: () => [
					{$match: {slug:params.slug}}
				],
			}
		});
	}

	permissions()
	{
		return ({
			edit: { collection:'event' },
//TODO cf removing collection from the assetFolder permissions. 
//	   COULD possibly omit 'permission' from messages and run through them server-side instead.	
			poster: { collection:'event', assetFolder:'Event-poster'},
			image: { collection:'event', assetFolder:'Event-image'},
			lineupImage: { collection:'event', assetFolder:'Lineup-image'},
		});
	}
}

