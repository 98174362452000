import { css, injectGlobal } from '@emotion/css';
import { StandardDefaultLayout } from 'Shared/frontend/layouts/StandardDefaultLayout';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { sanitise, standardRules } from 'Shared/frontend/Sanitise';
import { theme, ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { Show,For } from 'solid-js';
import { createStore } from 'solid-js/store';
import { EventPoster } from 'Shared/frontend/eventPosters/EventPoster';

const gridStyle = () => css({
	padding: '0 10px',
	maxWidth: '100%',
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center'
});

const headingStyle = () => css(theme().events.heading);



export function EventsPage(props:EventsData)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	const [theme,setTheme] = createStore(props.theme);

	return (
		<ThemeProvider theme={theme}>
			<PageProvider page={props.page}>
				<StandardDefaultLayout {...props} expandDesktopNav={true}>
					<PosterGrid {...props} />
				</StandardDefaultLayout>
			</PageProvider>
		</ThemeProvider>
	);
}

function PosterGrid(props)
{
	return (<>
{/*XXX CURRENTLY USED. TODO: replace with something more 'showcasey' */}			
		<Show when={props.about?.display?.includes('events')}>
			<section>
				<For each={props.venue.aboutList}>{ about => <>
					<h2>{about.title}</h2>
					<x-repeater-item>
						<div class='anchor' id={about.title.toLowerCase()?.replaceAll(' ',"")}></div>
						<Show when={about.photo.hash!==undefined}>
{/*									
							<div class='imageWrap'>
								{ landscape.landscape(about.photo.url) }
							</div>
*/}

							<img src={about.photo.url} />
						</Show>
						<x-description>
{/*	XXX need innerHTML?								
							<p>{about.description|safe}</p>
*/}											
							<p>{about.description}</p>
						</x-description>
					</x-repeater-item>
				</>}</For>
			</section>
		</Show>

		<section>
			<h2 class={headingStyle()}>Events</h2>

			<div class={gridStyle()}>
				<For each={props.events}>{event => 
					<a class={css(sanitise.a)} href={`/event/${event.slug}`}>
						<EventPoster {...event} />
					</a>
				}</For>
			</div>
		</section>

		<Show when={props.pastEvents?.length > 0}>
			<section>
				<h2 class={headingStyle()}>Past Events</h2>

				<div class={gridStyle()}>
					<For each={props.pastEvents}>{event =>
						<a class={css(sanitise.a)} href={`/event/${event.slug}`}>
							<EventPoster {...event} />
						</a>
					}</For>
				</div>
			</section>
		</Show>
	</>);
}

