import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import Assert from 'Common/Assert';
import html2canvas from 'html2canvas-pro';
import {findEmbedded} from 'Common/components/JsonUtils';
import {IPageWrapper} from 'Browser/pages/PageWrapper';

export class HtmlCaptureWidget extends SelectorWidget
{
	constructor(
		private pageWrapper:IPageWrapper
	)
	{
//XXX not sure we really want this to be a SelectorWidget	
		super(['.bf-htmlCapture']);

		this.saveFromLink = this.saveFromLink.bind(this);
		this.upload = this.upload.bind(this);
	}

	static key() { return 'htmlCapture'; } 

	async saveFromLink(sourceSelector:string,scale:number)
	{
		const source = Assert.htmlElement(document.querySelector(sourceSelector));
		const canvas = await html2canvas(source,{scale:scale,ignoreElements: node => node.classList.contains('detailsButton')});
		const dataURL = canvas.toDataURL();

		const downloadLink = document.createElement('a');
		downloadLink.download = Math.floor(Math.random() * 1000000).toString(36).toUpperCase() + '.png';

		const url = dataURL.replace(/^data:image\/png/,'data:application/octet-stream');
		downloadLink.setAttribute('href', url);
		downloadLink.click();
	}

	async upload(sourceSelector:string,node:HTMLElement,scale:number)
	{
		const source = Assert.htmlElement(document.querySelector(sourceSelector));
		const canvas = await html2canvas(source,{scale:scale,ignoreElements: node => node.classList.contains('detailsButton')});
		const me = this;

		canvas.toBlob(async blob => {
			const ds = node.dataset;

			const docName = Assert.toString(ds.document);
			const path = ds.path=='undefined' ? undefined : ds.path;
			const field = Assert.toString(ds.field);
			const doc = this.pageWrapper.page.data[docName];

			const uploadUrl = findEmbedded(doc,path)[field].uploadUrl;

//XXX why do we have this?
			await me.pageWrapper.display();

			const ret = await me.uploadFile(Assert.have(blob),uploadUrl);
			this.loadedFile(doc,path,field,ret);
			me.pageWrapper.refresh();
		});
	}		

	private async uploadFile(file:File|Blob,uploadUrl:string):Promise<any>  //XXX any
	{
		const formData = new FormData();

		formData.append('file',file);

		const ret = await fetch(uploadUrl,{method:'POST',body:formData});
		const body = await ret.json();
		if (body?.errorType!=null)
			throw new Error('Error on server:'+JSON.stringify(body,undefined,4));   //TODO use better error

		return body;
	}

	private loadedFile(doc:any,path:string|undefined,field:string,value:any)
	{
		const imageDataParent = path==undefined ? doc : findEmbedded(doc,path);

		if (value==undefined)
			delete imageDataParent[field];
		else
			imageDataParent[field] = {...imageDataParent[field], ...value, uploading:false,isCurrent:true};
	}
}

