/*
	Can set a #hex color or the name of color if it's defined in iconsAsMacros.njk
	'default' colors should be set to the most logical and commonly used color for the icon 
	(eg 'red' warning icons),
	or write textColour with no brackets '' if you want to match the theme's textColour.
*/

import {ISvgColours, IconColour} from 'Shared/backend/IconColour'

export function MusicCircle(props:ISvgColours)
{
	return (
		<svg id="music_circle" class="icon" style={`fill:${props.fill ?? IconColour.textColour}; stroke:${props.stroke ?? '#fff0'};`} 
				viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <defs></defs>
		  <title>menu</title>
		  <path d="M 32.001 2 C 15.433 2 2 15.432 2 32 C 2 48.567 15.433 62 32.001 62 C 48.569 62 62 48.567 62 32 C 62 15.432 48.569 2 32.001 2 Z M 46.018 39.218 C 46.188 39.872 41.452 43.887 38.849 43.887 C 36.246 43.887 34.701 41.777 35.399 39.174 C 36.096 36.57 38.772 34.46 41.375 34.46 C 41.441 34.46 41.502 34.468 41.567 34.47 L 41.649 25.142 C 41.649 24.732 41.249 24.256 40.983 24.094 C 40.736 23.944 40.237 23.839 39.838 23.942 L 25.243 27.917 C 24.66 28.069 24.253 29.199 24.253 29.199 L 24.253 30.371 L 24.248 30.372 L 24.243 44.761 C 24.498 45.483 20.304 48.685 17.701 48.685 C 15.097 48.685 12.966 46.563 13.663 43.959 C 14.36 41.355 17.624 39.258 20.227 39.258 C 20.261 39.258 20.294 39.262 20.327 39.264 L 20.132 23.401 C 20.132 22.402 20.804 21.529 21.769 21.273 L 42.299 15.825 C 42.961 15.649 43.759 15.504 44.406 15.72 C 44.406 15.72 45.641 16.007 45.641 16.691 L 46.018 39.218 Z" style=""></path>
		</svg>
	);

}
