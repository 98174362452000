import {CreateArrayItemMessage2, ReorderArrayMessage2} from 'Common/Messages';
import {locateSubDocument} from 'Common/ViewUtils';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {SectionIds, SectionItem, itemStyle} from 'Shared/backend/menu/SectionItem';
import { DragDropProvider, DragDropSensors, SortableProvider, closestCenter,DragOverlay, DragEventHandler} from '@thisbeyond/solid-dnd';
import {For, createMemo} from 'solid-js';
import {createSignal} from 'solid-js';
import {createStore} from 'solid-js/store';
import {Id} from 'Common/Id';
import {MenuSection, SectionData, SectionParams} from 'Shared/view/backend/MenuSection';
import {menuSelector} from 'Shared/frontend/menuPage/MenuPage';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';
import { MenuItemsHeader } from './MenuItemsHeader';
import { extendedSectionTitle } from './ListMenuPage';
import { injectGlobal } from '@emotion/css';
import { theme } from 'Shared/backend/theme';
import { forms } from 'Shared/artists/Forms';


export function SectionPage(props:SectionData)
{
	injectGlobal([theme,forms]);

	const [menuStore,setMenuStore] = createStore(props.menu);

	let section = menuStore;
	let location = [] as Location;
	for (const i of props.params.sectionIds ?? []) {
		section = section.sections[i.index];
		location = [...location,'sections',i.index];
	}
	const itemsLocation = [...location,'items'];

	const title = () => locateSubDocument(menuStore,itemsLocation.slice(0,-1)).title;

	const items = createMemo(() => locateSubDocument(menuStore,itemsLocation) ?? []); 
	const setItems = value => setMenuStore(...itemsLocation,value);

	const ids = () => [...Array(items().length).keys()].map(v => v+1);
	const indexed = createMemo(() => items().map((e:ItemData,i:number) => ({id:i+1,data:e})));
	const [activeItem, setActiveItem] = createSignal(null);

//FIXME title can include product name. May be different for beers etc.
	const onDragStart:DragEventHandler = e => {
		const i = items()[Number(e.draggable.id) - 1];
		setActiveItem(`<div>${i.name}</div>`);
	};

	const onDragEnd:DragEventHandler = ({draggable,droppable})  => {
		if (!draggable || !droppable) return;

		const fromIndex = indexed().findIndex( (item:{id:number}) => item.id == draggable.id);
		const toIndex = indexed().findIndex( (item:{id:number}) => item.id == droppable.id);
		if (fromIndex === toIndex) return;

		const newItems = items().slice();
		newItems.splice(toIndex, 0, ...newItems.splice(fromIndex, 1));
		setItems(newItems);

		reorder(props.page.server,menuStore._id,itemsLocation,fromIndex,toIndex);
	};

	/* anchor is sanitised using the same approach as in sanitiseSelector */
//TODO subsections.   Also remember top	
	const frontendUrl = `/${menuStore.slug}#`+ menuSelector(menuStore,location);

	/* Items are styled outside of MenuItemPage.tsx as an optimisation for ListMenuPage.tsx */
	const sharedItemStyle = createMemo(() => itemStyle(props.page.data.venue.settings.pages?.menu?.itemType != 'imageless'));

	return (
		<PageProvider page={props.page}>
			<BackendWrap>
				<breadcrumbs>
					<a href='/admin/menus'>Menus</a> &gt; {title()}
				</breadcrumbs>

				<div class='headingBar'>
					<h2>
						{menuStore.title}: {extendedSectionTitle(menuStore,location,title())}
						&nbsp;
						<a href={frontendUrl} class='linkInline link_open' target='_blank'>
							<LinkOpen />
						</a>
					</h2>
					<button onClick={() => createItemAndRedirect(props.page,props.menu,props.params,itemsLocation)}>Create menu item</button>
				</div>

				<MenuItemsHeader />

				<div class='gridTable'>
					<DragDropProvider onDragStart={onDragStart} onDragEnd={onDragEnd} collisionDetector={closestCenter} >
						<DragDropSensors />
						<SortableProvider ids={ids()}>
							<For each={indexed()}>{
								(item,index) =>
									<SectionItem 
										item={item.data} 
										reorderingId={item.id} 
										url={createUrl(`/admin/menu-item/${props.params.slug}`,props.params.sectionIds,item.data.name,index())}
										itemStyle={sharedItemStyle()}
									/> 
							}</For>
						</SortableProvider>

						<DragOverlay>
							<div class='row' innerHTML={activeItem()} />
						</DragOverlay>
					</DragDropProvider>
				</div>
			
				<button onClick={() => createItemAndRedirect(props.page,props.menu,props.params,itemsLocation)}>Create menu item</button>
			</BackendWrap>
		</PageProvider>
	);
}

function reorder(server:AjaxConnectionToServer,docId:Id,location:Location,oldIndex:number,newIndex:number)
{
	const msg = new ReorderArrayMessage2(MenuSection.pageName,'reorderItems',docId,location,oldIndex,newIndex);
	server.sendOperationOptimistically(msg);
}

async function createItemAndRedirect(page,menu:SectionData,params:SectionParams,location:Location)
{
	const msg = new CreateArrayItemMessage2(page.name(),'createItem',menu._id,location,
		{status:'unpublished',imageType:'photo',imageDimensions:'square'}); 
	await page.server.sendOperation(msg);

	const num = locateSubDocument(menu,location)?.length ?? 0;

	window.pageJs( createUrl(`/admin/menu-item/${params.slug}`,params.sectionIds,'new',num) );
}

/* The slugs are for human consumption only. The index is used to locate sections */
export function createUrl(prefix:string,sectionIds:SectionIds,name:string,index:number)
{
	let url = prefix;

	for (const i of sectionIds) 
		url += '/' + i.name.replace(/\W/g,'').toLowerCase() +'-'+ i.index;

	url += '/';
	url += (name ?? '').replace(/\W/g,'').toLowerCase();
	url += '-'+index;

	return url;
}

