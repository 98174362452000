import { css } from '@emotion/css';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { DateTitleWithEndTimes } from 'Shared/frontend/PosterTitle';
import { palette } from 'Shared/frontend/ThemeProvider';
import { Match, Show, Switch } from 'solid-js';

const imgStyle = () => css({
	width: '100%'
});

const detailsStyle = () => css({
	fontSize: 20,
	padding: '1.5em 1em 0.5em 1em'
});

const specialNameStyle = () => css({
	color: palette().feature,
	paddingBottom: '0.5em',
	fontSize: '1.2em',
	textTransform: 'uppercase'
});

const dateStyle = () => css({
	fontSize: '0.7em',
	textTransform: 'uppercase',
	paddingBottom: '2em'
});

const taglineStyle = () => css({
	fontSize: '0.9em'
});

const moreInfoStyle = () => css({
	fontSize: '0.9em'
});


export function SpecialsOverlayContent(props)
{
	const special = () => props.special;

	const imageUrl = () => {
		if (!props.special.image?.hash) return '';
		return (new PageFunctions()).createImageUrl2(new VenueUrls(usePage().build,usePage().site.key),
			props.special.image.hash,props.special.image,'Special-image','medium');
	};

	return (<>
		<Switch>
			<Match when={special().imageType == 'suppliedPoster2'}>
				<Show when={special().name}>
					<div class={specialNameStyle()}>{special().name ?? <>&nbsp;</>}</div>
				</Show>

				<img class={imgStyle()} src={imageUrl()}/>
			</Match>
			<Match when={special().imageType == 'photoPoster' || special().imageType == 'suppliedPoster'}>
				<img class={imgStyle()} src={imageUrl()}/>
			</Match>
			<Match when={special().imageType == 'autoNonMusicPoster'}>
				<div class={specialNameStyle()}>{special().name}</div>

				<Show when={special().tagline}>
					<div class={taglineStyle()}>
						{special().tagline}
					</div>
				</Show>
			</Match>
		</Switch>

		<div class={detailsStyle()}>
			<Show when={special().name}>
				<div class={specialNameStyle()}>
					{special().name}
				</div>
			</Show>

			<div class={dateStyle()}>
				{props.times.customDateLabel ?? <DateTitleWithEndTimes {...props.times}/> } 
			</div>

			<Show when={special().tagline}>
				<div class={taglineStyle()}>
					{special().tagline}
				</div>
			</Show>

			<div class={moreInfoStyle()} innerHTML={special().moreInfo} />
		</div>
	</>);
}

