import {css} from '@emotion/css';
import Cropper from 'cropperjs';
import {theme} from 'Shared/artists/Theme';
import {forms} from 'Shared/artists/Forms';

const cropperStyle = () => css(theme,forms,{
	position: 'fixed',
	left: 0,
	right: 0,
	top: 50,
	bottom: 0,
	zIndex: 9999,
	backgroundColor: '#000',

	'#bf-cropperBar': {
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		height: 50,
		backgroundColor: 'white',

		'button': {
			position: 'absolute',
			right: 10,
			top: 10,
			zIndex: 9999
		},

		'#bf-cropperMessage': {
			lineHeight: '50px',  //XXX requires px. Probably a bug in Emotion
			marginLeft: 20
		}
	}
});


export async function displayCropper(image:HTMLImageElement,mimeType:string,ratio:number|undefined,minWidth:number,minHeight:number):Promise<Blob|null>
{
	if (image.height < minHeight || image.width < minWidth) {
		alert('The image is too small');
		return await Promise.resolve(null);
	}

	/* Create the image editor overlay: */
	const editor = document.createElement('div');
	editor.classList.add(cropperStyle());
//		Assert.htmlElement(document.getElementById('content')).style.height='600px';
	editor.appendChild(image);
	document.body.appendChild(editor);

	const bar = document.createElement('div');
	bar.id = 'bf-cropperBar';
	const confirmButton = document.createElement('button');
	confirmButton.textContent = 'Confirm';
	bar.appendChild(confirmButton);
	const message = document.createElement('div');
	message.id = 'bf-cropperMessage'
	message.innerText = 'Click and drag to crop image';
	bar.appendChild(message);
	editor.appendChild(bar);

	const cropper = new Cropper(image, {
		viewMode: 1,
		autoCropArea: 1.0,
		guides: false,
		center: false,
		zoomable: false,
		imageSmoothingEnabled: true,
		imageSmoothingQuality: 'high',
//BUG the box moves when trying to shrink a minimally sized box
		crop: (event:any) => {
			const width = event.detail.width;
			const height = event.detail.height;

			if (width < minWidth || height < minHeight) {
				cropper.setData({
					width: Math.max(minWidth,width),
					height: Math.max(minHeight,height),
				});
			}
		}
	});
	if (ratio!=undefined)
		cropper.setAspectRatio(ratio);

	return await new Promise((resolve,reject) => {
		confirmButton.addEventListener('click',async () => 
			resolve(await performCrop(editor,cropper,mimeType)));
	});
}

async function performCrop(editor:HTMLElement,cropper:Cropper,mimeType:string):Promise<Blob|null>
{
	const canvas = cropper.getCroppedCanvas();
	document.body.removeChild(editor);
//		(<HTMLElement>document.getElementById('content')).style.height='initial';

	return await new Promise((resolve,reject) => 
		canvas.toBlob(blob => resolve(blob),mimeType,0.95)
	);
}

