import { baseSizes, baseTheme } from 'Shared/frontend/BaseTheme';
import { merge } from 'Shared/frontend/merge';
import { ITheme } from 'Shared/frontend/ThemeProvider';

const palette = {
	background: 'black',
	text: 'white',
	feature: '#be1e2d', /* a red */
	feature2: '#f2eb67', /* yellow */
	dimBackground: '#2b2929' /* a gray */
};

const fonts = {
	text: '"Open Sans", sans-serif',
	heading: 'Oswald, sans-serif',
	buttons: '"Open Sans", sans-serif',
	desktopNav: 'Oswald, sans-serif',
	mobileNav: '"Open Sans", sans-serif',
	subNav: '"Open Sans", sans-serif',
}

const layout = {
//TODO cf having a base version for these	
	/* Used in showcases. Useful for negative margins. */
	leftMargin: '16px',
	rightMargin: '16px' 
};



export function differentDrummerTheme():ITheme
{
	const base = baseTheme(baseSizes,palette,fonts,layout);

	const settings = merge(base,<Partial<ITheme>>{
		nav: {
			desktop: {
				active: {
					color: palette.text
				},
				hover: {
					color: palette.text
				}
			},
			mobile: {
				button: {
					backgroundColor: palette.background
				},
				active: {
					color: palette.text
				},
				hover: {
					color: palette.text
				}
			}
		},

//XXX it might be better to make the colourless theme the default. Could perhaps 'merge in' the colours as desired...
		subNavButton: {
			backgroundColor: palette.text
		},

		button: {
			border: 'solid 1px',
			backgroundColor: palette.background,

			':hover': {
				backgroundColor: palette.text,
				color: palette.background
			}
		},

		a: {
			color: palette.feature2
		},

		phone: {
			color:  palette.feature2
		},

		address: {
			color:  palette.feature2
		},

		widgets: { 
			slideshow: {
				teaser: {
					margin: '1em 0 2em 0'
				}
			},
		},

		embeddedHtml: {
			a: {
				color: palette.feature2
			},
		},
	});

	// XXX if we want to entirely remove or overwrite a base setting or two then do so here 
	return settings;
};

