import { css, injectGlobal } from '@emotion/css';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { DetailsTab } from 'Shared/backend/eventPage/DetailsTab';
import { artistNames } from 'Shared/backend/eventPage/Utils';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { theme } from 'Shared/backend/theme';
import { EventDoc } from 'Shared/model/Event';
import { Show, Switch, createSignal, mergeProps } from 'solid-js';
import { SetStoreFunction, createStore } from 'solid-js/store';
import { EventData } from 'Shared/view/backend/Event';
import { For } from 'solid-js';
import { Match } from 'solid-js';
import { Plan } from 'Shared/backend/eventPage/Plan';
import { forms } from 'Shared/artists/Forms';
import { StatusBar } from 'Shared/backend/eventPage/StatusBar';
import { Type } from 'Shared/backend/eventPage/Type';
import { Id } from 'Common/Id';


const topLine = () => css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: '1em'
});

const tabPaneGroupStyle = () => css({ });


export interface IEventProps {
	docId: Id;
	store: EventDoc;
	setStore: SetStoreFunction<EventDoc>;
	location: Location;
	permission: string;
//export type EditableComponent = EditComponent<IPageData,Document>|EditKeyComponent<IPageData,Document>;
}


export function EventPage(props: EventData)
{		
//TODO should put 'forms' into all the backend TSX files. Separate from artist forms.	
	injectGlobal([theme,forms]);

	const tabs = {details:'Details',marketing:'Marketing'};
	const [tab,setTab] = createSignal('details');

	const [event,setEvent] = createStore(props.event);

	const eventProps:IEventProps = mergeProps({
		page: props.page,
		venue: props.venue,
		docId:event._id,
		store:event,
		setStore:setEvent,
		location:[],
		permission:'edit'
	});

//TODO breadcrumbs ==> x-breadcrumbs

/* FIXME look at...
	{# 
		XXX Use in page preview... needs to be customised for each site
		TODO put in header rather than body
	#}
	<link href="https://fonts.googleapis.com/css?family=Questrial&display=swap" rel=stylesheet> 
*/	


	return (<PageProvider page={props.page}>
		<BackendWrap>
			<div class={topLine()}>
				<breadcrumbs>
					<a href='/admin/events'>Events</a> 
					{' > '}
					<Switch>
						<Match when={event.name}>
							{event.name}
						</Match>
						<Match when={event.eventType == 'music' && event.lineup?.length > 0}>
							{artistNames(event.lineup)}
						</Match>
						<Match when={true}>
							New event
						</Match>
					</Switch>
				</breadcrumbs>

				<Show when={eventProps.store.status=='published'}>
					<a href={`/event/${event.slug}`} class='linkInline' target='_blank'>
						<LinkOpen />
					</a>
				</Show>
			 </div>

			<Switch>
				<Match when={! event.eventType}>
					<Type {...eventProps} />
				</Match>
				<Match when={true}>
					<tab-group>
						<For each={Object.keys(tabs)}>{(key) => 
							<x-tab class={key==tab() ? 'active' : ''} onClick={() => setTab(key)}>{tabs[key]}</x-tab>
						}</For>
					</tab-group>

					<tab-pane-group class={tabPaneGroupStyle()}>
						<Switch>
							<Match when={tab() == 'details'}>
								<DetailsTab {...eventProps}/>
							</Match>
							<Match when={tab() == 'marketing'}>
								<div class='backend event'>
									<Plan {...eventProps}/>
								</div>
							</Match>
						</Switch>
					</tab-pane-group>

					<Show when={tab() == 'details' && event.eventType}>
						<StatusBar {...eventProps} />
					</Show>
				</Match>
			</Switch>
		</BackendWrap>
	</PageProvider>);
}

