import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm';
import { IconColour } from 'Shared/backend/IconColour';
import { PinCircle } from 'Shared/backend/icons/PinCircle'
import { SingleSelect, Text, Wrap } from 'Shared/forms/Inputs'
import { Show } from 'solid-js';

export function LocationDetails(props:ISet)
{
	return (<>
		<h4>Location</h4>
		<div class='statusAndSection'>
			<div class='statusIcons'>
				<PinCircle fill={props.state || props.city || props.country ? IconColour.active : IconColour.alert} />
			</div>

			<div class='location'>
				{/*TODO ben, CJ tried adding international:'International', as state option so Country isnt displayed if venue is adding artist
								and selects Austraian State. but vm wasnt storing international in database, please add */}

				<Wrap label='City/Town'>
					<Text {...props} field='city' />
				</Wrap>

				<Wrap label='State'>
					<SingleSelect {...props} field='state' options={{
						act: 'ACT',
						nsw: 'NSW',
						nt: 'NT',
						qld: 'QLD',
						sa: 'SA',
						tas: 'TAS',
						vic: 'VIC',
						wa: 'WA'
					}}/>
				</Wrap>

				{/* todo ben, pl note: add or 'international' to if statement when international option added to State */}
				<Show when={!props.state}>
					<Wrap label='Country'>
						<Text {...props} field='country' placeholder='Australia' />
					</Wrap>
				</Show>
			</div>
		</div>
	</>);
}

