import {ArtistProps} from 'Shared/artists/ProfilePage';
import {IconColour} from 'Shared/backend/IconColour';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {PeopleCircle} from 'Shared/backend/icons/PeopleCircle';
import {PersonCircle} from 'Shared/backend/icons/PersonCircle';
import {ImageUpload} from 'Shared/forms/ImageUpload';
import {Show} from 'solid-js';
import { css } from '@emotion/css'
import { ArtistUrls } from 'Common/ArtistUrls';
import { usePage } from 'Shared/frontend/PageProvider';


const imageUploadStyle = () => css({
	img: {
		maxWidth: 450,
		maxHeight: 300
	}
});


export function PressShot(props:ArtistProps)
{
	const imageExists = () => props.image != undefined;
	const page = usePage();

	const setImage = value => props.setStore(...props.location,'image',value);

//TODO PeopleCircle (and PersonCircle) has a 'stroke' but shouldnt
	return (<>
		<h4>Press shot
			<x-notes>VOS will crop image to 3:2 landscape ratio</x-notes>
		</h4>

		<div class='step'>
			<div class='stepIcon'>
				{ props.performanceType=='originalSolo' || props.performanceType=='coversSolo' || props.performanceType=='dj' ?
					<PersonCircle fill={imageExists() ? IconColour.active : IconColour.inactive} />
				:
					<PeopleCircle fill={imageExists() ? IconColour.active : IconColour.inactive} />
				}
			</div>

			<div>
				<div>
					<Show when={props.facebook || props.website}>
						<div class='search'>
							<label>
								Search for photos on your 

								<Show when={props.facebook}>
									<a href={`https://www.facebook.com/${props.facebook}/photos`} target='_blank' class='active'>
										Facebook page <LinkOpen fill={IconColour.active} />
									</a>
								</Show>

								<Show when={props.facebook && props.website}>
									or 
								</Show>

								<Show when={props.website}>
									<a href={props.website} target='_blank' class='active'>
										website <LinkOpen fill={IconColour.active} />
									</a>
								</Show>
							</label>
						</div>
					</Show>
				</div>

				<div class={imageUploadStyle()}>
					<ImageUpload {...props} 
						location={[...props.location,'image']}
						image={props.image} 
						setImage={setImage}
						urls={new ArtistUrls(usePage().build)}
						dimensions='landscape'
						permission='image'
						fallbackUrl='defaults/profile3x2.png'
						previewSize='medium'
						page={page}
					/>
				</div>
			</div>
		</div>
	</>);
}

