import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { StatusIcons } from 'Shared/backend/eventPage/StatusIcons';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { SingleSelect } from 'Shared/forms/Inputs';
import { DateTime } from 'Shared/forms/DateTimeInputs';
import { Show } from 'solid-js';
import { bottomBarStyle, statusBarStyle } from 'Shared/forms/StatusBar';
import { css } from '@emotion/css';

//TODO 	put 'sceheduled' back in some time

export function StatusBar(eventProps:IEventProps)
{
	const event = () => eventProps.store;

	return (
		<div class={css(bottomBarStyle)}>
			<div class={css(statusBarStyle)}> 
				<StatusIcons {...eventProps} />

				<SingleSelect {...eventProps} field='status' required={true}
					options={{
						draft:'Draft',
						published:'Published',
						unpublished:'Archive'
					}} 
				/>
				
				<Show when={event().status=='scheduled'} >
					<DateTime {...eventProps} field='scheduleDate' />
				</Show>

				<Show when={event().slug != null && event().status=='published'}>
					<a href={'/event/'+event().slug} class='linkInline' target='_blank'>
						<LinkOpen />
					</a>
				</Show>
			</div>
		</div>
	);
}

