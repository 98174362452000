import {Location} from 'Common/config/PageConfigTypes';
import {createMemo, JSX} from 'solid-js';
import {Repeater} from 'Shared/forms/Repeater';
import {CreateArrayItemMessage2, DeleteArrayItemMessage2, ReorderArrayMessage2, UpdateMessage2} from 'Common/Messages';
import {Id} from 'Common/Id';
import {reconcile, SetStoreFunction } from 'solid-js/store';


export interface ISolidRepeaterProps<ItemData> {
	store: any,
	setStore: SetStoreFunction<any>,
	page,
	permission:string,		//TODO maybe use keyof permissions()
	docId:Id,
	location: Location,
	field: string,
	renderTitle: (current:ItemData) => string,
	renderAddLabel: () => JSX.Element|string,
	deleteItem?: (event:Event,index:number) => void,
	onChange?:		(index:number,item:ItemData) => void,
	children: (item:ItemData[],index:number) => JSX.Element,
}

export function StoreRepeater<ItemData>(props:ISolidRepeaterProps<ItemData>)
{
//TODO remove EditableArray now?? Or parts of it?

	/*
		Convert the store and location into something more like a signal. Passing in a signal would be
		more convenient here, but the DB operations are expecting a store.
	*/
	/* Allowing the array to be undefined. */
	const items:ItemData[] = createMemo(() => valueFromStore(props) ?? []); 
	const setItems = (value:ItemData) => props.setStore(...props.location,props.field,value);

	const deleteFunc = props.deleteItem ?? ((e,i) => confirmAndDeleteItem(e,props,i));

	return (
		<Repeater 
			{...props}
			items={items()} 
			setItems={setItems} 
			deleteItem={(e:Event,i:number) => deleteFunc(e,i)} 
			reorder={(oldIndex:number,newIndex:number) => reorder(props.page,props.docId,props.location,props.field,props.permission,oldIndex,newIndex) } 
		/>
	);
}

function reorder(page,docId:Id,location:Location,field:string,permission:string,oldIndex:number,newIndex:number)
{
	const msg = new ReorderArrayMessage2(page.name(),permission,docId,[...location,field],oldIndex,newIndex);
	page.server.sendOperationOptimistically(msg);
}

export async function addItem<ItemData>(props:ISolidRepeaterProps<ItemData>,page,initData:XXX)
{
	/* Update the browser: */
	let value = valueFromStore(props);

	if (value==undefined) {
		/* Create the list and add item */
		props.setStore(...props.location,props.field,[]);
		value = valueFromStore(props);
		//TODO try optimistic?
       	await page.server.sendOperation(new UpdateMessage2(page.name(),props.permission,props.store._id,props.location,{[props.field]:[]}));
	}

	/* Add item to existing list */
	props.setStore(...props.location,props.field,value.length,reconcile(initData));

//XXX maybe make these updates optimistic?		
	await page.server.sendOperation(new CreateArrayItemMessage2(page.name(),props.permission,props.store._id,[...props.location,props.field],initData));
}

export async function addItemAndOpen<ItemData>(props:ISolidRepeaterProps<ItemData>,itemsNode:HTMLElement,initData:XXX)
{
	await addItem(props,props.page,initData);
//FIXME use ref instead	

	const kids = [...itemsNode.children];
	kids[kids.length - 1].querySelector('repeater-bar').click();
}

export interface IStoreRepeaterDelete {
	store;
	setStore;
	page;
	docId: Id;
	location: Location;
	field: string;
	permission: string;
}

export async function deleteItem(props:IStoreRepeaterDelete,index:number)
{
	const array = valueFromStore(props);
	const newArray = [...array.slice(0,index), ...array.slice(index+1)] 
	props.setStore(...props.location,props.field,newArray);

	const msg = new DeleteArrayItemMessage2(props.page.name(),props.permission,props.docId,[...props.location,props.field],index);
	await props.page.server.sendOperation(msg);
}

export async function confirmAndDeleteItem(event:Event,props:IStoreRepeaterDelete,index:number)
{
	event.stopPropagation();

	if (!confirm('Are you sure you wish to delete this item?'))
		return;

	await deleteItem(props,index);
}

/* Returns undefined if not found */
function valueFromStore(props:{store:any,location:Location,field:string})
{
	const parentPath = props.location ?? [];

	let pos = props.store;
	for (const part of parentPath) 
		pos = pos?.[part];

	if (pos==undefined)
		return undefined;

	return pos[props.field];
}

