import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm'
import { DocumentCircle } from 'Shared/backend/icons/DocumentCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { Html, Wrap, Text } from 'Shared/forms/Inputs';
import { Match, Show, Switch } from 'solid-js';
import { inlineOpenLinkStyle } from 'Shared/backend/theme';

export function Biography(props:ISet)
{
	const page = usePage();
	const urls = new VenueUrls(page.build,page.site.key);

	const taglineLength = () => props.tagline?.length ?? 0;

	return (<>
		<h4>Biography</h4>

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<DocumentCircle fill={props.tagline || props.biography ? IconColour.active : IconColour.inactive} />
			</div>
			<div class='section'>
				<Show when={props.facebook || props.website}> 
					<label>
						Search for info on artists 

						<Show when={props.facebook}>
							<a class='active' href={`https://www.facebook.com/${props.facebook}`} target='_blank'>
								<span class={inlineOpenLinkStyle()}>
									FACEBOOK 
									<LinkOpen fill={IconColour.active} />
								</span>
							</a>
						</Show>

						<Show when={props.facebook && props.website}>
							or 
						</Show>

						<Show when={props.website}>
							<a class='active' href={props.website} target='_blank'>	
								<span class={inlineOpenLinkStyle()}>
									WEBSITE HERE
									<LinkOpen fill={IconColour.active} />
								</span>
							</a>
						</Show>
					</label>
				</Show>
		
				<x-field-margin-bottom-wrap>
					<Wrap label='Short description' instructionsAfterLabel='Imagine this is the only thing people will read about the artist'>
						<Text {...props} field='tagline' />
					</Wrap>

					<Switch>
						<Match when={taglineLength() > 100}>
							<x-warning>
								Remove {taglineLength() - 100} characters (it won't save if over 100 characters)
							</x-warning>
						</Match>
						<Match when={true}>
							<x-notes>
								{100 - (taglineLength() ?? 0)}/100 characters left
							</x-notes>
						</Match>
					</Switch>
				</x-field-margin-bottom-wrap>

				<Wrap label='More Artist Info' instructionsAfterLabel='Displayed after "Short description" so do not repeat that'>
					<Html {...props} urls={urls} field='biography' options={{
						height: 200,
						menubar: false,
						plugins: ['link'],
						toolbar: 'undo redo | link',
						statusbar: true,
						resize: true
					}} />
				</Wrap>
			</div>
		</div>
	</>);
}

