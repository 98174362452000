import {createContext,useContext} from 'solid-js';

/* A leaf object containing CSS properties */
export type CSS = any;

export interface ITheme {
	footer: {
		divider: any,
		title: any
	},
	poster: {
		backgroundColor: string,
		dateBar: any,
		photoPoster: {
			inlineName: any
		},
		detailsButton: {
			textColor: string,
			backgroundColor: string  
		}
	},
	nav: {
		desktop: {
			active:any,
			hover:any
		},
		mobile: {
			button: any,
			active:any,
			hover:any
		}
	},
	widgets: { 
		slideshow: {
			teaser: CSS
		},
	},
	events: {
		heading: {
			color: string
		}
	},
	event: {
		heading: any,
		artist: {
			heading: any,
			wide: any,
			narrow: {
				nameAndTime: any
			}, 
			genresAndLocation: any
		}
	},
	a: any,
	heading: any,
	button: any,
	subNavButton: any,
	blockquote: any,
	embeddedHtml: any,
}


const ThemeContext = createContext();

export function ThemeProvider(props:{theme:ITheme}) 
{
	return (
		<ThemeContext.Provider value={props.theme}>
			{props.children}
		</ThemeContext.Provider>
	);
}

export function theme():ITheme
{
	return useContext(ThemeContext);
}

export function palette()
{
	return useContext(ThemeContext).palette;
}

export function fonts()
{
	return useContext(ThemeContext).fonts;
}

export function layout()
{
	return useContext(ThemeContext).layout;
}

export function sizes()
{
	return useContext(ThemeContext).sizes;
}

