import { z } from 'zod';
import {Components,DocumentQueries} from 'Common/config/PageConfigTypes';
import {menuDoc as menuModel} from 'Shared/model/Menu';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {MenuItemPage} from 'Shared/backend/menu/MenuItemPage';

export const menuItemParams = backendParams.extend({
	course: z.string(),
	index0: z.number()
}).strict();	
export type MenuItemParams = z.infer<typeof menuItemParams>;


export const menuItemData = backendData.extend({
	params: menuItemParams,
	menu: menuModel,
}).strict();
export type MenuItemData = z.infer<typeof menuItemData>;


export class MenuItem extends BackendPageConfig<MenuItemData,MenuItemParams>
{
	static readonly pageName = 'backend/menuItem';
	name() { return MenuItem.pageName; }

	access() { return 'venueMenuEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: '',
			template: MenuItemPage
		};
	}

	documents(params:MenuItemParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			menu: {
				type:'object',
				collection: 'menu',
				aggregate: () => [
					{$match: {slug:params.slug}}
				]
			}
		});
	}

	// Operation permissions used by the server...
	permissions()
	{
		return ({
			editMenu: {collection:'menu'},
//XXX ==> image			
			photo: { collection:'menu', assetFolder:'MenuItem-image'},
		});
	}
}
