import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {ImageUploadButtons, ImageUploadDragAndDrop} from 'Shared/forms/ImageUpload';
import {SingleSelect, Wrap} from 'Shared/forms/Inputs';
import {mergeProps, Show} from 'solid-js'
import { usePage } from 'Shared/frontend/PageProvider';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';
import { VenueUrls } from 'Common/VenueUrls';
import { createSignal } from 'solid-js';


export interface IImageSelector
{
	itemProps: IItemProps;
}

export function ImageSelector(props:IImageSelector)
{
	const setImage = value => props.itemProps.setStore(...props.itemProps.location,'image',value);
	const [buttonNode,setButtonNode] = createSignal();

	//WARNING not a tracking scope, so separated out dimensions and image
	const uploadProps = mergeProps(props.itemProps,{
		location: [...props.itemProps.location,'image'],
		permission: 'photo', 
		setImage: setImage,
		page: usePage(), 
		urls: new VenueUrls(usePage().build,usePage().site.key),
		node: buttonNode,
		setNode: setButtonNode
	});

	return (<>
		<header-regular>Image</header-regular>

		<div id='builder_sharePoster'>
			<ImageUploadDragAndDrop {...uploadProps} image={props.itemProps.item.image} dimensions={props.itemProps.item.imageDimensions}>

				<Show when={props.itemProps.item.brandName}>
					<div class='search'>
						<a href={`http://images.google.com/images?um=1&hl=en&safe=active&nfpr=1&q=${props.itemProps.item.brandName}+${props.itemProps.item.name}+logo`} target='_blank'>
							<label>
								Search Google for image &nbsp; <LinkOpen/>
							</label>
						</a>
					</div>
				</Show>

				<Wrap label='Dimensions' required={false}
					instructionsAfterLabel='Will be cropped to square on your menu, but full dimensions will show on details page'
				>
					<SingleSelect {...props.itemProps} field='imageDimensions' required={true} 
						options={ {
							landscape:	'▬ Landscape',
							portrait:	'▋ Portrait',
							square:		'■ Square'
						} } 
					/>
				</Wrap>

				<x-field>
					<ImageUploadButtons {...uploadProps} image={props.itemProps.item.image} dimensions={props.itemProps.item.imageDimensions}/>
				</x-field>
			</ImageUploadDragAndDrop>
		</div>
	</>);
}	

