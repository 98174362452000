import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { Match, Show, Switch } from 'solid-js';
import { css } from '@emotion/css';
import { DateWrap, infoStyle, maxTextLines, nameStyle } from 'Shared/frontend/Poster';
import { ImageSchema,ImageSize } from 'Shared/model/basic';
import { theme } from 'Shared/frontend/ThemeProvider';


const commonInfoStyle = () => css(infoStyle(), {
	fontSize: '0.9em'
});

const inlineStyle = () => css({
	fontSize: 16,
	display: 'flex',
	flexDirection: 'column',
});

const overlayedInfoStyle = () => css(commonInfoStyle(), {
	color: 'white',
	textShadow: 'black 1px 1px 3px',
	backgroundColor: 'rgba(0, 0, 0, 0.22)',
});

const inlineNameStyle = () => css(nameStyle(), {  
	margin: '0.5em 1em',
	fontSize: '0.9em',
	...maxTextLines(2),
	...theme().poster.photoPoster.inlineName
});

const inlineInfoStyle = () => css(commonInfoStyle(), {
	...maxTextLines(4),
	fontSize: '0.85em',
	margin: '0.8em 1em',
});

const overlayedStyle = () => css({
	flexGrow: 1,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	padding: '1em 0 2em 0',
});

const overlayedNameStyle = () => css(nameStyle(), {
	color: 'white',
	textShadow: 'black 1px 1px 3px',
	fontSize: '1.0em'
});	

const portraitInfoStyle = () => css(overlayedInfoStyle(), {
	padding: '0.5em 0.5em'
});

const squareStyle = () => css({
	position: 'relative',
});

const squareImgStyle = () => css({
	width: '100%'
});

const squareInfoStyle = () => css(overlayedInfoStyle(), {
	position: 'absolute',
	bottom: 0,
	left: 0,
	right: 0,
	margin: '0.8em 0 1.5em 0',
	padding: '0.5em 0.5em'
});


interface IPhotoPoster {
	image: ImageSchema,
	folder: string,
	size: ImageSize
}

export function PhotoPoster(props:IPhotoPoster)
{
	return (
		<DateWrap {...props}>
			<Switch>
				<Match when={props.imageDimensions == 'portrait4x5'}>
					<OverlayedInfoPoster {...props} />
				</Match>
				<Match when={props.imageDimensions == 'landscape'}>
					<InlineInfoPoster {...props} />
				</Match>
				<Match when={props.imageDimensions == 'square'}>
					<SquareImagePoster {...props} />
				</Match>

				{/* Used in Specials posters -- FIXME migrate existing to portrait4x5 and delete this case */}	
				<Match when={props.imageDimensions == 'portrait'}>
					<OverlayedInfoPoster {...props} />
				</Match>
			</Switch>
		</DateWrap>
	);
}

function InlineInfoPoster(props)
{
	return (
		<div class={inlineStyle()}>
			<div class={inlineNameStyle()}>{props.name ?? <>&nbsp;</>}</div>

			<img src={imageUrl(props)} />

			<Show when={props.tagline}>
				<div class={inlineInfoStyle()} >
					{props.tagline}
				</div>
			</Show>
		</div>
	);
}

function SquareImagePoster(props)
{
	return (
		<div class={squareStyle()}>
			<div class={inlineNameStyle()}>{props.name ?? <>&nbsp;</>}</div>

			<img class={squareImgStyle()} src={imageUrl(props)} />

			<Show when={props.tagline}>
				<div class={squareInfoStyle()} >
					{props.tagline}
				</div>
			</Show>
		</div>
	);
}

function OverlayedInfoPoster(props)
{
	return (
		<div class={overlayedStyle()} style={`background-image:url('${imageUrl(props)}')`}>
			<Show when={props.name?.length > 0} >
				<div class={overlayedNameStyle()}>{props.name}</div>
			</Show>

			<Show when={props.tagline}>
				<div class={portraitInfoStyle()} >
					{props.tagline}
				</div>
			</Show>
		</div>
	);
}


function imageUrl(props)
{
	if (!props.image?.hash) 
		return '';
	return (new PageFunctions()).createImageUrl2(new VenueUrls(usePage().build,usePage().site.key),props.image.hash,props.image, props.folder,props.size);
}

