import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {StatusIcons} from 'Shared/backend/menu/StatusIcons';
import {SingleSelect} from 'Shared/forms/Inputs';
import { Url } from 'Shared/model/basic';
import {Show} from 'solid-js';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';
import { bottomBarStyle, statusBarStyle } from 'Shared/forms/StatusBar';
import { css } from '@emotion/css';


export interface IStatusBar {
	itemProps: IItemProps;
	link: Url;
}


export function StatusBar(props:IStatusBar)
{
//TODO create a migrator... Replace with a boolean. 'published'==>true, others==>false

	return (
		<div class={css(bottomBarStyle)}>
			<div class={css(statusBarStyle)}> 
				<StatusIcons status={props.itemProps.item.status} />

				<SingleSelect {...props.itemProps} field='status' required={true}
					options={ {'unpublished':'Hide','published':'Show'} } 
				/>

				<Show when={props.itemProps.item.status=='published'}>
					<a href={props.link} class='linkInline' target='_blank'><LinkOpen /></a>
				</Show>
			</div>
		</div>
	);
}

