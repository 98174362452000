import { css, cx } from '@emotion/css'
import { facebookVideo, instagramVideo, youtube } from 'Browser/InputTypes'
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm'
import { InstagramEmbed } from 'Shared/backend/eventPage/artist/InstagramEmbed'
import { FacebookCircle } from 'Shared/backend/icons/FacebookCircle'
import { IconColour } from 'Shared/backend/IconColour'
import { InstagramCircle } from 'Shared/backend/icons/InstagramCircle'
import { LinkOpen } from 'Shared/backend/icons/LinkOpen'
import { VideoCircle } from 'Shared/backend/icons/VideoCircle'
import { SingleSelect, Text, Wrap } from 'Shared/forms/Inputs'
import { Show, Switch } from 'solid-js'
import { Match } from 'solid-js'

const labelStyle = () => css({
	display: 'inline-flex',
	alignItems: 'center',
	gap: '0.2em',
	color: '#666',

	':visited': {
		color: '#666'
	}
});

const activeStyle = () => css({
	color: '#44C4A1'
});


export function Videos(props:ISet)
{
	return (<>
		<h4>Videos
			<x-notes>VOS displays on venue website & drafts Facebook and Instagram posts for venue and artists to post to their socials</x-notes>
		</h4>

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<VideoCircle fill={props.facebookVideoUrl || props.instagramVideoUrl || props.youtubeVideoUrl || props.musicEmbedId ? IconColour.active : IconColour.inactive } />
			</div>

			<div class='sectionWrap'>
				<div class='videoLinks'>
					<FacebookVideo {...props} />
					<InstagramVideo {...props} />
					<Youtube {...props} />

					{/*
						TODO CJ discontinue old vieoembed once new artist import is built currently only shows 
						if was imported from old profile system
					*/}
					<Show when={props.videoEmbedPlatform}>
						<VideoEmbed {...props} />
					</Show>
				</div>
			</div>
		</div>
	</>)
}

function FacebookVideo(props)
{
	return (<>
		<Switch>
			<Match when={props.facebookVideoUrl}>
				<Wrap label={
					<a class={cx(labelStyle(),activeStyle())}>
						<FacebookCircle fill={IconColour.active} />
						&nbsp; Play Facebook video below to check it's working
					</a>
				}>
					<Text {...props} field='facebookVideoUrl' processInput={facebookVideo} placeholder='Paste video share link here' />
				</Wrap>
			</Match>
			<Match when={props.facebook}>
				<Wrap label={
					<a class={labelStyle()} href={`https://www.facebook.com/${props.facebook}/videos`} target='_blank'>
						<FacebookCircle fill={IconColour.inactive} />
						&nbsp;Search Facebook for video &nbsp;
						<LinkOpen fill={IconColour.inactive} />
					</a> 
				}>
					<Text {...props} field='facebookVideoUrl' processInput={facebookVideo} placeholder='Paste video share link here' />
				</Wrap>
			</Match>
			<Match when={true}>
				<Wrap 
					instructionsAfterLabel="Provide the artist's Facebook handle above to enable search"
					label={<span class={labelStyle()}>
						<FacebookCircle fill={IconColour.inactive}/> 
						Facebook video URL
					</span>}
				>
					<Text {...props} field='facebookVideoUrl' processInput={facebookVideo} placeholder='Paste video share link here' />
				</Wrap>
			</Match>
		</Switch>

		<Show when={props.facebookVideoUrl}>
			<div class='videoPlayer'>
				<div class='videoEmbed facebook'>
					<div>
						<iframe class='fbVideo' allowfullscreen
							src={`https://www.facebook.com/plugins/video.php?href=${props.facebookVideoUrl}%2F&show_text=false&width=100&amp;height=60&amp;t=0`}
							style="border:none;overflow:hidden" 
							allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
						</iframe>
					</div>
				</div>
			</div>
		</Show>
	</>);
}

function InstagramVideo(props)
{
	return (<>
		<Switch>
			<Match when={props.instagramVideoUrl}>
				<Wrap label={
					<a class={cx(labelStyle(),activeStyle())}> 
						<InstagramCircle fill={IconColour.active} />
						&nbsp; Play Instagram video below to check it's working
					</a>
				}>
					<Text {...props} field='instagramVideoUrl' processInput={instagramVideo} placeholder='Paste video share link here' />
				</Wrap>
			</Match>
			<Match when={props.instagram}>
				<Wrap label={
					<a class={labelStyle()} href={`https://www.instagram.com/${props.instagram}/reels`} target='_blank'>
						<InstagramCircle fill={IconColour.inactive} />
						&nbsp;Search Instagram for video &nbsp;
						<LinkOpen fill={IconColour.inactive} />
					</a> 
				}>
					<Text {...props} field='instagramVideoUrl' processInput={instagramVideo} placeholder='Paste video share link here' />
				</Wrap>
			</Match>
			<Match when={true}>
				<Wrap 
					instructionsAfterLabel="Provide the artist's Instagram handle above to enable search"
					label={<span class={labelStyle()}>
						<InstagramCircle fill={IconColour.inactive}/> 
						Instagram video URL
					</span>}
				>
					<Text {...props} field='instagramVideoUrl' processInput={instagramVideo} placeholder='Paste video share link here' />
				</Wrap>
			</Match>
		</Switch>

		<Show when={props.instagramVideoUrl}>
			<div class='videoPlayer'>
				<div class='videoEmbed instagram'>
					<div>
						<InstagramEmbed url={props.instagramVideoUrl} />
					</div>
				</div>
			</div>
		</Show>
	</>);
}

function Youtube(props)
{
	const label = () => props.youtubeVideoUrl ?
		<a class={`${labelStyle()} active`}> {/* keep ahref with no link to inherit active labelStatus */}
			<VideoCircle fill={IconColour.active} />
			&nbsp;	
			Play Youtube video below to check it's working
		</a>
	:
		<a class={`${labelStyle()} inactive`} href={`http://www.youtube.com/results?search_query=${props.name}+music`} target='_blank'>
			<VideoCircle fill={IconColour.inactive} />
			&nbsp;	Search YouTube for video 	&nbsp;	
			<LinkOpen fill={IconColour.inactive} />
		</a>;


	return (<>
		<Wrap label={label()}>
			<Text {...props} field='youtubeVideoUrl' processInput={youtube} />
		</Wrap>

		<Show when={props.youtubeVideoUrl}>
			<div class='videoPlayer'>
				<div class='videoEmbed youtube'>
					<div>
						<iframe class='youtubeVideo' allowfullscreen
							src={`https://www.youtube.com/embed/${props.youtubeVideoUrl}?modestbranding=1`} 
							title='YouTube video player'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' 
						></iframe>
					</div>
				</div>
			</div>
		</Show>
	</>);
}

function VideoEmbed(props)
{
	return (<>
		<Wrap label='Video embed provider'>
			<SingleSelect {...props} field='videoEmbedPlatform' options={{
				youtube: 'Youtube',
				vimeo: 'Vimeo',
				facebookVideo: 'Facebook Video'
			}} />
		</Wrap>

		<Wrap label='Video embed ID'>
			<Text {...props} field='videoEmbedId' />
		</Wrap>
	</>);
}

