import {DocumentQueries} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import { artistDoc as artistSchema } from 'Shared/model/Artist';
import * as t from 'Shared/model/basic';
import {ArtistPageConfig, artistData, artistParams} from 'Common/pages/artist/ArtistPageConfig';
import {ManagerPage} from 'Shared/artists/ManagerPage';


export const managerParams = artistParams.extend({
}).strict();	
export type ManagerParams = z.infer<typeof managerParams>;


export const managerData = artistData.extend({
	artists: z.array(artistSchema),

	/* Component data types: */
	createArtist: z.object({
		id: t.id,
		name: z.string()
	})
}).strict();
export type ManagerData = z.infer<typeof managerData>;



//XXX warning - manager here refers to anyone who manages an artist. When you log into to profile system
//              you get this page.

export class Manager extends ArtistPageConfig<ManagerData,ManagerParams>
{
	static readonly pageName = 'artist/manager';
	name() { return Manager.pageName; }

	settings()
	{
		return {
			...super.settings(),
			template: ManagerPage
//			template: this.templates.loginOrRegister
		};
	}

	documents(params:ManagerParams): DocumentQueries
	{
		return ({
//			...bannerQuery(),
			artists: {
				type:'array',
				collection: 'artist'
			}
		});
	}

	permissions()
	{
		return ({
			create: {collection:'artist'},
		});
	}
}

