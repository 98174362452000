import { z } from 'zod';
import * as t from 'Shared/model/basic';
import {JSX} from 'solid-js';


//TODO move to separate file eventually
/* ------- Page settings ------- */
export const pageSettingsType = z.object({
	htmlClasses: z.string(),
	template: z.union([
		z.string(),
		z.function().args(z.any()).returns(<z.ZodType<JSX.Element>> z.any())
	])
})
.partial();

export type PageSettingsType = z.infer<typeof pageSettingsType>;


/* ---- Venue page settings ---- */

const venuePageSettings = pageSettingsType.extend({
})
.partial();


/* ---- Venue settings ---- */

export const generalSettings = z.object({
	enabled: z.boolean(),
	timezone: z.string(),
	dayStart: t.time,
	dayEnd: t.time,
	testNow: t.date.optional(),
});

//export const homeTypes = z.enum(['standardHome','differentDrummerHome','oldHome']);   XXX can I get from venueSettingsType below?

export const frontendFonts = z.enum(['questrial','theDock','differentDrummer']);
export type FrontendFonts = z.infer<typeof frontendFonts>;


export const frontendSettings = z.object({
	venueClass: z.string(),
	fonts: frontendFonts
});

export const backendSettings = z.object({
	navTemplate: z.string()
});

export const homeTypes = z.enum(['standardHome','oldHome','differentDrummerHome']);

export const standardHomeFrontendNav = z.object({
	shrinkOnScroll: z.boolean()
});

export const standardHomeFrontendDesign = z.object({
	type: z.enum(['widgetList']),	// | sections | widgetTree | eventGrid | grid
	entry: z.enum(['backend']),   // | static | tabs
});

export const standardHomeFrontend = z.object({
	nav: standardHomeFrontendNav,
	design: standardHomeFrontendDesign,
	javascriptEmbedCode: z.string()
});

export const standardContactFrontend = z.object({
	javascriptEmbedCode: z.string()
});

export const standardReservationsFrontend = z.object({
	javascriptEmbedCode: z.string()
});

export const standardHome = z.object({
	type: z.literal('standardHome'),

	frontend: standardHomeFrontend
});

export const standardMenu = z.object({
	itemType: z.enum(['standard','imageless']),
});

export const standardContact = z.object({
	type: z.literal('standardContact'),
	frontend: standardContactFrontend
});

export const standardReservations = z.object({
	type: z.literal('standarReservations'),
	frontend: standardReservationsFrontend
});


export const pageSettings = z.discriminatedUnion('type',[		//XXX Q: can we use homeTypes above?
	standardHome,
	z.object({type:z.literal('oldHome') }),
	z.object({type:z.literal('differentDrummerHome') }),
]);
export type PageSettings = z.infer<typeof pageSettings>;


export const pageEntry = z.record(
	z.enum(['home']),					//XXX home ,events, ...
	pageSettings
);

export const venueSettingsType = z.object({
	general: generalSettings,
	frontend: frontendSettings,
	backend: backendSettings,
	pages: pageEntry
});

export type VenueSettingsType = z.infer<typeof venueSettingsType>;


/* ------- VenueAndSettingsWithShortcuts ------ */
export const venueAndPageSettingsType = pageSettingsType.merge(venueSettingsType);

export type VenueAndPageSettingsType = z.infer<typeof venueAndPageSettingsType>;

