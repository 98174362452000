
import { css } from '@emotion/css';
import {BaseFooter} from 'Shared/frontend/footer/BaseFooter';
import {IConfig, Nav} from 'Shared/frontend/layouts/StandardDefaultLayout';
import {MobileFooterNav} from 'Shared/frontend/navWidget/MobileFooterNav';
import { layout, sizes } from 'Shared/frontend/ThemeProvider';
import {VenueDoc} from 'Shared/model/venue';
import {Switch,Match } from 'solid-js';

//XXX could show/hide using JS rather than media points
const desktopNavStyle = () => css({
	[`@media (max-width:${sizes().flipToMobileMenu}px)`]: {
		display: 'none'
	},
});

const mobileNavStyle = () => css({
	[`@media (min-width:${sizes().flipToMobileMenu}px)`]: {
		display: 'none'
	}
});

const contentStyle = () => css({
	/* 
		position: relative is required here as the navs are using position: absolute. 
		Note that position: fixed doesn't work with max page widths and page margins.
	*/
	position: 'relative',

	/* Stretch short pages so the footer stays at the bottom: */
	display: 'flex',
	flexDirection: 'column',

	/* 
		The page contents are in charge of the max-width and the margins as there are various weird classes
		(e.g. parallax - no margin, narrow menu page - small margins). This is a good default that can be used
		by more boring child pages: 
			maxWidth: `calc(min(100%,${sizes().maxPageWidth}px) - 4em)`,
			margin: '0 auto',
	*/
});

const mainStyle = () => css({
	flexGrow: 1,
	marginTop: 0,
	marginBottom: '2em',
	marginLeft: layout().leftMargin,
	marginRight: layout().rightMargin
});


interface IOuterBasic {
	classes: string;
	mainContent;
	nav: Nav;
	footer;
	venue: VenueDoc;
	config: IConfig;
	pageName: string;
	children;
}

export function OuterBasic(props:IOuterBasic)
{
//TODO Remove outer <main> in OuterBasic.tsx - it has <header> in it

	return (
		<div id='content' class={`${props.classes ?? ''} ${contentStyle()}`}>
			<div class={desktopNavStyle()}>
				{props.children}
			</div>

			<main class={mainStyle()}>
				{props.mainContent}
			</main>

			<Switch>
				<Match when={props.footer!=null}>
					{props.footer}
				</Match>
				<Match when={true}>
					<BaseFooter venue={props.venue} />
				</Match>
			</Switch>
			
			<div class={mobileNavStyle()}>
				<MobileFooterNav {...props} />
			</div>
		</div>
	);
}

