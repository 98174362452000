import {CSSInterpolation} from '@emotion/css';

export const theme:CSSInterpolation = {
	//XXX may wish to separate sanitise sometime
	/* ---- Sanitise -----*/
	'*': {
		boxSizing: 'border-box'
	},

	'html,body': {
		margin: 0,
		width: '100%',
		minWidth: '100%'
	},

	/* Remove dotted border in select elements in FF: */
	'select:-moz-focusring': {
		color: 'transparent',
		textShadow: '0 0 0 #000'
	},

	a: {
		textDecoration: 'none',

		'&:visited': {
			color: 'inherit'
		}
	},


	/* ---- Theme -----*/
	body: {
		font: '15px "Open Sans",Helvetica,Arial,sans-serif',
		/* Prevents horizontal movement - important for mobile (XXX is a sanitise property) */
		overflowX: 'hidden'
	},

	h3: {
		fontSize: '1.2em',
		color: '#42627b',
		lineHeight: '1.5em',
		textTransform: 'uppercase',
		textAlign: 'left',
		fontWeight: 'initial'
	},

	h4: {
		textTransform: 'uppercase',
		color: '#000000',
		fontSize: '1em',
		margin: '12px 0 6px 0',
		padding: 6
	},

	section: {
		margin: '0 0 36px 0'
	},

	button: {
		textAlign: 'center',
		verticalAlign: 'middle',
		backgroundColor: '#7e94a5',
		borderRadius: 3,
		border: '1px solid #728a9d',
		color: '#FFF',
		padding: '8px 12px',
		fontSize: 13,
		fontWeight: 400,
		lineHeight: '16px'
	}
};

