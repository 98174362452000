import { Checkbox, MultiSelect, SingleSelect, Text, Wrap, updateStoreAndDb } from 'Shared/forms/Inputs';
import { Date, DateTime, Time} from 'Shared/forms/DateTimeInputs';
import { Show, Switch,Match} from 'solid-js';
import { Location } from 'Common/config/PageConfigTypes';
import { Id } from 'Common/Id';
import { reconcile, SetStoreFunction} from 'solid-js/store';
import { EditComponent } from 'Common/components/EditComponent';
import { locateSubDocument } from 'Common/ViewUtils';
import { EventCalendarSettings } from 'Shared/model/basic';
import { Document, IPageData } from 'Common/PageConfig';
import { createMemo } from 'solid-js';
import { DateTime as Luxon } from 'luxon';
import { css, cx } from '@emotion/css';

const fieldStyle = () => css({
	display: 'flex',
	gap: '0.5em',

	label: {
		whiteSpace: 'nowrap'
	},

	'input[type=checkbox]': {
		width: 'unset'
	}
});

const paneStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	gap: '0.6em',

	'x-field': {
		marginBottom: 0
	}
});

const dateLimitsStyle = () => css({
	display: 'grid',
	gridTemplateColumns: 'auto auto',

	'x-field': {
		marginBottom: 0
	}
});

const monthlyWeekdayStyle = () => css({
	minWidth: '8em'
});

const alertStyle = () => css({
	label: {
		color: 'red'
	}
});


export interface ICalendarProps<Data extends IPageData,Doc extends Document> {
	docId: Id;
	store: Doc;
	setStore: SetStoreFunction<Doc>;
	location: Location;
	permission: string;
	component: EditComponent<Data,Document>;
}

export function CalendarEntry<Data extends IPageData>(props:ICalendarProps<Data,string & {_id:string}>)
{
	let calendarEntry!: HTMLElement;

	const frequency = () => props.store.times.frequency;

	const updateFrequency = e => {
		const times = changeFrequency(e.target.value,props.store.times);
		updateStoreAndDb(times,{...props,location:[],field:'times'},() => {});
		props.setStore(props.location,reconcile(times));
	};

	return (
		<div class={paneStyle()} ref={calendarEntry}>
			<select onChange={updateFrequency}>
				<option value='once'    selected={frequency()=='once'}>Does not repeat</option>
				<option value='daily'   selected={frequency()=='daily'}>Repeats daily</option>
				<option value='weekly'  selected={frequency()=='weekly'}>Repeats weekly</option>
				<option value='monthly' selected={frequency()=='monthly'}>Repeats once a month</option>
			</select>

			<Switch>
				<Match when={frequency()=='once'}>
					<SingleEvent {...props} />
				</Match>
				<Match when={true}>
					<RecurringEvent {...props} />
				</Match>
			</Switch>

			<Wrap label='Date looks messy on poster? Write a custom label to override it'>
				<Text {...props} field='customDateLabel' />
			</Wrap>
		</div>
	);
}

function SingleEvent(props)
{
	const times = createMemo(() => locateSubDocument(props.store,props.location));

	let singleDateTimes!: HTMLDivElement;
	let startSingleField!: HTMLElement;
	let endSingleField!: HTMLDivElement;

	const earlyEnd = () => times().endSingle && Luxon.fromISO(times().endSingle) < Luxon.fromISO(times().startSingle);

	return (<>
		<div ref={singleDateTimes}>
			<Wrap label='Start time' classes={fieldStyle()}>
				<DateTime ref={startSingleField} {...props} field='startSingle' hideClear={true} required={true} />
			</Wrap>
		</div>

		<Wrap label='End time' classes={cx(fieldStyle(),earlyEnd() && alertStyle())}>
			<DateTime ref={endSingleField} {...props} field='endSingle' hideClear={true} placeholder='xx/xx/xxxx xx:xx'/>
		</Wrap>

		<Wrap label='Or end at venue close' classes={fieldStyle()}>
			<input type='checkbox' checked={!times().endSingle} 
				onClick={e => {
					if (times().endSingle != null) 
						updateStoreAndDb(null,{...props,field:'endSingle'},()=>{});
					else
						e.preventDefault();
				}}
			/>
		</Wrap>
	</>);
}

function RecurringEvent(props)
{
	//XXX Google calendar also has a "custom" option that may be useful in time 
	//TODO for weekly append 'weekdays' and 'weekends' as options... 

	const days = {
		mon:'Monday', 
		tue:'Tuesday', 
		wed:'Wednesday', 
		thu:'Thursday', 
		fri:'Friday', 
		sat:'Saturday', 
		sun:'Sunday'
	};

	let startTimeField!: HTMLElement;
	let endTimeField!: HTMLDivElement;
	let recurringTimesNode!: HTMLDivElement;
	let startDateField!: HTMLDivElement;
	let endDateField!: HTMLDivElement;

	const times = createMemo(() => locateSubDocument(props.store,props.location));

	const earlyDate = () => times().endDate && Luxon.fromISO(times().endDate) < Luxon.fromISO(times().startDate);

	const earlyTime = () => times().endTime && Luxon.fromISO(times().endTime) < Luxon.fromISO(times().startTime);

	return (<>
		<div>
			<Show when={times().frequency=='weekly'}>
				<div classList={{[alertStyle()]: times().weekdays?.length==(null || 0) }}>
					<Wrap label='Which day/s is this on?'>
						<MultiSelect {...props} field='weekdays' options={days} />
					</Wrap>
				</div>
			</Show>

			<Show when={times().frequency=='monthly'}>
				<div class={fieldStyle()}> 
					<label>Repeats monthly on the</label>

					<SingleSelect {...props} field='week' required={true}
						options={ {
							first:'First', 
							second:'Second', 
							third:'Third', 
							fourth:'Fourth', 
							last:'Last'
						} }
					/>

					<SingleSelect class={monthlyWeekdayStyle()} {...props} field='weekday' required={true} options={days} />
				</div>
			</Show>

			<div ref={recurringTimesNode}>
				<Switch>
					<Match when={times().allDay}>
						<Wrap classes={fieldStyle()} label='All day'>
							<Checkbox {...props} field='allDay' />
						</Wrap>
					</Match>
					<Match when={true}>
						<div class={dateLimitsStyle()}>
							<Wrap label='Start' classes={fieldStyle()}>
								<Time ref={startTimeField} {...props} field='startTime' hideClear={true} required={true} placeholder='xx:xx' />
							</Wrap>

							<Wrap label='End' classes={cx(fieldStyle(),earlyTime() && alertStyle())}>
								<Time ref={endTimeField} {...props} field='endTime' hideClear={true} placeholder='xx:xx' />
							</Wrap>

							<Wrap classes={fieldStyle()} label='All day'>
								<Checkbox {...props} field='allDay' />
							</Wrap>

							<Wrap label='Or end at venue close' classes={fieldStyle()}>
								<input type='checkbox' checked={! times().endTime} 
									onClick={e => {
										if (times().endTime != null) 
											updateStoreAndDb(null,{...props,field:'endTime'},()=>{});
										else
											e.preventDefault();
									}}
								/>
							</Wrap>
						</div>
					</Match>
				</Switch>
			</div>

			<Wrap classes={fieldStyle()} label='Start repeating these events on'>
				<Date ref={startDateField} {...props} field='startDate' required={true} hideClear={true} />
			</Wrap>

			<Wrap classes={fieldStyle()} label='Run indefinitely'>
				<input type='checkbox' checked={! times().endDate}
					onClick={e => {
						if (times().endDate != null) 
							updateStoreAndDb(null,{...props,field:'endDate'},()=>{});
						else
							e.preventDefault();
					}}
				/>
			</Wrap>

			<Wrap classes={cx(fieldStyle(),earlyDate() && alertStyle())} label='Or ends on'>
				<Date ref={endDateField} {...props} field='endDate' hideClear={true} />
			</Wrap>
		</div>
	</>);
}

/*
	PROGRAM DESIGN ISSUE
	There is an argument for adding separate model-level code that applies this stuff in the server and I guess 
	in the browser. It could probably be used generally to set initial values and to delete old irrelevant data.
	The model code would apply to documents (not pages) and kick in whenever a document is updated. 
	The code needs to run in the browser as well as the server. 
	NB calculating the dates separately in both places is a bit weird. This would be an argument against
	   using an optimistic updates and just applying in the server (or browser? NB browser has most of the other
	   functionality).
*/

function changeFrequency(frequency,times:EventCalendarSettings)
{

	const recurringDefaults = {
		startDate: Luxon.now().set({hour:0,minute:0,second:0})
			.toISO({includeOffset:false,suppressMilliseconds:true}),
		startTime: '12:00:00'
	};

	const recurringOptionalFields: {[field:string]:true} = {
		frequency: true,
		endDate: true,
		endTime: true
	};

	let defaults;
	let optionalFields!: {[field:string]:true};

	/* Note a second update is being performed. Not ideal. */
	switch(frequency) {
		case 'once':
			defaults = {
				startSingle: Luxon.now().plus({days:1}).set({hour:12,minute:0,second:0})
					.toISO({includeOffset:false,suppressMilliseconds:true})
			};
			optionalFields = {
				frequency: true,
				endSingle: true
			}
			break;
		case 'daily':
			defaults = recurringDefaults;
			optionalFields = recurringOptionalFields;
			break;
		case 'weekly':
			defaults = {...recurringDefaults,
				weekdays: []
			};
			optionalFields = recurringOptionalFields;
			break;
		case 'monthly':
			defaults = {...recurringDefaults,
				week: 'first',
				weekday: 'mon'
			};
			optionalFields = recurringOptionalFields;
			break;
	}

	/* Set defaults: */
	const newTimes = {...defaults, ...times, frequency:frequency};

	/* Delete inapplicable fields: */
	for (const field of Object.keys(newTimes)) 
		if (defaults[field]==undefined && optionalFields[field]==undefined)
			delete newTimes[field];

	return newTimes;
}

