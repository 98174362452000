import { css } from '@emotion/css';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { DateTime } from 'luxon';
import { usePage } from 'Shared/frontend/PageProvider';
import { LineupSet } from 'Shared/model/lineup';
import { Match, Show, Switch } from 'solid-js';
import { palette, theme } from 'Shared/frontend/ThemeProvider';


const wideSummaryAndImageStyle = () => css({
	display: 'flex',
	gap: '1.5em'
});

const imageStyle = () => css({
	flex: 4,

	img: {
		width: '100%'
	}
});

const wideSummaryStyle = () => css({
	flex: 6,
	display: 'flex',
	flexDirection: 'column'
});

const wideNameAndTimeStyle = () => css(theme().event.artist.wide.nameAndTime,{
	h3: {
		marginBottom: 0
	}
});

const nameStyle = () => css(theme().event.artist.heading,{
	display: 'inline',
});

const narrowNameAndTimeStyle = () => css(theme().event.artist.narrow.nameAndTime,{
	padding: '0.5em',
	color: palette().background
});

const narrowNameStyle = () => css(theme().event.artist.heading,{
	display: 'inline',
	color: palette().background 
});

const genresAndLocationStyle = () => css(theme().event.artist.genresAndLocation, {
	gridArea: 'genre',
	textTransform: 'uppercase',
	fontSize: '1em',
	fontWeight: 300
});

const genresStyle = () => css({
	display: 'inline',
	marginRight: '0.5em'
});

const locationStyle = () => css({
	display: 'inline',
	whiteSpace: 'nowrap'
});

const taglineStyle = () => css(theme().blockquote,{
	fontSize: '1.25em'
});


export function ArtistImageAndSummary(props)
{
	return (
		<div>
			<Show when={props.wide != undefined}>
				<Switch>
					<Match when={props.wide}>
						<WideImageAndSummary {...props} />
					</Match>
					<Match when={true}>
						<NarrowImageAndSummary {...props} />
					</Match>
				</Switch>
			</Show>
		</div>
	);
}

function WideImageAndSummary(props)
{
	return (
		<div class={wideSummaryAndImageStyle()}>
			<Show when={props.image}>
				<div class={imageStyle()}>
					<img src={imageUrl(props)} />
				</div>
			</Show>

			<div class={wideSummaryStyle()}>
				<div class={wideNameAndTimeStyle()}>
					<h3 class={nameStyle()}>{props.name}</h3>
					<Show when={props.setTime}>
						{' '}
						<span>
							{timeLabel(props.setTime)}
						</span>
					</Show>
				</div>

				<GenresAndLocation {...props} />

				<Show when={props.tagline}>
					<blockquote class={taglineStyle()}>{props.tagline}</blockquote>
				</Show>
			</div>
		</div>
	);
}

function NarrowImageAndSummary(props)
{
	return (
		<div>
			<div class={narrowNameAndTimeStyle()}>
				<h3 class={narrowNameStyle()}>{props.name}</h3>
				<Show when={props.setTime}>
					{' '}
					<span>
						{timeLabel(props.setTime)}
					</span>
				</Show>
			</div>

			<Show when={props.image}>
				<div class={imageStyle()}>
					<img src={imageUrl(props)} />
				</div>
			</Show>

			<GenresAndLocation {...props} />

			<Show when={props.tagline}>
				<blockquote class={taglineStyle()}>{props.tagline}</blockquote>
			</Show>
		</div>
	);
}

function timeLabel(value)
{
	const time = DateTime.fromISO(value);
	return time.toFormat('mm') == '00' ? time.toFormat('ha') : time.toFormat('h:mma');
}

function imageUrl(props)
{
	if (!props.image?.hash) return '';
	return (new PageFunctions()).createImageUrl2(new VenueUrls(usePage().build,usePage().site.key),
		props.image.hash,props.image,'Lineup-image','medium');
}

function genresExist(props)
{
	return props.mainGenre || props.otherGenre1 || props.otherGenre2 || props.extraGenre;
}

function locationExists(props)
{
	return props.city || props.state || props.country;
}

function GenresAndLocation(props)
{
	return (
		<Show when={genresExist(props) || locationExists(props)}>
			<div class={genresAndLocationStyle()}>
				<Show when={genresExist(props)}>
					<Genres {...props} />
				</Show>
				{' ' /* Wrapping space */}
				<Show when={locationExists(props)}>
					<Location {...props} />
				</Show>
			</div>
		</Show>
	);
}

function Genres(props:LineupSet)
{
	const genres = () => {
		let sep = '', label = '';
		if (props.mainGenre)   { label += props.mainGenre; sep = ', '; }
		if (props.otherGenre1) { label += `${sep}${props.otherGenre1}`; sep = ', '; }
		if (props.otherGenre2) { label += `${sep}${props.otherGenre2}`; sep = ', '; }
		if (props.extraGenre)    label += `${sep}${props.extraGenre}`;
		return label;
	};

	return (
		<div class={genresStyle()}>
			{genres()}
		</div>
	);
}

function Location(props:LineupSet)
{
	const cityOrStateLabel = () => {
		let sep = '',label='';

		if (props.country == 'Australia') {
			if (props.city)  { label += props.city; sep = ', '; }
			if (props.state)   label += `${sep}${props.state})`;
		}
		else {
			if (props.city)  { label += props.city; sep = ', '; }
			if (props.state)   label += sep + props.state;
			if (props.country) label += sep + props.country;
		}
		return label;
	};

	return (
		<Show when={locationExists(props)}>
			<div class={locationStyle()}>
				(<Switch>
					<Match when={props.city!=null || props.state!=null}>
						{cityOrStateLabel()}
					</Match>
					<Match when={props.country!=null}>
						{props.country}
					</Match>
				</Switch>)
			</div>
		</Show>
	);
}

