import { genres, performanceType} from 'Shared/ArtistTypes';
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm';
import { IconColour } from 'Shared/backend/IconColour';
import { InfoCircle } from 'Shared/backend/icons/InfoCircle';
import { SingleSelect, Text, Wrap } from 'Shared/forms/Inputs';

export function Style(props:ISet)
{
	return (<>
		<h4>Style</h4>

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<InfoCircle fill={props.mainGenre || props.extraGenre ? IconColour.active : IconColour.alert} />
			</div>

			<div class='genres'>
				<Wrap label='Performance type'>
					<SingleSelect {...props} field='performanceType' options={performanceType} placeholder='Original Band' />
				</Wrap>

				<Wrap label='Main genre'>
					<SingleSelect {...props} field='mainGenre' options={genres} />
				</Wrap>

				<Wrap label='2nd genre'>
					<SingleSelect {...props} field='otherGenre1' options={genres} />
				</Wrap>

				 {/* using class hide because removing field breaks artist import but we dont want to display */}
				<div class='hide'>
					<Wrap label='Other genre 2'>
						<SingleSelect {...props} field='otherGenre2' options={genres} />
					</Wrap>
				</div>

				<Wrap label='Custom genre' instructionsAfterLabel='Type if not in the above genre list'>
					<Text {...props} field='extraGenre' />
				</Wrap>
			</div>
		</div>
	</>);
}

