import { css } from '@emotion/css'
import { For,Switch,Match,Show} from 'solid-js';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { PageFunctions } from 'Browser/PageFunctions';
import { Slide, Slider } from 'Browser/widgets/frontend/Slider';
import { BottomInfo, Info, TopInfo } from 'Shared/frontend/showcaseWidgets/Info';
import { sizes } from 'Shared/frontend/ThemeProvider';
import { createSignal } from 'solid-js';
import { onMount } from 'solid-js';
import { onCleanup } from 'solid-js';
import { isServer } from 'solid-js/web';

const topInfoStyle = () => css({
	textAlign: 'center'
});

const bottomInfoStyle = () => css({
	textAlign: 'center'
});

const imageCaptionStyle = () => css({
	position: 'absolute',
	bottom: 0,
	padding: '1em',
	zIndex: '20',
	width: '100%',
	background: 'rgba(0, 0, 0, 0.446)',
	textAlign: 'center',
	height: 'fit-content'
});

const sideImageStyle = (imageOnLeft:boolean) => css({
	float: imageOnLeft ? 'left' : 'right',
	width: '50%',
	marginTop: '1em',

	/*
		I think the goal here might be to align the side of the image with the side of the 
		text underneith and above. Grids could almost be used for this, but doesn't allow for
		wrapping around floats. Currently doesn't quite line up.
	 */
	paddingLeft: imageOnLeft ? 8 : 16,
	paddingRight: imageOnLeft ? 16 : 8,   //TODO use a fluid fonts?
	marginLeft: imageOnLeft ? 0 : '1%',
	marginRight: imageOnLeft ? '1%' : 0, 

	[`@media (max-width:${sizes().flipToOneColumn}px)`]: {
		width: '100%'
	},
});

//XXX maybe combine with imageAboveStyle()??
const imageAboveStyle = () => css({
	width: '100%',
	marginTop: '1em',
});

const imageBelowStyle = () => css({
	width: '100%',
	marginTop: '1em',
});

const landscapeStyle = () => css({
	width: '100%',
	maxHeight: 700,
	objectFit: 'cover',
	objectPosition: 'center'
});

const overlayedStyle = () => css({
	position: 'relative'
});

const imageAndCaptionStyle = () => css({
	// widget switches from single imageWrap to multiple images in slideshow using .navigationWrapper
	position: 'relative',
	padding: 0,
	width: '100%',
});


// TODO combine: teaser & itemTeaser, title & itemTitle,  etc

export function Slideshow(props)
{
	const itemProps = item => props.sameInfo ? {} : {
		...item,
		title: item.itemTitle, 
		teaser: item.itemTeaser,
		tagline: item.itemTagline, 
		details: item.itemDetails,
		link: item.buttonLink,
		addButton: item.addItemButton
	};

	return (
		<Switch>
			<Match when={props.items.length == 1}>
				<Layout {...props} {...itemProps(props.items[0])}>
					<Image {...props} {...props.items[0]} />
				</Layout>
			</Match>
			<Match when={props.sameInfo}>
				<Layout {...props}>
					<Slider>
						<For each={props.items}>{item =>
							<Slide>
								<Image {...props} {...item} />
							</Slide>
						}</For>
					</Slider>
				</Layout>
			</Match>
			<Match when={true}>
				<Slider>
					<For each={props.items}>{item =>
						<Slide>
							<Layout layout={props.layout} {...itemProps(item)}>
								<Image {...props} {...item} />
							</Layout>
						</Slide>
					}</For>
				</Slider>
			</Match>
		</Switch>
	);
}

function Layout(props)
{
//XXX could possibly reuse the "wide" code elsewhere	
	const [wide,setWide] = createSignal(isServer ? true : undefined);

	onMount(() => {
		const oneColumn = sizes().flipToOneColumn;
		setWide(window.innerWidth >= oneColumn);
		const widthHandler = () => setWide(window.innerWidth >= oneColumn);
		window.addEventListener('resize', widthHandler);
		onCleanup(() => window.removeEventListener('resize', widthHandler));
	});

	return (
		<Switch>
			<Match when={props.layout == 'ss_imageLeft' || props.layout == 'ss_imageRight'}>
				<Switch>
					<Match when={wide()}>
						<SideImage {...props} isWide={true} />
					</Match>
					<Match when={true}>
						<ImageAbove {...props} isWide={false} />
					</Match>
				</Switch>
			</Match>
			<Match when={props.layout == 'ss_imageAbove'}>
				<ImageAbove {...props} />
			</Match>
			<Match when={props.layout == 'ss_imageBelow'}>
				<ImageBelow {...props} />
			</Match>
			<Match when={props.layout == 'slideshow'}>
				<ImageOverlayed {...props} />
			</Match>
		</Switch>
	);
}

function SideImage(props)
{
	return (<>
		<div class={sideImageStyle(props.layout == 'ss_imageLeft')}>
			{props.children}
		</div>

		<Info {...props} />

		<div style='clear:both' />
	</>);
}

function ImageAbove(props)
{
	return (<>
		<div class={topInfoStyle()}>
			<TopInfo {...props} />
		</div>

		<div class={imageAboveStyle()}>
			{props.children}
		</div>

		<div class={bottomInfoStyle()}>
			<BottomInfo {...props} />
		</div>
	</>);
}

function ImageBelow(props)
{
	return (<>
		<div class={imageBelowStyle()}>
			{props.children}
		</div>

		<Info {...props} />
	</>);
}

function ImageOverlayed(props)
{
	return (
		<div class={overlayedStyle()}>
			{props.children}

			<Info {...props} />
		</div>
	);
}


interface IImage {
	carouselImageFolder: string;
	carouselImageSize;
}

function Image(props:IImage)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);
	const imageUrl = () => (new PageFunctions()).createImageUrl2(urls,props.image?.hash ?? '',props.image,props.carouselImageFolder,props.carouselImageSize);

	return (
		<div class={imageAndCaptionStyle()}>
			<img class={landscapeStyle()} src={imageUrl()} />

			<Show when={props.itemImageCaption}>
				<div class={imageCaptionStyle()}>{props.itemImageCaption}</div>
			</Show>
	   </div>
	);
}

