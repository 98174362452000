import { css } from '@emotion/css';
import {Facebook} from 'Shared/frontend/icons/Facebook';
import {Instagram} from 'Shared/frontend/icons/Instagram';
import {Snapchat} from 'Shared/frontend/icons/Snapchat';
import {TikTok} from 'Shared/frontend/icons/TikTok';
import {Twitter} from 'Shared/frontend/icons/Twitter';
import {For, Match, Switch, Show} from 'solid-js';

const iconStyle = () => css({
	display: 'inline-block',
	position: 'relative',
	verticalAlign: 'middle',
	width: '3.6em',
	height: 'auto',
	padding: '0 0.3em',

	svg: {
		fill: '#e2e2e2'
	},

	span: {
		background: '#eef',
		position: 'absolute',
		bottom: 0,
		fontSize: '0.8em',
		textAlign: 'center',
		left: '50%',
		transform: 'translate(-50%, 0%)',
		color: '#28363f',
		fontWeight: 'bold',
		padding: '0 1ch'
	}
});


interface ISocialIcons {
	venue: VenueDoc;
}

export function SocialIcons(props:ISocialIcons)
{
	return (
		<div class='socialIcons'>
			<Show when={props.venue.facebook}>
				<a href={`https://www.facebook.com/${props.venue.facebook}`} target='_blank'>
					<div class={iconStyle()}>
						<Facebook />
					</div>
				</a>
			</Show>

			<Show when={props.venue.instagram}>
				<a href={`https://www.instagram.com/${props.venue.instagram}`} target='_blank'>
					<div class={iconStyle()}>
						<Instagram />

						{/* custom hack for townie to enable 2x instagram accounts */}
						<For each={props.venue.otherSocialMedia}>{ other =>
							<Show when={other.title != 'Instagram'}>
								<span>Venue</span>
							</Show>
						}</For>
					</div>
				</a>
			</Show>

			{/* custom hack for townie to enable 2x instagram accounts */}
			<For each={props.venue.otherSocialMedia}>{ other =>
				<Switch>
					<Match when={other?.title != 'Instagram'}>
						<a href={other?.url} target='_blank'>
							<div class={iconStyle()}>
								<Instagram />
								<span>{other.title.replace('Instagram','')}</span>
							</div>
						</a>
					</Match>
					<Match when={true}>
						<a href={other.url} target='_blank'>
							<div class={`${iconStyle()} other`}>{other.title}</div>
						</a>
					</Match>
				</Switch>
			}</For>

			<Show when={props.venue.twitter}>
				<a href={`https://www.twitter.com/${props.venue.twitter}`} target='_blank'>
					<div class={iconStyle()}>
						<Twitter />
					</div>
				</a>
			</Show>

			<Show when={props.venue.tiktok}>
				<a href={`https://www.tiktok.com/${props.venue.tiktok}`} target='_blank'>
					<div class={iconStyle()}>
						<TikTok />
					</div>
				</a>
			</Show>

			<Show when={props.venue.snapchat}>
				<a href={`https://www.snapchat.com/add/${props.venue.snapchat.replace('@','')}`} target='_blank'>
					<div class={iconStyle()}>
						<Snapchat />
					</div>
				</a>
			</Show>
		</div>
	);
}

