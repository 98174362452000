import {Wrap,Text} from 'Shared/forms/Inputs';
import {FacebookAuthButton} from 'Shared/artists/FacebookAuthButton';
import {GoogleAuthButton} from 'Shared/artists/GoogleAuthButton';
import {Show, createSignal, mergeProps} from 'solid-js';
import {UserData} from 'Common/pages/artist/User';
import {outerPage} from 'Shared/artists/OuterPage';
import {theme} from 'Shared/artists/Theme';
import {forms} from 'Shared/artists/Forms';
import {css, injectGlobal} from '@emotion/css';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {PasswordFormContent} from 'Shared/users/SetPassword';
import { createStore } from 'solid-js/store';

function connectStyle()
{
	return css({
		button: {
			display: 'flex',
			margin: '0 0 12px 0'
		}
	});
}


export function UserPage(props:UserData)
{
console.log('UserPage props.person._id:',props.person._id);	

	const [personStore,setPersonStore] = createStore(props.person);

//XXX or am I better passing through all props?
	const person = mergeProps({
		page: props.page,
		docId: personStore._id,
		permission: 'edit',
		collection: 'Person',
		location: [],
		store: personStore,
		setStore: setPersonStore,
	});


	injectGlobal([theme,forms]);

	return (
		<PageProvider page={props.page}>
			<div class={outerPage()}>
			<nav>
				<x-breadcrumbs>
					<a href='/manager'>Top</a>
				</x-breadcrumbs>
				<div class='logoutLink'>
					<a href='/logout'>Logout</a>
				</div>
			</nav>		

			<h3>Edit user</h3>

			<Details {...person} />

			{/* XXX Require at least one method to log in. If email: email + password */}

			<PasswordForm {...person} />

			<AuthenticationProviders {...person} />
		</div>
		</PageProvider>
	);
}

function Details(person)
{
	return <section>
		<Wrap label='First name' required={true}>
			<Text {...person} field='firstName' />
		</Wrap>

		<Wrap label='Last name' required={true}>
			<Text {...person} field='lastName' />
		</Wrap>

		<Wrap label='Email' required={true}>
			<Text {...person} field='email' />
		</Wrap>
	</section>;
}

function PasswordForm(props:UserData)
{
	const [showPasswordForm,setShowPasswordForm] = createSignal(false);

	return <section>
		<h3>Set password</h3>

		<p>
			{ showPasswordForm() ?
				<a href='' class='passwordFormLink' onClick={() => setShowPasswordForm(false)}>Hide password form</a>
			:
				<a href='' class='passwordFormLink' onClick={() => setShowPasswordForm(true)}>Set password</a>
			}
		</p>

		<Show when={showPasswordForm()}>
			<PasswordFormContent {...props} setShowPasswordForm={setShowPasswordForm} done={passwordChanged} />
		</Show>
	</section>
}


function passwordChanged(props,ret)
{
	if ('error' in ret) 
		alert(ret.error);
	else {
		alert('Password updated');
		props.setShowPasswordForm(false);
	}
}


function AuthenticationProviders(props:UserData)
{
	const person = () => props.store;

	return <>
		<section class={connectStyle()}>
			<h3>Connect to authentication providers</h3>
			{/*
				If the email exists and is different ignore the Google email.
				If the email doesnt yet exist then use it.
			 */}
			<Show when={!person().googleConnected}>
				<GoogleAuthButton label='Connect to Google' action={() => {location.href='/user/google/connect';}} />
			</Show>

			<Show when={!person().facebookConnected}>
				<FacebookAuthButton label='Connect to Facebook' action={() => location.href='/user/facebook/connect'} />
			</Show>

			<Show when={person().googleConnected && person().facebookConnected}>
				<p>
					All authentication providers are already connected.
				</p>
			</Show>
		</section>


		<section class={connectStyle()}>
			<h3>Disconnect from authentication providers</h3>

			<Show when={person().googleConnected}>
				<GoogleAuthButton label='Disconnect from Google' action={() => props.page.config.disconnectProvider(props.page.server,'google')} />
			</Show>

			<Show when={person().facebookConnected}>
				<FacebookAuthButton label='Disconnect from Facebook' action={() => props.page.config.disconnectProvider(props.page.server,'facebook')} />
			</Show>

			<Show when={!person().googleConnected && !person().facebookConnected}>
				No providers to disconnect.
			</Show>
		</section>
	</>;
}

