import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { EventArtistProfileAgrement } from 'Shared/frontend/eventPage/EventArtistProfileAgreement';
import { usePage } from 'Shared/frontend/PageProvider';
import { createSignal, Match, Show, Switch } from 'solid-js';

export function Offer(props)
{
	const [show,setShow] = createSignal(false);

	return (<>
		<header-solid>OFFER BOOKING TO ARTIST</header-solid>

		<div class='statusAndSection shaded'>
			<div class='statusIcons'>
				<Switch>
					<Match when={props.artist.bookingStatus == 'draft'}>
						<ExclamationCircle />
					</Match>
					<Match when={true}>
						<TickCircle />
					</Match>
				</Switch>
			</div>
			
			<div class='sectionMargin'>
				<a class='toggleReveal linkColour' onClick={() => setShow(!show())}>
					1. Review agreement here
				</a>

				<Show when={show()}>
					<EventArtistProfileAgrement {...props} />
				</Show>

				<br />

				<div>2. Copy the the <i>booking offer</i> message below</div>

				<br />

{/* 
	TODO ben pl get copyToClipboard working to collect the specific artists performance agreement page 
<button type="button" onclick="page.widgets.get('copyToClipboard').copy('emailText')">1. Click here to copy the booking offer</button>
*/}

{/* XXX temp hack until copy button is working */}

				<div class='draftEmailBody'>
{/*
TODO benadd venueName and Artibelow
*/}

					The venue.name is pleased to offer {props.artist.name} a gig.
					To confirm your booking please 

{/*
TODO ben url below needs to start with venue's frontend domain, CJ hacked in townhall domain to work for Sally testing 
*/}

					<a href={`${usePage().site.hostUrl}/event/${props.event._id}/artist`} class='linkInline' target='_blank'>
						follow instructions on this link 
					</a> /event/{props.slug}/artist
				</div>
				<br />

				<div>
					3. Paste message into your email
					<br/>
					<br/>
					4. Send to Artist Contact
					<br/>
					<br/>
					5. Change booking status below from 'draft' to 'offered'
				</div>
			</div>
		</div>
	</>);
}
