import { css } from '@emotion/css';
import { fluidType } from 'Shared/frontend/FluidType';
import { sizes,palette } from 'Shared/frontend/ThemeProvider';
import { onCleanup, onMount } from 'solid-js';

const desktopContainerStyle = () => css({
	backgroundColor: palette().background
});

export const collapsedHeight = () => fluidType('px',sizes().flipToMobileMenu,sizes().desktopWidth,65,91.65);
export const expandedHeight = () => fluidType('px',sizes().flipToMobileMenu,sizes().desktopWidth,100,141);

const desktopStyle = (expand:boolean) => css({
	//XXX sticky sub navs are layed over this - bodgy: requires subnav to be at least as tall
	position: 'fixed',
	overflow: 'visible',
	top: 0,
	left: 0,
	width: '100%',
	zIndex: 10,
	display: 'flex',
	justifyContent: 'center',
	willChange: 'height',
	backgroundColor: palette().background,
	minHeight: collapsedHeight(),
	maxHeight: expand ? expandedHeight() : collapsedHeight(),
	/* Scavenge some space on narrow screens: */
	gap: fluidType('em',sizes().flipToMobileMenu/16,sizes().desktopWidth/16,0,2),
});

const collapsedStyle = () => css({
	height: collapsedHeight(),
	zIndex: -10,
	opacity: 1,
	position: 'absolute',
	top: 0,
	width: '100%',
label:'collapsed'	
});

const expandedStyle = () => css({
	zIndex: -10,
	opacity: 1,
	position: 'absolute',
	top: 0,
	width: '100%',
	height:	expandedHeight()
});

const underlayStyle = (expand:boolean) => css({
	height: expand ? expandedHeight() : collapsedHeight(),
	width:'100%'
});


interface IShrinkingNavBar {
	expand: boolean;
	setFullHeight: (height:number) => void;
};

export function ShrinkingNavBar(props: IShrinkingNavBar)
{
	let expandedComparator!:HTMLDivElement;
	let collapsedComparator!:HTMLDivElement; 
	let nav!:HTMLDivElement; 

	const handleScroll = (e:Event) => afterScroll(nav,props.setFullHeight,props.expand,expandedComparator,collapsedComparator);

	onMount(() => {
		window.addEventListener('resize',handleScroll);
		if (props.expand)
			window.addEventListener('scroll',handleScroll);
		afterScroll(nav,props.setFullHeight,props.expand,expandedComparator,collapsedComparator);

		onCleanup(() => {
			window.removeEventListener('resize',handleScroll);
			if (props.expand)
				window.removeEventListener('scroll',handleScroll);
		});
	});

	return (<>
		<div ref={collapsedComparator} class={collapsedStyle()} />
		<div ref={expandedComparator} class={expandedStyle()} />

		<header class={desktopContainerStyle()} >
			<nav ref={nav} class={desktopStyle(props.expand)} >
				{props.children}
			</nav>
		</header>
		<div class={underlayStyle(props.expand)} />
	</>);
}	

function afterScroll(nav:HTMLDivElement,setFullHeight:(height:number)=>void,expand:boolean,expandedComparator:HTMLDivElement,collapsedComparator:HTMLDivElement)
{
	const expandedHeight = expandedComparator.getBoundingClientRect().height;
	const collapsedHeight = collapsedComparator.getBoundingClientRect().height;
			
	let height = expandedHeight - window.scrollY;
	if (height < collapsedHeight)
		height = collapsedHeight;
	if (height > expandedHeight)
		height = expandedHeight;

	nav.style.height = `${height}px`;

	/* Signal to shrinkable logo */
	setFullHeight(expand ? expandedHeight : collapsedHeight);
}

