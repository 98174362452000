import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { ImageSelector } from 'Shared/backend/eventPage/ImageSelector'
import { AnnounceDate } from 'Shared/backend/eventPage/tasks/AnnounceDate';
import { AnnounceFacebook } from 'Shared/backend/eventPage/tasks/AnnounceFacebook';
import { AnnounceInstagram } from 'Shared/backend/eventPage/tasks/AnnounceInstagram';
import { FacebookVideos } from 'Shared/backend/eventPage/tasks/FacebookVideos';
import { PosterAndPublish } from 'Shared/backend/eventPage/tasks/PosterAndPublish';
import { WebsiteMusic } from 'Shared/backend/eventPage/tasks/WebsiteMusic';
import { Overlay } from 'Shared/forms/Overlay';
import { createSignal, Show, Switch,Match } from 'solid-js';

const paneStyle = () => css({
	border: 'none'
});

export function Plan(props)
{
	const event = () => props.store;
	const [overlay,setOverlay] = createSignal(false);

	return (
		<tab-pane class={paneStyle()}>
			<div id='plan'>
				<div>Complete tasks to promote event on your website and social media, and motivate performers to do the same!</div>
				<br/>

				<div class='planTable'>
			  		<PosterAndPublish {...props} />
			  		<AnnounceDate {...props} />
			  		<AnnounceFacebook {...props} />

					<x-pane>
			  			<AnnounceInstagram {...props} />
					</x-pane>

					<Show when={overlay()}>
						<Overlay classes='overlay fullscreen' close={() => setOverlay(false)}>
							<x-pane>
{/*
					{{ overlay.overlay(overlays,'overlays','announceInstagramOverlay',content,'overlay') }}
*/}
								<ImageSelector {...props} />
							</x-pane>
						</Overlay>
					</Show>
			 

					{/* Music Event Tasks to do between announce date and event  */}
					<Show when={event().eventType == 'music'}>

						<div class='statusAndSection task'>
							<div class='statusIcon'>
								<i class='fa fa-calendar'></i>
							</div>
							<div class='title'>
								<Switch>
									<Match when={event().announceDate}>
										Tasks to do between {' '}
										{DateTime.fromISO(event().announceDate).toFormat('ccc d/MM')} 
										{' & '}
										{DateTime.fromISO(event().times.startSingle).toFormat('ccc d/MM/yyyy')}
									</Match>
									<Match when={event().scheduleDate && event().status == 'scheduled'}>
										Tasks to do between {' '}
										{DateTime.fromISO(event().scheduleDate).toFormat('ccc d/MM')}
										{' & '}
										{DateTime.fromISO(event().times.startSingle).toFormat('ccc d/MM/yyyy')}
									</Match>
									<Match when={true}>
										Tasks to do between now and {' '}
										{DateTime.fromISO(event().times.startSingle).toFormat('ccc d/MM/yyyy')}
									</Match>
							</Switch>
							</div>
						</div>

			  			<FacebookVideos {...props} />

						{/* Task: FB Post anchor link to jump to music player on website event page    */}
			  			<WebsiteMusic {...props} />
					</Show>
				</div>
			</div>

			<Show when={overlay()}>
{/*
TODO replace classes 
*/}
				<Overlay classes='overlay fullscreen' close={() => setOverlay(false)}>
					<x-pane>
{/*
		{{ overlay.overlay(overlays,'overlays','imageSelectorOverlay',content,'overlay') }}
*/}
					  <ImageSelector {...props} />
					</x-pane>
				</Overlay>
			</Show>
		</tab-pane>
	);
}


export function musicExists(lineup)
{
	let ret = false;
	for (const a of lineup)
		ret = ret || a.spotify || a.appleMusic || a.bandcamp || a.soundcloud;
	return ret;
};

export function videoExists(lineup)
{
	let ret = false;
	for (const a of lineup)
		ret = ret || a.facebookVideoUrl || a.instagramVideoUrl || a.youtubeVideoUrl;
	return ret;
};

