import {DocumentQueries} from 'Common/config/PageConfigTypes'
import { z } from 'zod';
import {eventDoc as eventModel,musicEvent as musicEventModel} from 'Shared/model/Event';
import {home as homeModel} from 'Shared/model/Home';
import {venueDoc} from 'Shared/model/venue';
import {FadePageInWidget} from 'Browser/widgets/FadePageInWidget';
import { events, musicEvents,nonMusicEvents } from 'Shared/view/frontend/eventQueries';
import { specialsQueries } from 'Shared/view/frontend/specialsQueries';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import {TimesFunctions} from 'Common/TimesFunctions';
import { HomePage } from 'Shared/frontend/homePage/HomePage';


export const homeParams = z.object({}).strict();	
export type HomeParams = z.infer<typeof homeParams>;

export const homeData = frontendData.extend({
	venue: venueDoc.extend({
		vosLogo: z.string().optional()
	}),
	home: homeModel,
	musicEvents: z.array(musicEventModel),
	nonMusicEvents: z.array(eventModel),
	events: z.array(eventModel),
	socialEvents: z.array(eventModel),
//FIXME not an event	
	specials: z.array(eventModel)
}).strict();
export type HomeData = z.infer<typeof homeData>;


export class Home extends FrontendPageConfig<HomeData,HomeParams>
{
	static readonly pageName = 'frontend/home';
	name() { return Home.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	widgets(page:IPageWrapper)
	{
		return {
			[FadePageInWidget.key()]: new FadePageInWidget(),
		};
	}

	defaultSettings()
	
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} homePage`,
			template: HomePage
		};
	}
	

	documents(): DocumentQueries
	{
		const times = new TimesFunctions(this.venue.document.settings.general.timezone,this.venue.document.settings.general.testNow);

		return ({
//			...footerDocuments(),
			home: {
				type:'object',
				collection:'home'
			},

			specials: specialsQueries(times,'times'),
			nonMusicEvents: nonMusicEvents(times,'times'),
			
			musicEvents: musicEvents(times,'times'),
			
			events: events(times,'times'),
		});
	}
}

