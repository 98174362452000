import {DocumentQueries} from 'Common/config/PageConfigTypes'
import { z } from 'zod';
import {eventDoc as eventModel} from 'Shared/model/Event';
import {StickySubNavWidget} from 'Browser/widgets/StickySubNavWidget';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import {Title as EventTitle} from 'Shared/frontend/eventPage/StandardTitle';
import {headInsert as eventHeadInsert} from 'Shared/frontend/eventPage/StandardHeadInsert';
import { EventPage } from 'Shared/frontend/eventPage/EventPage';

export const eventParams = z.object({
	slug: z.string()
}).strict();	
export type EventParams = z.infer<typeof eventParams>;

export const eventData = frontendData.extend({
	event: eventModel
}).strict();
export type EventData = z.infer<typeof eventData>;


export class Event extends FrontendPageConfig<EventData,EventParams>
{
	static readonly pageName = 'frontend/event';
	name() { return Event.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	titleTemplate() { return 'App/frontend/eventPage/basicTitle.njk'; }

	headerTemplate() { return 'App/frontend/eventPage/basicHeader.njk'; }

	widgets()
	{
		return {
			[StickySubNavWidget.key()]: new StickySubNavWidget()
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			titleTemplate: EventTitle,
			headTemplate: eventHeadInsert,
			template: EventPage
		};
	}

	documents(params:EventParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			event: {
				type:'object',
				collection:'event',
				aggregate: () => [
					{$match: {slug:params.slug}}
				]
			}
		});
	}
}

