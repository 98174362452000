import { Match, Switch } from 'solid-js';
import { Poster } from 'Shared/frontend/Poster';
import { PhotoPoster } from 'Shared/frontend/eventPosters/PhotoPoster';
import { SuppliedPoster } from 'Shared/frontend/eventPosters/SuppliedPoster';


export function SpecialsPoster(props)
{
	const showDetails = () => {
		const overLimit = (props.tagline?.length ?? 0) > 57;
		return overLimit || props.moreInfo;
	}

	return (
		<Poster {...props} showDetails={showDetails()}>
			<Switch>
				<Match when={props.imageType == 'photoPoster'}>
					<PhotoPoster {...props} folder='Special-image' size='medium' />
				</Match>
				<Match when={props.imageType == 'suppliedPoster'}>
					<SuppliedPoster {...props} folder='Special-image' size='medium' />
				</Match>
			</Switch>
		</Poster>
	);
}

