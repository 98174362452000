
import { css } from '@emotion/css';
import { appleMusic, bandcamp, soundcloud, spotify } from 'Browser/InputTypes';
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm';
import { labelLinkStyle } from 'Shared/backend/eventPage/artist/ArtistDetails';
import { BandcampButton } from 'Shared/backend/icons/BandcampButton';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { ListenOnApple } from 'Shared/backend/icons/ListenOnApple';
import { ListenOnSpotify } from 'Shared/backend/icons/ListenOnSpotify';
import { MusicCircle } from 'Shared/backend/icons/MusicCircle';
import { SoundcloudButton } from 'Shared/backend/icons/SoundcloudButton';
import { Text, Wrap } from 'Shared/forms/Inputs';
import { Show } from 'solid-js';

export function MusicLinks(props:ISet)
{
	return (<>
		<h4> Music
			<x-notes>VOS displays links on venue website & drafts social media posts so fans can listen on their platform of choice</x-notes>
		</h4>
		<div class='statusAndSection'>
			<div class='statusIcons'>
				<MusicCircle fill={props.spotify || props.appleMusic || props.musicEmbedId ? IconColour.active : IconColour.inactive} />
			</div>
			<div class='section'>
				<div class='musicLinks'>
					<Spotify {...props} />
					<AppleMusic {...props} />
					<Bandcamp {...props} />
					<Soundcloud {...props} />
				</div>
			</div>
		</div>
	</>);
}

function Spotify(props:ISet)
{
	const label = () => props.spotify ?
		<a class={`${css(labelLinkStyle)} active`}>Spotify song link </a>
	:
		<a class={`${css(labelLinkStyle)} inactive`} href={`https://open.spotify.com/search/${props.name}/artists`} target='_blank'>
			Search Spotify for a song &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='spotify' processInput={spotify} placeholder='Paste song share link' />
		</Wrap>
			
		<Show when={props.spotify}>
			<a href={props.spotify} target='_blank'>
				<ListenOnSpotify />
			</a>
		</Show>
	</>);
}

function AppleMusic(props:ISet)
{
	const label = () => props.appleMusic ?
		<a class={`${css(labelLinkStyle)} active`}>Apple song link </a>
	:
		<a class={`${css(labelLinkStyle)} inactive`} href={`https://music.apple.com/us/search?term=${props.name}`} target='_blank'>
			Search Apple Music for a song &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='appleMusic' processInput={appleMusic} placeholder='Paste song share link' />
		</Wrap>

		<Show when={props.appleMusic}>
			<a href={props.appleMusic} target='_blank'>
				<ListenOnApple />
			</a>
		</Show>
	</>);
}

function Bandcamp(props:ISet)
{
	const label = () => props.bandcamp ?
		<a class={`${css(labelLinkStyle)} active`}>Bandcamp page</a>
	:
		<a class={`${css(labelLinkStyle)} inactive`} href={`https://bandcamp.com/search?q=${props.name}&item_type`} target='_blank'>
			Search Bandcamp &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='bandcamp' processInput={bandcamp} placeholder='Paste track link' />
		</Wrap>

		<Show when={props.bandcamp}>
			<a href={props.bandcamp} target='_blank'>
				<BandcampButton />
			</a>
		</Show>
	</>);
}

function Soundcloud(props:ISet)
{
	const label = () => props.soundcloud ?
		<a class={`${css(labelLinkStyle)} active`}>Soundcloud page</a>
	:
		<a class={`${css(labelLinkStyle)} inactive`} href={`https://soundcloud.com/search?q=${props.name}`} target='_blank'>
			Search Soundcloud &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='soundcloud' processInput={soundcloud} placeholder='Link to artist/track/album' />
		</Wrap>

		<Show when={props.soundcloud}>
			<a href={props.soundcloud} target='_blank'>
				<SoundcloudButton />
			</a>
		</Show>
	</>);
}
