
import { css } from '@emotion/css';
import { fluidType } from 'Shared/frontend/FluidType';
import {Links, Nav} from 'Shared/frontend/layouts/StandardDefaultLayout';
import { sizes,palette, theme } from 'Shared/frontend/ThemeProvider';
import {For} from 'solid-js';

/*
	XXX current a max-width is set across the the entire nav. A more robust approach is probably
	    to work up from the buttons. calc-size() and max-content could be used for this, but not super
		well supported yet.
*/

const buttonStyle = () => css({
	...theme().nav.desktop.button,

	fontSize: fluidType('px',sizes().endFontShrink, sizes().desktopWidth,16,25),
//	color: palette().text,
//	backgroundColor: palette().background,
	textTransform: 'uppercase',
	padding: '0 0',
//	padding: '0 0.6em',

	':hover': theme().nav.desktop.hover
});

const activeStyle = () => css(theme().nav.desktop.active);


interface INavWidgetContents {
	nav: Nav;
	links: Links;
	currentPage:string;
}

export function NavWidgetContents(props:INavWidgetContents)
{
	return (
		<For each={props.nav}>{ item =>
			<a href={props.links[item.link].target}>
				<button tabindex='-1'
					classList={{
						[buttonStyle()]: true,
						[activeStyle()]: item.link == props.currentPage
					}}
				>
					{item.label}
				</button>
			</a>
		}</For>
	);
}
