import {css} from '@emotion/css';
import {VosLogo} from 'Shared/backend/icons/VosLogo';

export const utilityPageStyle = () => css({
	maxWidth: 600,
	margin: '0 auto',
	display: 'flex',
	flexDirection: 'column',

	header: {
		svg: {
			width: 80,
			alignSelf: 'center'
		},

		'.tick': {
			color: 'green',
			fontSize: '7em'
		}
	}
});

export const bannerStyle = () => css({
	svg: {
		width: 80,
		alignSelf: 'center'
	}
});


export function Banner()
{
	return (
		<header class={bannerStyle()}>
			<VosLogo />
		</header>
	);
}


