import { For } from 'solid-js';
import {ManagerData} from 'Common/pages/artist/Manager';
import {outerPage} from 'Shared/artists/OuterPage';
import {theme} from 'Shared/artists/Theme';
import {forms} from 'Shared/artists/Forms';
import {injectGlobal} from '@emotion/css';
import { CreateMessage2 } from 'Common/Messages';


//TODO
// breadcrumbs ==> x-breadcrumbs + register it somehow

export function ManagerPage(props:ManagerData) 
{
	injectGlobal([theme,forms]);

	return (
		<div id='content' class={outerPage()}>
			<nav>
				<breadcrumbs/> 
				<div class='userDetails'>
					<a href='/user'>User details</a> 
				</div>
			</nav>		

			<main>
				<ul>
					<For each={props.artists}>
						{artist => <li><a href={`/profile/${artist._id}`}>{artist.name ?? '(Missing name)'}</a></li>}
					</For>
				</ul>	

				<br/>
				<br/>

				<div>
					<a class='createNew' href="" onClick={e => createArtist(props.page)}><button>Add artist</button></a>
				</div>
			</main>
		</div>
	);
}

async function createArtist(page)
{
//TODO remove any *2 messages, operations and inputs (inc date time, html) as possible	

	const ret = await page.server.sendOperation(new CreateMessage2(page.name(),'create',{name:'New artist'}));

	window.pageJs(`/profile/${ret.id}`);
}



