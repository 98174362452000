import { IField, id, updateField, updateStoreAndDb, valueFromStore,updateFieldWithValue } from 'Shared/forms/Inputs';
import {Show,createEffect,createSignal, onCleanup, onMount} from 'solid-js';
import { DateTime as LuxonDateTime } from 'luxon';
import flatpickr from 'flatpickr';
import * as DateTimes from 'Browser/DateTimes';

//XXX THIS LOOKS LIKE IT HAS A BETTER TIME SELECTOR + supports Solid:   
//       https://www.npmjs.com/package/date-time-picker-solid

//BUG: typing in the time or year by hand and then changing focus does not cause an onChange event and the change is lost. Haven't found a fix

interface IDateTime extends IField<string> {
	ref: HTMLDivElement;
	classes?:string;
	placeholder?:string;
	hideClear?:boolean;
	extraInputAttributes?:any;
}

//TODO remove some classes if possible

export function DateTime(props:IDateTime)
{
	const value = () => props.store ? valueFromStore(props as Required<IDateTime>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	let inputNode!: HTMLInputElement & {_flatpickr:flatpickr.Instance}
	let started = false;

	const changeValue = val => {
		if (!started) return;

		if (props.required && (val==null || val=='')) {
			updateFlatpickr(inputNode,value());
			return;
		}

		const iso = LuxonDateTime.fromFormat(val,'dd/MM/yyyy h:mma').toISO({includeOffset:false,suppressMilliseconds:true});
		updateFieldWithValue(iso,props,setError);
	};

	onMount(() => {
		flatpickr(inputNode,{allowInput:true, enableTime:true, dateFormat:'d/m/Y h:iK'});
		onCleanup(() => inputNode?._flatpickr?.destroy());
		updateFlatpickr(inputNode,value());
		started = true;
	});

	return (
		<div class={`bf-dateTime ${props.classes ?? ''}`}
			id={id(props)}
			ref={props.ref}
		>
			<input ref={inputNode} type='text' 
				class='bf-dateTime-input' 
				placeholder={props.placeholder}
				onChange={e => changeValue(e.target.value)}				
				{...(props.extraInputAttributes ?? {})}
			/>

			<button class='bf-dateTime-calendar' onClick={() => inputNode._flatpickr.toggle() }>
				<i class='fa fa-calendar'></i>
			</button>

			<Show when={!props.hideClear}>
				<button class='bf-dateTime-clear' onClick={() => clearSelection(inputNode,hiddenNode)}>
					<i class='fa fa-times'></i>
				</button>
			</Show>
		</div>
	);
}

interface IDate extends IField<string> {
	ref: HTMLDivElement;
	classes?:string;
	placeholder?:string;
	hideClear?:boolean;
	extraInputAttributes?:any;
}

export function Date(props:IDate)
{
	const value = () => props.store ? valueFromStore(props as Required<IDate>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	let inputNode!: HTMLInputElement & {_flatpickr:flatpickr.Instance}
	let started = false;

	const changeValue = val => {
		if (!started) return;

		if (props.required && (val==null || val=='')) {
			updateFlatpickr(inputNode,value());
			return;
		}

		const iso = LuxonDateTime.fromFormat(val,'dd/MM/yyyy').toISO({includeOffset:false,suppressMilliseconds:true});
		updateFieldWithValue(iso,props,setError);
	};

	onMount(() => {
		flatpickr(inputNode, {allowInput:true,dateFormat:'d/m/Y'});
		onCleanup(() => inputNode?._flatpickr?.destroy());
		updateFlatpickr(inputNode,value());
		started = true;
	});

	return (
		<div class={`bf-date ${props.classes ?? ''}`}
			id={id(props)}
			ref={props.ref}
		>
			<input ref={inputNode} type='text' class='bf-dateTime-input'
				placeholder={props.placeholder}
				onChange={e => changeValue(e.target.value)}				
				{...(props.extraInputAttributes ?? {})}
			/>

			<button class='bf-dateTime-calendar' onClick={() => inputNode._flatpickr.toggle()}>
				<i class='fa fa-calendar'></i>
			</button>

			<Show when={!props.hideClear}>
				<button class='bf-dateTime-clear'>
					<i class='fa fa-times'></i>
				</button>
			</Show>
		</div>
	);
}	

interface ITime extends IField<string> {
	ref: HTMLDivElement,
	classes?:string;
	placeholder?:string;
	hideClear?:boolean;
	extraInputAttributes?:any;
}

export function Time(props:ITime)
{
	const value = () => props.store ? valueFromStore(props as Required<ITime>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	let inputNode!: HTMLInputElement & {_flatpickr:flatpickr.Instance}
	let started = false;

	const changeValue = val => {
		if (!started) return;

		if (props.required && (val==null || val=='')) {
			updateFlatpickr(inputNode,value());
			return;
		}

		const iso = LuxonDateTime.fromFormat(val,'h:mma').toISO({includeOffset:false,suppressMilliseconds:true});
		updateFieldWithValue(iso,props,setError);
	};

	onMount(() => {
		flatpickr(inputNode,{allowInput:true,enableTime:true,noCalendar:true,dateFormat:'h:iK'});
		onCleanup(() => inputNode?._flatpickr?.destroy());
		updateFlatpickr(inputNode,value());
		started = true;
	});

	return (
		<div class={`bf-time ${props.classes ?? ''}`} 
			id={id(props)} 
			ref={props.ref}
		>
			<input ref={inputNode} type='text' class='bf-dateTime-input' 
				placeholder={props.placeholder}
				onChange={e => changeValue(e.target.value)}	
				{...(props.extraInputAttributes ?? {})}
			/>

			<Show when={!props.hideClear}>
				<button class='bf-dateTime-clear'>
					<i class='fa fa-times'></i>
				</button>
			</Show>
		</div>
	);
}

function updateFlatpickr(inputNode:HTMLInputElement,value:string)
{
	if (value==null || value=='')
		inputNode._flatpickr.clear();
	else
		inputNode._flatpickr.setDate(LuxonDateTime.fromISO(value).toJSDate());
}

function clearSelection(calendarNode:HTMLDivElement,valueNode:HTMLInputElement)
{
	const f = calendarNode._flatpickr;
	valueNode.value = '';
	f.clear();
}
