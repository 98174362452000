import { CopyArtistImageMessage, ReadArtistMessage, SearchArtistsMessage, UpdateMessage2 } from 'Common/Messages';
import { usePage } from 'Shared/frontend/PageProvider';
import { LineupSet } from 'Shared/model/lineup';
import { Event } from 'Shared/view/backend/Event';
import { sharedArtistFields} from 'Shared/model/Artist';
import Awesomplete from 'awesomplete';
import { produce } from 'solid-js/store';
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm';
import { onMount } from 'solid-js';
import { genres } from 'Shared/ArtistTypes';


export function Search(props:ISet) 
{
	const page = usePage();

	let awesomplete!:Awesomplete;
	let inputNode!:HTMLInputElement;
	const searchResults:string[] = [];

	const changeTerms = async () => loadArtistDetails(page,inputNode,props.store,props.setStore,awesomplete,props.currentAct);

	const onInput = () =>  changedArtistSearchTerm(page,awesomplete,inputNode.value,searchResults);

	onMount(() => {
		awesomplete = getAwesomplete(inputNode,searchResults);
	});

	return (
		<x-field class='artistSearch'>
			<label>Retrieve Artist Marketing Content</label>

			<x-notes>
				Type artist name below.
				No results? Get artist to register their 
				<a>Onto It Media Profile</a>
				{' '}
				<u>OR</u>
				{' '}
				enter artist info below. 
			</x-notes>

			<div class='artistSearch-inputRow'>
				<div class='artistSearch-inputWrap'>
					<input type='text' ref={inputNode} maxlength={255} autocomplete='off' onInput={onInput} />
				</div>
				<button onClick={changeTerms}><i class='fa fa-download'/>&nbsp;&nbsp;Copy in artist content</button>
			</div>
		</x-field>
	);
}


async function loadArtistDetails(page,inputNode:HTMLInputElement,store:LineupSet,setStore,awesomplete:Awesomplete,actNum:number)
{
//TODO detact searchId from the awesomplete object	
	const retrievedDetails = await page.server.sendOperation(new ReadArtistMessage(Event.pageName,awesomplete.searchId));
	const imageHash = retrievedDetails.image?.hash;
//TODO  USE Zod to confirm? 

	/* Need to preserve the old image hash in the DB for the moment so we can delete out the old image */
	delete retrievedDetails['image'];

	/* Copy over the fields with the same name as ours: */
	setStore('lineup',actNum,produce(act => {
		for (const field of Object.keys(sharedArtistFields))
			act[field] = retrievedDetails[field];
	}));

	/* Update the database: */
	page.server.sendOperationOptimistically(
		new UpdateMessage2(page.name(),'edit',store._id,['lineup',actNum],retrievedDetails)
	);

	(window as any)?.tinymce?.get(`tinymce-edit-["lineup",${actNum}]-biography`).setContent(retrievedDetails.biography ?? '');

	if (imageHash) {
		const ret = await page.server.sendOperation(new CopyArtistImageMessage(actNum,store._id,imageHash)); 

		setStore('lineup',actNum,produce(act => {
			act.image = ret;
		}));
	}

	inputNode.value = '';
}

async function changedArtistSearchTerm(page,awesomplete:Awesomplete,term:string,/*mod*/searchResults:string[])
{
	if (term.length<2) {
		awesomplete.list = [];
		awesomplete.evaluate();
		return;
	}

	const items = await page.server.sendOperation(
		new SearchArtistsMessage(Event.pageName,term.trim())
	);

	const list = [];
	awesomplete.length = 0;
	for (const e of items) {
		searchResults[e._id] = e;
		list.push(e._id);
	}

	if (items.length==0)
		list.push('none');

	awesomplete.list = list;
	awesomplete.evaluate();
}

function getAwesomplete(inputNode:HTMLInputElement,searchResults:string[])
{
	const awesomplete = new Awesomplete(inputNode,{
		sort:false,
//                replace: function (suggestion:Awesomplete.Suggestion) {
		replace: function (suggestion:any) {
			if (suggestion.value=='none')
				return;
			inputNode.value = searchResults[suggestion.value].name;
			awesomplete.searchId = suggestion.value;
		},
		filter: (text,input) => true,
//                item: (suggestion:Awesomplete.Suggestion,input) => {
		item: (suggestion:any,input) => {
			const li = document.createElement('LI');
			let text:string;
			if (suggestion.value=='none')
				text = '<i>No results found</i>';
			else {
				const entry = searchResults[suggestion.value];
				const name = entry.name;
				const pos = name.toLowerCase().indexOf(input.toLowerCase());
				text = escapeHtml(name.substring(0,pos))+'<mark>'
						+ escapeHtml(name.substr(pos,input.length))+'</mark>'
						+ escapeHtml(name.substr(pos+input.length));

				/* add the extra information: */
				let extra = '';
				if (entry.city!=null && entry.city!='')
					extra = escapeHtml(entry.city);
				if (entry.mainGenre!=null && entry.mainGenre!='') {
					if (extra!='')
						extra += ', ';
					extra += escapeHtml(genres[entry.mainGenre] ?? '');
				}
				if (extra!='')
					text += ' <i>('+extra+')</i>';
			}

			li.innerHTML = text;
			return li;
		},
	});

	inputNode.addEventListener('awesomplete-close',event => {
		if (event.reason!='select') 
			inputNode.value = '';
	});		

	return awesomplete;
}

function escapeHtml(unsafe:string):string
{
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
}
