/* 
	Included for previews 
	XXX 'if' statement is required artist GUI, which shouldnt be using this file I guess... TODO: separate
*/

import Assert from 'Common/Assert';
import {IPageData} from 'Common/PageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import {OpenSans} from 'Shared/backend/OpenSans';
import {lookupFont} from 'Shared/frontend/fonts/lookupFont';

export function HeadInsert(data: IPageData)
{
	const resourcesPrefix = (new VenueUrls(data.build,'ANY')).backendUrls().prefixes.resources;

	return ( 
		OpenSans(Assert.have(resourcesPrefix)) + 
		lookupFont(data.venue.settings.frontend.fonts)() 
	);
}
