import { Show } from 'solid-js';
import {DateTime} from 'luxon';
import { InfoCircle } from 'Shared/backend/icons/InfoCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { Html, SingleSelect, Text, Wrap } from 'Shared/forms/Inputs';
import { ClockCircle } from 'Shared/backend/icons/ClockCircle';
import { PersonCircle } from 'Shared/backend/icons/PersonCircle';
import { DocumentCircle } from 'Shared/backend/icons/DocumentCircle';
import { LineupSet } from 'Shared/model/lineup';
import { DollarCircle } from 'Shared/backend/icons/DollarCircle';
import { TruckCircle } from 'Shared/backend/icons/TruckCircle';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { Offer } from 'Shared/backend/eventPage/artist/Offer';

const htmlOptions = {
	height: 200,
	menubar: false,
	promotion: false,
	plugins: ['link'],
	statusbar: false,
	toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link '
};


export function Booking(props:LineupSet)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	return (<>
		<br/>
		<h4>
			<inline-content>
				Artist: &nbsp;&nbsp; {props.name ?? <x-warning>Add above</x-warning>}
			</inline-content>
		</h4>
		<br/>

		<inline-content>
			Performance Starts: &nbsp;&nbsp;
			<Show when={props.setTime} fallback={<x-warning>Add above</x-warning>}>
				{ DateTime.fromISO(props.setTime).toFormat('hh:mm a') }
			</Show>
		</inline-content>

		<br/>

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<InfoCircle fill={props.performanceDuration ? IconColour.active : (!props.useAgreement ? IconColour.inactive : IconColour.alert) } />
			</div>

			<Wrap notes='Eg number of sets and length, and other time requirements'
				label={'Performance duration' + (
					!props.performanceDuration && !props.useAgreement ? ' (Optional)' : ''
				)}
			>
				<Text {...props} field='performanceDuration' />
			</Wrap>
		</div>

		<FeeStructure {...props} />

		<Worksheet {...props} />

		<TermsAndConditions {...props} />

		<Offer {...props} artist={props} />
	</>);
}

function FeeStructure(props)
{
	return (<>
		<h4>Fee Structure</h4>
		<div class='statusAndSection'>
			<div class='statusIcons'>
				<DollarCircle fill={props.feeType ? IconColour.active : IconColour.alert} />
			</div>

			<div>
				<Wrap label='Fee Type'>
					<SingleSelect {...props} field='feeType' options={{
						guarantee: 'Venue Guarantee',
						ticketSales: 'Ticket Sales',
						door: 'Percentage of Door',
						other: 'Other (not listed)'
					}} />
				</Wrap>

				<Show when={props.feeType == 'other'}>
					<Wrap label='Other Fee terms'>
						<Text {...props} field='feeTypeOther' />
					</Wrap>
				</Show>
			</div>
		</div>

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<InfoCircle fill={props.feeDetails ? IconColour.active : IconColour.alert} />
			</div>

			<Wrap label='Details' instructionsAfterLabel='ie total $ if guarantee, % if split deal'>
				<Text {...props} field='feeDetails' />
			</Wrap>
		</div>
	</>);
}

function Worksheet(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	return (<>
		<h4>Worksheet</h4>
		<div class='statusAndSection'>
			<div class='statusIcons'>
				<ClockCircle fill={props.soundCheck ? IconColour.active : IconColour.alert} />
			</div>

			<Wrap label='Sound Check' notes='Eg write a time and tips, or "TBA" if To Be Announced or "NA" if not available'>
				<Text {...props} field='soundCheck' />
			</Wrap>
		</div>

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<TruckCircle fill={props.loadInOut_Custom ? IconColour.active : IconColour.inactive} />
			</div>

			<Wrap label='Load In/Out Instructions'>
				<Html {...props} field='loadInOut_Custom' urls={urls} options={htmlOptions} />
			</Wrap>
		</div>

		<i>To do add Tech Specs here</i>
		<br />

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<PersonCircle fill={props.venueEventContacts_Custom ? IconColour.active : IconColour.inactive} />
			</div>
			{/* TODO call venue templates into .ts file and inject as default data, not placeholder */}

			<Wrap {...props} label='Venue Contacts'>
				<Html {...props} field='venueEventContacts_Custom' urls={urls} options={htmlOptions} />
			</Wrap>
		</div>
	</>);
}

function TermsAndConditions(props)
{
	return (<>
		<h4>Terms and Conditions</h4>

		<div class='statusAndSection'>
			<div class='statusIcons'>
				<DocumentCircle fill={props.termsType ? IconColour.active : IconColour.inactive} />
			</div>
			<div class='sectionWrap'>
				<SingleSelect {...props} field='termsType' options={{
					standard:'Standard Terms', 
					custom:'Customise Terms'	 
				}} />
			</div>
		</div>

		<Show when={props.termsType == 'custom'}>
			<CustomTerms {...props} />
		</Show>
	</>);
}

function CustomTerms(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	return (<>
		<x-field>
			<label>Your Standard Terms have been pasted into relevant sections below. You can edit them if this booking has special changes.</label>
			<x-notes>Want to change Terms for all future agreements? <a>Edit your Agreement Template here</a>
			</x-notes>
		</x-field>
	 
		<Wrap label='Performance Terms'>
			<Html {...props} field='performanceTerms_Custom' urls={urls} options={htmlOptions} />
		</Wrap>

		<Wrap label='Worksheet Terms'>
			<Html {...props} field='worksheetTerms_Custom' urls={urls} options={{...htmlOptions,height:400}} />
		</Wrap>

		<Wrap label='Payment Terms'>
			<Html {...props} field='paymentTerms_Custom' urls={urls} options={htmlOptions} />
		</Wrap>

		<Wrap label='Other Terms'>
			<Html {...props} field='otherTerms_Custom' urls={urls} options={{...htmlOptions,height:600}} />
		</Wrap>
	</>);
}
