import { css } from '@emotion/css';
import { facebookPageUrl, instagramPageUrl, websiteUrl } from 'Browser/InputTypes';
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm';
import { labelLinkStyle } from 'Shared/backend/eventPage/artist/ArtistDetails';
import { FacebookCircle } from 'Shared/backend/icons/FacebookCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { InstagramCircle } from 'Shared/backend/icons/InstagramCircle';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { WebsiteCircle } from 'Shared/backend/icons/WebsiteCircle';
import { Text, Wrap } from 'Shared/forms/Inputs';

export function SocialSites(props:ISet)
{
	return (<>
		<h4>
			Sites &amp; Socials
			<x-notes>Important so VOS can find artist content</x-notes>
		</h4>

		<div class='statusAndSection'>
			<Facebook {...props} />
		</div>

		<div class='statusAndSection'>
			<Instagram {...props} />
		</div>

		<div class='statusAndSection'>
			<Website {...props} />
		</div>
	</>);
}


function Facebook(props:ISet)
{
	const label = () => props.facebook ?
		<a class={`${css(labelLinkStyle)} active`} href={`https://www.facebook.com/${props.facebook}`} target='_blank'>
			Facebook &nbsp; 
			<LinkOpen fill={IconColour.active} />
		</a>
	:
		<a class={`${css(labelLinkStyle)} inactive`} target='_blank'
			href={searchUrl(['facebook','page',props.name,...styleTerms(props),...locationTerms(props)])}
		>
			Search Facebook for artist &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<div class='statusIcons'>
			<FacebookCircle fill={props.facebook ? IconColour.active : IconColour.inactive} />
		</div>

		<Wrap label={label()}>
			<Text {...props} field='facebook' processInput={facebookPageUrl} placeholder='Paste web link or handle here'  />
		</Wrap>
	</>);
}


function Instagram(props:ISet)
{
	const label = () => props.instagram ?
		<a class={`${css(labelLinkStyle)} active`} href={`https://www.instagram.com/${props.instagram}`} target='_blank'>
			Instagram &nbsp; 
			<LinkOpen fill={IconColour.active} />
		</a>
	:
		<a class={`${css(labelLinkStyle)} inactive`} target='_blank'
			href={searchUrl(['instagram','profile',props.name,...styleTerms(props),...locationTerms(props)])}
		>
			Search Instagram for artist &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a> ;

	return (<>
		<div class='statusIcons'>
			<InstagramCircle fill={props.instagram ? IconColour.active : IconColour.inactive} />
		</div>

		<Wrap label={label()}>
			<Text {...props} field='instagram' processInput={instagramPageUrl} placeholder='Paste web link or handle here' />
		</Wrap>
	</>);
}


function Website(props:ISet)
{
	const label = () => props.website ?
		<a class={`${css(labelLinkStyle)} active`} href={props.website} target='_blank'>
			Website &nbsp; 
			<LinkOpen fill={IconColour.active} />
		</a>
	:
		<a class={`${css(labelLinkStyle)} inactive`} target='_blank'
			href={searchUrl([props.name,...styleTerms(props),'website',...locationTerms(props)])}
		>
			Search for artist website &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<div class='statusIcons'>
			<WebsiteCircle fill={props.website ? IconColour.active : IconColour.inactive} />
		</div>

		<Wrap label={label()}>
			<Text {...props} field='website' processInput={websiteUrl} placeholder='Paste website address here starting with http' />
		</Wrap>
	</>);
}

function locationTerms(props)
{
	const terms = [];
	if (props.city) terms.push(props.city);
	if (props.state) terms.push(props.state);
	if (props.country) terms.push(props.country);
	return terms;
}

function styleTerms(props)
{
	if (props.performanceType && props.performanceType.includes('comedy')) 
		return ['Comedian'];
	else if (props.performanceType && props.performanceType.includes('dj'))
		return ['DJ'];
	else
		return ['Music','Band'];
}

function searchUrl(terms:string[])
{
	let url = 'https://www.google.com/search?q=';
	let sep = '';

	for (const term of terms) 
		if (term) {
			url += sep + term;
			sep = '%20';
		}

	return url;
}

