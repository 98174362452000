import {Phone} from 'Shared/frontend/icons/Phone';
import {Pin} from 'Shared/frontend/icons/Pin';
import {SocialIcons} from 'Shared/frontend/footer/SocialIcons';
import {Show} from 'solid-js';
import { VenueDoc } from 'Shared/model/venue';
import { css } from '@emotion/css';
import { palette, sizes, theme } from 'Shared/frontend/ThemeProvider';


const footerStyle = () => css({
	borderTopStyle: 'solid',
	...theme().footer.divider,

	padding: '1em',
	maxWidth: sizes().maxFooterWidth,
	margin: '0 auto',
	width: '100%',

	[`@media (max-width:${sizes().flipToMobileMenu}px)`]: {
		paddingBottom: '7em'
	}
});

const venueNameStyle = () => css({
	marginTop: 0,
	...theme().footer.title
});

const innerStyle = () => css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'stretch'
});

const leftStyle = () => css({
	display: 'flex',
	gap: '1em',
	flexWrap: 'wrap',
	alignItems: 'center'
});

const phoneOrAddressStyle = () => css({
	color: palette().feature,
	textDecoration: 'none'
});

const phoneStyle = () => css(theme().phone);

const addressStyle = () => css({
	...theme().address,
	display: 'inline-block',
	verticalAlign: 'middle', //XXX required?
});

const logoStyle = () => css({
	display: 'block',
	padding: '0 0.5em',

	img: {
		width: 104,
	}
});

//XXX used in mobile nav too. Maybe separate a file for the common bits.
export const leadingIconStyle = () => css({
	display: 'inline-block',
	verticalAlign: 'middle',
	width: '1.9em',
	height: 'auto',
	marginRight: '0.35em',

	svg: {
		fill: palette().text
	}
});


interface IBaseFooter {
	venue: VenueDoc;
}

export function BaseFooter(props: IBaseFooter)
{
	return (
		<footer class={footerStyle()}>
			<h4 class={venueNameStyle()}>{props.venue.name}</h4>

			<div class={innerStyle()}>
				<div class={leftStyle()}>
					<a class={phoneOrAddressStyle()} href={`tel:${props.venue.phoneNumber}`}>
						<div class={leadingIconStyle()}>
							<Phone />
						</div>
						<span class={phoneStyle()}>
							{props.venue.phoneNumber}
						</span>
					</a>

					<a class={phoneOrAddressStyle()} href={props.venue.mapUrl} target='_blank'>
						<div class={leadingIconStyle()}>
							<Pin />
						</div>
						<address class={addressStyle()}>
							{props.venue.streetAddress}<br/>
							{props.venue.suburb+' '}
							{displayState(props.venue.state)+' '}
							{props.venue.postcode}
						</address>
					</a>

					<Show when={props.venue.postalAddress}>
						<p>Postal Address: {props.venue.postalAddress}</p>
					</Show>

					<SocialIcons venue={props.venue} />
				</div>

				<a class={logoStyle()} target='_blank' href='https://ontoitmedia.com/vos'>
					<img src={props.venue.vosLogo} alt='Website by VOS' />
				</a>
			</div>
		</footer>
	);
}

export function displayState(state:string)
{
	const states = {act:'ACT',nsw:'NSW',nt:'NT',qld:'Qld',sa:'SA',tas:'Tas',vic:'Vic',wa:'WA'};
	return states[state];
}

