import { baseSizes, baseTheme } from 'Shared/frontend/BaseTheme';
import { merge } from 'Shared/frontend/merge';
import { ITheme } from 'Shared/frontend/ThemeProvider';

const palette = {
	background: 'black',
	text: '#E2E2E2',
	feature: '#FCB03B',

	/* Eg the mobile nav colour */
	dimBackground: '#1C1513'
};

const fonts = {
	text: '"Kiwi Maru", serif',
	headings: '"Kiwi Maru", serif',
	buttons: '"Kiwi Maru", serif',
	desktopNav: '"Kiwi Maru", serif',
	mobileNav: '"Open Sans", sans-serif',
	subNav: '"Kiwi Maru", serif'
}

const layout = {
};


export function indieIslandTheme():ITheme 
{
	const base = baseTheme(baseSizes,palette,fonts,layout);

	const settings = merge(base, <Partial<ITheme>>{
		/* Add base theme overrides here */
	});

	// XXX if we want to entirely remove or overwrite a base setting or two then do so here 
	return settings;
};

