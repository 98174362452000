import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { css } from '@emotion/css';
import { ImageSchema,ImageSize } from 'Shared/model/basic';

const imageStyle = () => css({
	maxWidth: '100%',
	height: 'auto'
});


interface ISuppliedPoster {
	image: ImageSchema,
	folder: string,
	size: ImageSize
}

export function SuppliedPoster(props: ISuppliedPoster)
{
	const imageUrl = () => {
		if (!props.image?.hash) return '';
		return (new PageFunctions()).createImageUrl2(new VenueUrls(usePage().build,usePage().site.key),props.image.hash,props.image,props.folder,props.size);
	};

	return (
		<img class={imageStyle()} src={imageUrl()} />
	);
}

