import { css } from '@emotion/css';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { DateWrap, maxTextLines, nameStyle } from 'Shared/frontend/Poster';
import { FiveActs } from 'Shared/frontend/eventPosters/FiveActs';
import { FourActs } from 'Shared/frontend/eventPosters/FourActs';
import { OneAct } from 'Shared/frontend/eventPosters/OneAct';
import { ThreeActs } from 'Shared/frontend/eventPosters/ThreeActs';
import { TwoActs } from 'Shared/frontend/eventPosters/TwoActs';
import { usePage } from 'Shared/frontend/PageProvider';
import { palette } from 'Shared/frontend/ThemeProvider';
import { Match, Show, Switch } from 'solid-js';


const eventNameStyle = () => css(nameStyle(), {
	color: palette().feature,
	width: '100%',
	fontSize: '0.9em',
	minHeight: '1em',
	margin: '0.5em 0 0.5em 0',
	padding: '0 4px',
	...maxTextLines(2)
});

export const commonGenresStyle = () => css({
	textTransform: 'lowercase',
	...maxTextLines(1)
});

export function actImageUrl(image)
{
	if (!image?.hash)
		return '';

	return (new PageFunctions()).createImageUrl2(new VenueUrls(usePage().build,usePage().site.key),image.hash,image,'Lineup-image','medium');
}


export function MusicPoster(props)
{
	const numActs = () => props.lineup?.length ?? 0;

	return (
		<DateWrap {...props}>
			<div>
				<Show when={props.name}>
					<div class={eventNameStyle()}>{ props.name }</div>
				</Show>

				<Switch>
					<Match when={numActs() == 1}>
						<OneAct {...props} />
					</Match>
					<Match when={numActs() == 2}>
						<TwoActs {...props} />
					</Match>
					<Match when={numActs() == 3}>
						<ThreeActs {...props} />
					</Match>
					<Match when={numActs() == 4}>
						<FourActs {...props} />
					</Match>
					<Match when={true}>
						<FiveActs {...props} />
					</Match>
				</Switch>
			</div>
		</DateWrap>
	);
}

