import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { SingleSelect, Text, Wrap } from 'Shared/forms/Inputs'
import { Show } from 'solid-js';

export function PriceOptions(eventProps: IEventProps)
{
	const event = () => eventProps.store;

/*
	TODO ben can we set none/null/blank to be labelled as 'No Button'. CJ removed 'hide':'No button', from options below 
	be better to keep existing default of none with 'No Button' label 
*/

	return (<>
		<Wrap label='Entry details and button to display'> 
			<SingleSelect {...eventProps} field='priceOptions' 
				options={{
					'free':'FREE',  
					'buyNow':'BUY NOW', 
					'bookNow':'BOOK NOW', 
					'custom':'Custom label' 
				}}
			/>
		</Wrap>

		<Show when={event().priceOptions == 'custom'}>
			<Wrap label='Custom label on button (max 16 chars)'
				notes='Example: BUY $20, TIX $20, TIX $20/$15, $20 ($10 conc.), TIX $20+BF, FROM $120, $20 AT DOOR, FROM $50*, BOOK NOW'
			> 
				<Text {...eventProps} field='customPrice' />
			</Wrap>
		</Show>

		<Show when={event().priceOptions == 'buyNow' || event().priceOptions == 'bookNow' || event().priceOptions == 'custom'}>
			<Wrap label='Weblink to buy or book'> 
				<Text {...eventProps} field='purchaseLink' />
			</Wrap>
		</Show>
	</>);
}

