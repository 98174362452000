import {ImageSchema} from 'Shared/model/basic';
import {VenueDoc} from 'Shared/model/venue';

export function crawlerMetaData(url:string,venue:VenueDoc,title:string,image:ImageSchema,options:any)	//XXX any
{
	return (`
		<link rel=canonical href="${url}" />

		<meta property=og:type content=website />
		<meta property=og:url content="${url}" />
		<meta property=og:site_name content="${venue.nickname ?? venue.name}" />
		<meta property=og:title content="${title}" />

		${ image ? ogImage(image) : '' }

		${ googleInfo(venue,image,options.google) }
	`);
}


export function ogImage(image:any) 	//FIXME or use Image or ImageDetails or a new type? SEE: StandardHeadInsert. May be a bug... cf format vs formats
{
	let ret = `<meta property=og:image content="${image.url}" />`;

	if (image.format)
		ret += `<meta property=og:image:type content="${image.format}" />`;
	if (image.width)
		ret += `<meta property=og:image:width content="${image.width}" />`;
	if (image.height)
		ret += `<meta property=og:image:height content="${image.height}" />`;

	return ret;
}


export function googleInfo(venue:VenueDoc,image:ImageSchema|undefined,options:any={}) 		//XXX any
{
	 /* 
		Google recommends a width of 1920px wide (seriously?), with a min width 720px, and for
		"best" results a supplying images in 16x9, 4x3, and 1x1. Great.
	*/
	let props:any = { "@context": "https://schema.org", };

//		"@type": "Event",   XXX Whats a good default? Needs to be overridable if supplied...

	if (image)
		props = {...props, image: [ image?.url ]};


	props = {
		...props,

		location: {
			"@type": 'Place',
			name: venue.name,
			address: {
				"@type": 'PostalAddress',
				streetAddress: venue.streetAddress,
				addressLocality: venue.suburb,
				postalCode: venue.postcode,
				addressRegion: venue.state,
				addressCountry: venue.country
			}
		},

		...options
	};

	return '<script type=application/ld+json>'+JSON.stringify(props)+'</script>';
}

