import { DateTime } from 'luxon'
import { copyToClipboard } from 'Shared/backend/CopyToClipboard'
import { otherFacebookHandles } from 'Shared/backend/eventPage/Handles'
import { IconColour } from 'Shared/backend/IconColour'
import { VideoCircle } from 'Shared/backend/icons/VideoCircle'
import { Checkbox, Wrap } from 'Shared/forms/Inputs'
import { Overlay } from 'Shared/forms/Overlay'
import { FacebookCircle } from 'Shared/frontend/icons/FacebookCircle'
import { usePage } from 'Shared/frontend/PageProvider'
import { createMemo, createSignal, For, Match, Show, Switch } from 'solid-js'


export function FacebookVideos(props)
{
	const lineup = () => props.store.lineup ?? [];

	const fbVideoExists = createMemo(() => {
		let ret = false;
		for (const artist of lineup()) {
console.log('FacebookVideos memo  facebookVideoUrl:',artist.facebookVideoUrl);		
			ret = ret || artist.facebookVideoUrl!=null;
}
		return ret;
	});

	const [overlay,setOverlay] = createSignal(false);

	return (
		<div class='statusAndSection task'>
			<div class='statusIcon'>
				<VideoCircle />
			</div>

			<Switch>
				<Match when={!fbVideoExists()}>
					<div class='title'>No artist videos</div>
					<div class='content'>
						Perhaps ask them to add a Facebook Video to their artist profile at 
						<a href='https://profile.ontoitmedia.com/'>profile.ontoitmedia.com</a>{' '} 
						so you can copy the artists content into your videos.
						<br/>
					</div>
				</Match>
				<Match when={true}>
					<div class='title'>Post artist videos to Facebook</div>
					<div class='content'>
						<For each={lineup()}>{a => 
							<Show when={a.facebookVideoUrl}>
					
{/* TODO ben urgent get overlay working below to get data for each artist that has a.facebookVideoUrl 
might need parameters like ,'currentAct','setData',{{loop.index0}} */}

								<inline-content>
									<a href='#' onClick={() => setOverlay(true)}>
										- Post {a.name}'s video to Facebook
									</a>

{/* TODO ben urgent get the task_videoFb working below CJ can't get field input working in this for loop */}

									<Wrap label='Done' classes='checkboxBeforeLabel'>
										<Checkbox {...props} field='task_videoFb' />
									</Wrap>
								</inline-content>
								<br/>
							</Show>
						}</For>

{/* TODO Ben Urgent get the above runOnComponent overlay working with the below INCLUDE file, then remove the below FOR loop */}
						<For each={lineup()}>{artist => 
							<Show when={artist.facebookVideoUrl}>
								<VideosFacebook {...props} artist={artist} />

								<Show when={overlay()}>
									<Overlay>
										<x-pane>
											<VideosFacebook {...props} artist={artist} />
										</x-pane>
									</Overlay>
								</Show>
							</Show>
						}</For>
					</div>
				</Match>
			</Switch>
		</div>

	);
/*
		{{ overlay.overlay(overlays,'overlays','videosOverlay',content,'overlay fullscreen') }}
*/		
}

//TODO RENAME
function VideosFacebook(props)
{
	const event = () => props.store;
	const lineup = () => props.store.lineup ?? [];

	let postText!: HTMLDivElement;

	return (
		<div>
			<div class='overlayHeading'>
				<FacebookCircle fill={IconColour.black} />
				{props.artist.name} video draft post
			</div>

			<div class='postInstructions'>
				<div class='buttonAndTip'>

{/* TODO CJ get all instances of 'copyToClipboard' working on VOS. search that phrase to find and fix */}    

					<inline-content>
						<button onClick={() => copyToClipboard(postText)}>Copy Post</button>
				
						&nbsp; Then paste into Facebook, where you can edit the text before posting.
					</inline-content> 
				</div>
			</div>

			<div class='draftPost'>
				<div ref={postText}>
					Great video below from {props.artist.facebook ? '@'+props.artist.facebook : props.artist.name}.
					See live {DateTime.fromISO(event().times.startSingle).toFormat('ccc d LLL')+' '} 
					w/ { otherFacebookHandles(lineup(),props.artist.position) }
					at {props.venue.name}.
				</div>
				
				<div class='postContent'> 
					<p>
{/* XXX Facebook strips line breaks and whitespaces so i've hacked in &#xFEFF; as recommended
  on stack exchange <p>.</p> draw users attention to events link
  particularly because we don't want them clicking video link by mistake
*/}

				  
						{/* Below will collapse to 1 line of text on draft post */}
						&#128467;&#127926;
						INFO: {usePage().site.hostUrl}/event/{event().slug}
						&#127926;&#128467;
						{/* <p>&#xFEFF;</p>  */}
						{ props.artist.facebookVideoUrl }
					</p>
					
					<div class='postContent'> 
						{/* preview video */}
						<div class='videoPlayer'>
							<div class='videoEmbed'>
								<div>
									<iframe class='fbVideo' 
										src={`https://www.facebook.com/plugins/video.php?href=${props.artist.facebookVideoUrl}%2F&show_text=false&width=100&amp;height=60&amp;t=0`}
										style='border:none;overflow:hidden'
										allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
										allowfullscreen={true} 
									/>
								</div>
							</div>
						</div>
					</div>       
				</div>
			</div>
		</div>
	);
}

