import { css } from '@emotion/css';
import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { SingleSelect, Wrap } from 'Shared/forms/Inputs'
import { Match,  Switch } from 'solid-js'

const selectStyle = () => css({
	fontSize: '1.1em',
	background: '#1b2126',
	color: 'white',
});

const outerStyle = () => css({
	margin: '1em 1em 1em 0'
});

export function Type(eventProps:IEventProps)
{
	return (
		<Switch>
			<Match when={! eventProps.store.eventType}>
				<Wrap label='Start by choosing your event type...'> 
					<SingleSelect {...eventProps} field='eventType' options={{
							music:'Music Event',
							social:'Social Event',
							tv:'Televised Event'
						}} 
					/>
				</Wrap>
			</Match>
			<Match when={true}>
				<div class={outerStyle()}>
					<SingleSelect {...eventProps} field='eventType' class={selectStyle()} options={{
							music:'Music Event',
							social:'Social Event',
							tv:'Televised Event'
						}} 
					/>
				</div>
			</Match>
		</Switch>
	);
}
