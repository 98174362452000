import { IconColour } from 'Shared/backend/IconColour'

export function TruckCircle(props)
{
	return (
		<svg id='truck_circle' class='icon' style={`fill:${props.fill ?? IconColour.green}; stroke:${props.stroke ?? IconColour.green};`} 
			viewBox='0 0 64 64' enable-background='new 0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
		  <defs></defs>
		  <g transform="matrix(0.031427, 0, 0, -0.031427, -5.83231, -1916.09119)" fill="" style="transform-origin: 5.83231px 1916.09px;" alignment-baseline="initial" stroke="">
			<path d="M820 1914 c-341 -65 -598 -260 -736 -559 -63 -136 -77 -209 -78 -390 -1 -137 2 -172 22 -244 93 -340 352 -600 690 -693 112 -31 372 -31 484 0 338 93 597 354 690 693 19 71 23 108 23 239 0 131 -4 168 -23 239 -93 340 -351 599 -687 691 -60 17 -115 23 -220 25 -77 2 -151 1 -165 -1z m81 -377 c69 -29 114 -65 159 -126 l35 -49 103 -7 c120 -8 122 -10 122 -109 l0 -66 88 0 87 0 118 -118 117 -117 0 -161 c0 -111 -4 -164 -12 -172 -8 -8 -55 -12 -145 -12 l-133 0 -20 -40 c-56 -109 -206 -107 -265 3 l-20 37 -247 0 -246 0 -19 -37 c-54 -111 -212 -111 -266 0 -18 36 -20 37 -73 37 -50 0 -55 2 -65 27 -13 36 -8 698 6 712 6 6 56 14 112 17 l102 7 26 36 c74 108 172 160 305 160 56 0 89 -5 131 -22z" style=""></path>
			<path d="M695 1501 c-91 -24 -168 -92 -211 -185 -66 -145 10 -331 162 -396 180 -78 389 32 423 223 25 140 -40 271 -169 337 -43 22 -157 34 -205 21z m93 -83 c8 -8 12 -47 12 -110 l0 -98 38 -24 c48 -30 64 -50 56 -70 -9 -22 -23 -20 -69 10 -86 57 -85 56 -85 174 0 98 7 130 30 130 3 0 11 -5 18 -12z" style=""></path>
			<path d="M270 980 l0 -320 40 0 c30 0 40 4 40 16 0 27 64 81 109 94 31 8 51 8 76 0 36 -12 95 -71 95 -95 0 -13 38 -15 255 -15 l254 0 15 29 c18 33 71 81 92 81 12 0 14 42 14 265 l0 265 -67 0 -68 0 0 -98 c0 -86 -3 -103 -28 -154 -113 -231 -413 -281 -587 -98 -75 78 -94 128 -98 248 l-4 102 -69 0 -69 0 0 -320z" style=""></path>
			<path d="M1320 945 l0 -175 25 -6 c28 -7 85 -67 85 -89 0 -12 21 -15 120 -15 l120 0 0 133 0 132 -98 98 -97 97 -78 0 -77 0 0 -175z m108 27 l3 -72 43 0 c50 0 82 -19 73 -44 -6 -14 -19 -16 -84 -14 l-78 3 -3 89 c-3 101 1 119 25 114 15 -3 19 -16 21 -76z" style=""></path>
			<path d="M429 691 c-49 -50 -37 -121 27 -147 29 -12 39 -12 68 0 64 26 76 97 27 147 -40 39 -82 39 -122 0z" style=""></path>
			<path d="M1229 691 c-70 -70 -5 -181 87 -151 69 23 86 99 35 151 -40 39 -82 39 -122 0z" style=""></path>
		  </g>
		</svg>
	);
}		

