import {Profile, ProfileData} from 'Common/pages/artist/Profile';
import {Style} from 'Shared/artists/Style';
import {Location} from 'Shared/artists/Location';
import {PressShot} from 'Shared/artists/PressShot';
import {SocialSites} from 'Shared/artists/SocialSites';
import {Videos} from 'Shared/artists/Videos';
import {Biography} from 'Shared/artists/Biography';
import {MusicLinks} from 'Shared/artists/MusicLinks';
import {Text, Wrap} from 'Shared/forms/Inputs';
import {ArtistDoc} from 'Shared/model/Artist';
import {createStore} from 'solid-js/store';
import {mergeProps} from 'solid-js';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {outerPage} from 'Shared/artists/OuterPage';
import {steps} from 'Shared/artists/Steps';
import {theme} from 'Shared/artists/Theme';
import {forms} from 'Shared/artists/Forms';
import {injectGlobal} from '@emotion/css';
import {EditComponent} from 'Common/components/EditComponent';
import {Page} from 'Common/pages/Page';
import { Id } from 'Common/Id';
import { DeleteMessage2 } from 'Common/Messages';


export type ArtistProps = ArtistDoc & { 
	setStore: (...args: any[]) => void,
	editArtist: EditComponent<ProfileData,ArtistDoc>,
};

export function ProfilePage(profileData:ProfileData & {page:Page<ProfileData>})
{
	const [artistStore,setArtistStore] = createStore(profileData.artist);

//XXX or am I better passing through all props?
	const artist = mergeProps(artistStore,{
		page: profileData.page,
		docId: artistStore._id,
		permission: 'edit',
		location: [],
		store: artistStore,
		setStore: setArtistStore,
	});

	injectGlobal([theme,forms]);

//<ErrorBoundary fallback={err => <div>GOT ERROR: {err}</div>}>

	return (
		<PageProvider page={profileData.page}>
			<div id='content' class={outerPage()}>
				<nav>
					<x-breadcrumbs>
						<a href='/manager'>Top</a>
					</x-breadcrumbs>

					<div class='userDetails'>
						<a href='/user'>User details</a>  
					</div>
				</nav>		

				<main class={steps()}>
					<ArtistName 	{...artist}  />

					<Style			{...artist} />
					<Location		{...artist} />
					<SocialSites	{...artist} />  
					<PressShot		{...artist} />
					<Videos			{...artist} />
					<MusicLinks		{...artist} />
					<Biography		{...artist} />

					<br/>
					<br/>
					<br/>
					<a href='' class='delete' onClick={() => deleteProfile(profileData.page,artistStore._id,location)}>Delete artist profile</a>
				</main>
			</div>
		</PageProvider>		
	);
}

async function deleteProfile(page,docId:Id,location:Location)
{
	if (!confirm('Delete this artist profile?'))
		return;

	await page.server.sendOperation(new DeleteMessage2(Profile.pageName,'edit',docId));

//TODO need to use Urls() or links.ts to help standardise...
	window.pageJs('/manager');
}


function ArtistName(artist:ArtistProps)
{
//XXX BUG when the artist name is deleted the various search options should change

	return (
		<Wrap label='Artist name' required={true}>
			<Text {...artist} field='name' />
		</Wrap>
	);
}


