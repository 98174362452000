import { z } from 'zod';

//export const id = z.object({});  //TODO restrict further...
export const id = z.string();

/* Using strings so timezone can be added separately */
export const dateTime = z.string().refine(
	val => val.match(/^\d\d\d\d-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d$/)!=null,
	val => ({ message: `invalid datetime: ${val}` })
);	


export const date = z.string().refine(val => val.match(/^\d\d\d\d-[01]\d-[0-3]\d$/)!=null,{message:'invalid date'}); 
export const time = z.string().refine(val => val.match(/^[0-2]\d:[0-5]\d:[0-5]\d$/)!=null,{message:'invalid time'}); 

//TODO. Maybe a library - eg validator.js (bit large?), or FieldTypes might help
//XXX warning: in some places Im using a relative URL
export const url = z.string(); 
export type Url = z.infer<typeof url>;    

export const facebook = z.string().max(128).regex(/^[^\/\s&]+$/);
export const instagram = z.string().max(128).regex(/^[^\/\s\?#&]+$/);
export const twitter = z.string().max(128).regex(/^[^\/\s\?#&]+$/);
export const soundcloud = z.string().max(128).regex(/^[^\/\s\?#&]+$/);


export const imageDetails = z.object({
	format: z.enum(['jpeg','png']),
	width: z.number().min(0),
	height: z.number().min(0)
}).strict();

export type ImageDetails = z.infer<typeof imageDetails>;

export const imageFormats = z.object({
	full: imageDetails,
	large: imageDetails.optional(),
	medium: imageDetails.optional(),
	small: imageDetails.optional(),
	tiny: imageDetails.optional()
}).strict();
export type ImageFormats = z.infer<typeof imageFormats>;

export type ImageSize = keyof ImageFormats;

//XXX this is based on what is in the DB
export const image = z.object({
	creationTime: dateTime,
	modificationTime: dateTime,
	source: z.discriminatedUnion('type',[
		z.object({
			type: z.literal('upload'),
			personId: id
		})
		//TODO add in others
	]),
	dimensions: imageFormats,
	hash: z.string(),  //XXX could check better

	/* Used browser-side */
	dev: z.boolean().optional(),
	uploading: z.string().optional(),
	url: url.optional(),
	// Possibly just used in event.poster...
	isCurrent: z.boolean().optional()
}).strict();

export type ImageSchema = z.infer<typeof image>;


export const eventCalendarSettingsParent = z.object({
	allDay: z.boolean().optional(),
});


//TODO make all these calendar events strict... but currently there's cross-contamination between event types... 

export const eventCalendarOnceSettings = eventCalendarSettingsParent.extend({
	frequency: z.literal('once'),
	startSingle: dateTime,
	endSingle: dateTime.optional(),
});

export const eventCalendarRecurringSettings = eventCalendarSettingsParent.extend({
	startDate: date,
	endDate: date.optional(),
	startTime: time,
	endTime: time.optional(),
});

export const eventCalendarDailySettings = eventCalendarRecurringSettings.extend({
	frequency: z.literal('daily'),
});

export const eventCalendarWeeklySettings = eventCalendarRecurringSettings.extend({
	frequency: z.literal('weekly'),
	weekdays: z.array(z.string()),  //TODO
});

export const eventCalendarMonthlySettings = eventCalendarRecurringSettings.extend({
	frequency: z.literal('monthly'),
	week: z.string(),    //TODO
	weekday: z.string()  //TODO
});

export const eventCalendarSettings = z.discriminatedUnion('frequency',[
	eventCalendarOnceSettings,
	eventCalendarDailySettings,
	eventCalendarWeeklySettings,
	eventCalendarMonthlySettings
]);
export type EventCalendarSettings = z.infer<typeof eventCalendarSettings>;


export const posterSettings = z.any();
/*XXX WAS
		['fbPosterSettings',{type:['settings', {
			fontSize:		{type:['positiveInteger'],default:35},
			hideDetails:	{type:['array'],default:[]},
			bgColour:		{type:['colour'],default:'pink'},
			detailsColour:	{type:['colour'],default:'purple'},
			detailsBgColour:{type:['colour'],default:'#00F5'}, 
			artistColour:	{type:['colour'],default:'green'},
			artistBgColour:	{type:['colour'],default:'#0005'}
		} ],default:{}}],
*/		

