import { z } from 'zod';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {menuDoc} from 'Shared/model/Menu';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import {MenusPage} from 'Shared/backend/menu/MenusPage';


export const menuParams = z.object({}).strict();	
export type MenuParams = z.infer<typeof menuParams>;

export const menuData = backendData.extend({ 
	menu: menuDoc
}).strict();
export type MenuData = z.infer<typeof menuData>;


export class Menus extends BackendPageConfig<MenuData,MenuParams>
{
	static readonly pageName = 'backend/menus';
	name() { return Menus.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} menuPage`,
			template: MenusPage
		};
	}

	documents(params:MenuParams): DocumentQueries 
	{
		return ({
			...super.documents(params),
			menus: {
				type:'array',
				collection: 'menu'
			}
		});
	}
}

