import { DeleteMessage2 } from 'Common/Messages';
import { ArtistForm } from 'Shared/backend/eventPage/artist/ArtistForm';
import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { ImageSelector } from 'Shared/backend/eventPage/ImageSelector';
import { Lineup } from 'Shared/backend/eventPage/Lineup';
import { Name } from 'Shared/backend/eventPage/Name';
import { PreviewWrapTab } from 'Shared/backend/eventPage/PreviewWrapTab';
import { PriceOptions } from 'Shared/backend/eventPage/PriceOptions';
import { TaglineAndMoreInfo } from 'Shared/backend/eventPage/TaglineAndMoreInfo';
import { Type } from 'Shared/backend/eventPage/Type';
import { showTimesStatusIcon } from 'Shared/backend/eventPage/Utils';
import { IconColour } from 'Shared/backend/IconColour'
import { TickCircle } from 'Shared/backend/icons/TickCircle'
import { CalendarEntry } from 'Shared/forms/CalendarEntry';
import { createSignal, Show } from 'solid-js';
import { Id } from 'Common/Id';
import { css } from '@emotion/css';
import { DeleteButton } from 'Shared/forms/DeleteButton';
import { navHeight, paneWithStatusIconStyle } from 'Shared/backend/theme';
import { Event } from 'Shared/view/backend/Event';

const singleColumnWidth = 900;

const paneStyle = () => css({
	padding: '0 0',
	border: 'none'
});

const wrapStyle = () => css({
	position: 'relative',
});

const detailsStyle = () => css({
	display: 'inline-block',
	width: '50%',

	[`@media (max-width:${singleColumnWidth}px)`]: {
		width: '100%',
	},
});

const previewStyle = () => css({
	display: 'block',
	width: '50%',
	float: 'right',
	position: 'sticky',
	top: `calc(${navHeight} + 1em)`,

	[`@media (max-width:${singleColumnWidth}px)`]: {
		width: '100%',
		float: 'none'
	},
});


export function DetailsTab(props: IEventProps)
{
	const event = () => props.store;

	return (
		<tab-pane class={paneStyle()}>
			<div class={wrapStyle()}>
				<div class={detailsStyle()}>
					<Type {...props} />

					<Details {...props} />	

					<DeleteButton label='Delete event' delete={() => deleteItem(props.page,event()._id)} />
				</div>

				<div class={previewStyle()}>
					<PreviewWrapTab {...props} />
				</div>
				<div style='clear:both' />
			</div>
		</tab-pane>
	);
}

function Details(props)
{
	const [currentAct,setCurrentAct] = createSignal(undefined as number|undefined);
	const event = () => props.store;

	return (<>
		<h4>Date</h4>
		<div class={paneWithStatusIconStyle()}>
			<div>
				{showTimesStatusIcon(event().times)}
			</div>
			<CalendarEntry {...props} location={['times']} />
		</div>	
				
		<Show when={event().eventType == 'music'}>
			<h4>Lineup</h4>

			<Lineup {...props} lineup={event().lineup} currentAct={currentAct} setCurrentAct={setCurrentAct} />	

			<Show when={currentAct() != undefined}>
				<ActOverlay {...props} 
					event={props.store}
					{...event().lineup[currentAct()]} 
					location={['lineup',currentAct()]} 
					currentAct={currentAct()} 
					setCurrentAct={setCurrentAct} 
				/>
			</Show>
		</Show>

		<h4>Poster</h4>
		<div class={paneWithStatusIconStyle()}>
			<div>
				<TickCircle fill={event().imageType=='auto' || event().image?.hash ? IconColour.active : IconColour.inactive} />
			</div>
			<ImageSelector {...props} />
		</div>
		
		<h4>Event info</h4>

		<Name {...props} />
		<TaglineAndMoreInfo {...props} />
		<PriceOptions {...props} />
	</>);
}

function ActOverlay(props)
{
	return (
		<div class='backend'>
			<div class='lineup-edit'>
{/* TODO look at Overlay.ts now wrt these class names... */}								
				<div class='overlay' >
					<div class='overlayBackground'></div>
					<div class='overlayForeground'>
						<div class='overlayBody'>
							<ArtistForm {...props} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

async function deleteItem(page,docId:Id)
{
	if (!confirm('Delete this event?'))
		return;

	const msg = new DeleteMessage2(Event.pageName,'edit',docId);
    await page.server.sendOperation(msg);

	window.pageJs('/admin/events');
}

