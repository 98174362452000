import { injectGlobal } from '@emotion/css';
import { StandardDefaultLayout } from 'Shared/frontend/layouts/StandardDefaultLayout';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { standardRules } from 'Shared/frontend/Sanitise';
import { ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { Widget } from 'Shared/frontend/showcaseWidgets/Widgets';
import { For } from 'solid-js';
import { createStore } from 'solid-js/store';
import { HireData } from 'Shared/view/frontend/Hire';


export function HirePage(props:HireData)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	const [theme,setTheme] = createStore(props.theme);

	return (
		<ThemeProvider theme={theme}>
			<PageProvider page={props.page}>
				<StandardDefaultLayout {...props} expandDesktopNav={false}>
					<PageDesign {...props} />
				</StandardDefaultLayout>
			</PageProvider>
		</ThemeProvider>
	);
}

function PageDesign(props)
{
	/*
		Other possibilities could include: 
		eventGrid, grid, contactPage, menuPage, sections, widgetTree, eventGrid, grid
	*/

	//XXX cf alternatives... 'verticalList', 'verticalLayout', 'verticalWidgets'
	//Assert.check(props.design.type == 'widgetList');  //XXX Breaks Indie Island


	return (
		<For each={props.hire.showcase}>{(section,index) =>
			<Widget {...section} location={[index()]} index={index()} 
				showcase={props.hire.showcase}
				carouselImageFolder='Hire-showcaseCarouselImage' 
				carouselImageSize='large'
			/>
		}</For>
	);
}

