import { VenueUrls } from 'Common/VenueUrls';
import { DateTime } from 'luxon';
import { copyToClipboard } from 'Shared/backend/CopyToClipboard';
import { otherFacebookHandles } from 'Shared/backend/eventPage/Handles';
import { musicExists, videoExists } from 'Shared/backend/eventPage/Plan';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { Checkbox, Wrap } from 'Shared/forms/Inputs';
import { FacebookCircle } from 'Shared/frontend/icons/FacebookCircle';
import { usePage } from 'Shared/frontend/PageProvider';
import { Switch,Match, Show } from 'solid-js';

export function AnnounceFacebook(props)
{
	const event = () => props.store;
	const lineup = () => props.store.lineup ?? [];
	const firstAct = () => props.store.lineup?.[0];

	let postText!: HTMLDivElement;

	const posterUrl = () => !event().poster ? '' :
		(new VenueUrls(usePage().build,usePage().site.key)).imageUrl('Event-poster',event().poster.hash,'tiny','jpeg',!event().poster.dev);

	return (
		<div class='statusAndSection task'>
			<div class='statusIcon'>
				<Switch>
					<Match when={event().task_announceFb}>
						<TickCircle fill={IconColour.green} />
					</Match>
					<Match when={true}>
						<ExclamationCircle fill={IconColour.red} />
					</Match>
				</Switch>
			</div>

			{/*
				TODO ben urgent, get announceFacebookOverlay runOnComponent working to inlcude /backend/eventPage/draftPost/announceFacebook.njk
				then remove that file from being included below 

				{#<a href="#"  class='title' onclick="runOnComponent(event,'overlays','toggle','announceFacebookOverlay')"> #}
			*/}
			
			<div class='title'>
				Announce on Facebook

				<Wrap label='Done' classes='checkboxBeforeLabel'>
					<Checkbox {...props} field='task_announceFb' />
				</Wrap>
			</div>
			

			<div>
				<div class='overlayHeading'>
					<FacebookCircle fill='black' />

					<p>Draft Post</p>
				</div>

				<div class='postInstructions'>
					<div class='buttonAndTip'>
						{/* TODO CJ get all instances of 'copyToClipboard' working on VOS. search that phrase to find and fix */}    
						<inline-content>
							<button onClick={() => copyToClipboard(postText)}>Copy Post</button>
						
   							&nbsp; Then paste into Facebook, where you can edit the text before posting.
						</inline-content> 
					</div>
				</div>

				<div class='draftPost'>
					<div ref={postText}>
						{/* Customise the text that can be copied and pasted to social media */}

						Announcing 
						{ firstAct()?.facebook ? '@' + firstAct()?.facebook : firstAct()?.name }

						<Show when={lineup().length > 1}>	
							{' '}
							with 
							{ otherFacebookHandles(lineup(),0) }
							{DateTime.fromISO(event().times.startSingle).toFormat('ccc d LLL')} 
							{' '}
							@{props.venue.facebook}
						</Show>

						<p>
							Click Poster ↓ to discover
							{' '}

							<Switch>
								<Match when={musicExists(lineup()) && videoExists(lineup())}>
									music, videos and event details
								</Match>
								<Match when={musicExists(lineup()) && !videoExists(lineup())}>
									music and event details
								</Match>
								<Match when={!musicExists(lineup()) && videoExists(lineup())}>
									videos and event details
								</Match>
								<Match when={!musicExists(lineup()) && !videoExists(lineup())}>
									event details
								</Match>
							</Switch>
							{' '}
							{usePage().site.hostUrl}/event/{event().slug}
						</p>
					</div>
					
					<Show when={event().poster} fallback={<x-warning>Poster missing</x-warning>}>
						<div class='postContent'> 
							<img class='preview' src={posterUrl()}
								width={event().poster?.formats?.tiny?.width}
								height={event().poster?.formats?.tiny?.height}
							/>
						</div>
					</Show>
				</div>
			</div>
		</div>
	);
}

