
export function capitalise(s:string)
{
	if (!s)
		return s;

	return s.split(/\s+/).map(word => 
		(word?.[0] ?? '').toUpperCase() + 
		(word.substring(1)?.toLowerCase() ?? ''))
	.join(' ');
}

