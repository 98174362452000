import {FrontendData} from 'Common/FrontendPageConfig';
import {crawlerMetaData} from 'Shared/frontend/HeadMacros';
import {lookupFont} from 'Shared/frontend/fonts/lookupFont';

export function headInsert(data:FrontendData,title:string)
{
	return (
		lookupFont(data.venue.settings.frontend.fonts)() + 

		crawlerMetaData((<any>data).url,data.venue,title,data.venue.photo!,{}) + 		//XXX any
		`<style> 
			body > * {
				transition: opacity 0.3s;
			}

			.preload > * {
				opacity: 0;
			}

			.loaded > * {
				opacity: 1;
			}
		</style>`
	);
}
