
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm';
import { Biography } from 'Shared/backend/eventPage/artist/Biography';
import { LocationDetails } from 'Shared/backend/eventPage/artist/Location'
import { MusicLinks } from 'Shared/backend/eventPage/artist/MusicLinks';
import { PressShot } from 'Shared/backend/eventPage/artist/PressShot';
import { Search } from 'Shared/backend/eventPage/artist/Search'
import { SocialSites } from 'Shared/backend/eventPage/artist/SocialSites';
import { Style } from 'Shared/backend/eventPage/artist/Style';
import { Videos } from 'Shared/backend/eventPage/artist/Videos';
import { SingleSelect, Text, Wrap } from 'Shared/forms/Inputs'

export const labelLinkStyle = {
	display: 'inline-flex',
	alignItems: 'center'
};


export function ArtistDetails(props:ISet)
{
	return (<>
		<Search {...props} />

		<LocationDetails {...props} />
		<Style {...props} />
		<SocialSites {...props} />
		<PressShot {...props} />
		<Videos {...props} />
		<MusicLinks {...props} />
		<Biography {...props} />


{/*
TODO delete I guess
*/}
		<div class='hide'>
			<SingleSelect {...props} field='musicEmbedPlatform' placeholder='Select Platform' options={{
				bandcamp: 'Bandcamp',
				soundcloud: 'Soundcloud'
			}} />

			<Wrap label='Music embed ID'>
				<Text {...props} field='musicEmbedId' />
			</Wrap>
		</div>
	</>);
}

