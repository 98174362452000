
import { css, cx } from '@emotion/css';
import { sanitise } from 'Shared/frontend/Sanitise';
import { layout, palette } from 'Shared/frontend/ThemeProvider';
import {createSignal, onCleanup, onMount, Show} from 'solid-js';

//XXX cf splitting StickySubNav into Sticky + SubNav

const navStyle = (dontStick:boolean) => css({
	display: 'flex !important', 	//TODO delete !importants in time
	justifyContent: 'center',
	position: dontStick ? 'static' : 'sticky',
	top: 0,
	background: palette().background, 
	zIndex: 10,

	/* Cancel out the page margins to make the most of the page width: */
	marginLeft:  '-'+layout().leftMargin+' !important', 
	width: '100vw !important'
});

const innerStyle = () => css({
	position: 'relative',
	maxWidth: '100%',
});

const scrollStyle = () => css({
	overflow: 'scroll',
	padding: '0 1.5em',
	scrollbarWidth: 'none'
});

const itemsStyle = () => css({
	whiteSpace: 'nowrap',
	margin: '2em 0',
	userSelect: 'none',
});

const arrowStyle = () => css({
	...sanitise.button,
	position: 'absolute',
	top: 6,
	zIndex: 10,
	color: palette().text,
	backgroundColor: `${palette().background} !important`,  //XXX !important overriding Less TODO delete
	borderRadius: 0,
	fontSize: 16,
	padding: '2em 1.5em !important'  //XXX !important overriding Less TODO delete
});

const leftArrowStyle = () => css({
	left: 0
});

const rightArrowStyle = () => css({
	right: 0
});

/* Utility function that is useful for users */
export function jumpToAnchor(id:string)
{
	document.location = `#${id}`;
	/* -100 could be put in theme. Otherwise need to calculate from the nav above */
 	window.scrollBy(0,-100);
}


interface IStickySubNav {
	id: string;
	dontStick?: boolean;
}


//XXX for some pages could move the arrows into the page margin. 
//    Note menu page margins are narrow for small pages

export function StickySubNav(props:IStickySubNav) 
{
	const [showLeft,setShowLeft] = createSignal(true);
	const [showRight,setShowRight] = createSignal(true);

	let scrollPane!: HTMLDivElement;
	let leftArrow!: HTMLButtonElement;
	let rightArrow!: HTMLButtonElement;

	const handler = () => updateArrows(scrollPane,setShowLeft,setShowRight);

	onMount(() => {
		window.addEventListener('resize',handler);
		updateArrows(scrollPane,setShowLeft,setShowRight);

		onCleanup(() => window.removeEventListener('resize', handler));
	});

	return (
		<div id={props.id} class={cx('noReset',navStyle(props.dontStick ?? false))}>
			<div class={innerStyle()}>
				<Show when={showLeft()}>
					<button ref={leftArrow} class={cx(arrowStyle(),leftArrowStyle())} onClick={() => scrollLeft(scrollPane)}>
						&#10094;
					</button>
				</Show>

				<div ref={scrollPane} class={scrollStyle()} 
					onScroll={() => updateArrows(scrollPane,setShowLeft,setShowRight)}
				>
					<div class={itemsStyle()}>
						{props.children}
					</div>
				</div>

				<Show when={showRight()}>
					<button ref={rightArrow} class={cx(arrowStyle(),rightArrowStyle())} onClick={() => scrollRight(scrollPane)}>
						&#10095;
					</button>
				</Show>
			</div>
		</div>
	);
}

function updateArrows(scrollPane:HTMLElement,setLeftArrow,setRightArrow)
{
//XXX Note most browsers support the 'scrollend' event, which could be used instead 

	/* 
		Don't bother showing arrows if real close to the edge. Mobiles seem to struggle
		with exactly edge. cf having scroll 'click' into next tag.
	 */
	const cutoff = 3;

	setLeftArrow(scrollPane.scrollLeft > cutoff);
	setRightArrow(scrollPane.scrollLeft < scrollPane.scrollWidth - scrollPane.clientWidth - cutoff);
}

function scrollLeft(scrollPane:HTMLElement)
{
	/* Think the browser trims these... */
	/* The 0.85 ensures a bit of overlap when paging left or right */

	scrollPane.scrollBy({left: -scrollPane.clientWidth * 0.85, behavior: 'smooth'});
	/* The scroll event will be called by the event handler */
}

function scrollRight(scrollPane:HTMLElement)
{
	scrollPane.scrollBy({left: scrollPane.clientWidth * 0.85,behavior: 'smooth'});
}

