import {ArtistUrls} from 'Common/ArtistUrls';
import {Id} from 'Common/Id';
import {VenueUrls} from 'Common/VenueUrls';
import {Location} from 'Common/config/PageConfigTypes';
import {ImageSchema,ImageSize,Url} from 'Shared/model/basic';


//XXX maybe move elsewhere
/* The types provided by jsonpath-plus aren't great unfortunately, so create my own version: */
export type JSONPathItem = {
    path: string,
    value: any,
    parent: any,
    parentProperty: string,
    //hasArrExpr: undefined,  //Dont know what this is
    pointer: string
};

export type UpdateFieldValuesCallback = (ret:JSONPathItem)=>any;


//XXX PageFunctions kind-of assumes its being used on a ConfigDefinedPage, but its not formalised in the class
//    hierarchy. Rather components without configs are ignored.

export class PageFunctions
{
	/*
		IMPORTANT: 
		The data returned by Page.load() should not be overwritten or replaced as
		these functions may be run repeatedly without Page.load() being called again.
		These functions should instead add properties to the data. 
	 */

	updateLocationWithTabs(inBrowser:boolean,location:Url)
	{
		if (inBrowser) 
			window.history.replaceState(null,'',location);
	}				


	/* ----- Functions available for use in 'process' clauses in pages.json ---- */

	imageUploadUrl(urls:VenueUrls|ArtistUrls,docId:Id,pageName:string,component:string,imageObj:ImageSchema,folder:string,collection:string,location:Location,imageSize:ImageSize,defaultImage:Url) 
	{
		if (imageObj?.uploading)
			return;

		const uploadUrl = urls.uploadImageUrl(pageName,component,docId,location);
		const defaultImageUrl = urls.resourceUrl(defaultImage);

		const newValues:any = {table:collection,uploadUrl:uploadUrl,defaultImageUrl:defaultImageUrl};

//FIXME? the old 'collection/tables' are uppercase. Also table based, not embeddedable
		newValues.imageExists = imageObj?.hash != null;
		if (newValues.imageExists)
			newValues.previewUrl = this.createImageUrl(urls,imageObj,folder,imageSize);

		Object.assign(imageObj,newValues);
	}

	createImageUrl(urls:VenueUrls|ArtistUrls,fieldData:ImageSchema|undefined,assetFolder:string,imageSize:ImageSize)
	{
		if (fieldData?.formats==undefined || fieldData?.hash==undefined )
			return undefined;

		const size = fieldData.formats[imageSize];
        if (size==null) 
			return undefined;

		const useProduction = !(fieldData['dev']);

		return urls.imageUrl(assetFolder,fieldData.hash,imageSize,size.format,useProduction);
	}

	createImageUrl2(urls:VenueUrls|ArtistUrls,hash:string,fieldData:ImageSchema|undefined,assetFolder:string,imageSize:ImageSize)
	{
		if (fieldData?.formats==undefined || hash==undefined )
			return undefined;

		const size = fieldData.formats[imageSize];
        if (size==null) 
			return undefined;

		const useProduction = !(fieldData['dev']);

		return urls.imageUrl(assetFolder,hash,imageSize,size.format,useProduction);
	}
}

