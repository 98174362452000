import {locateSubDocument} from 'Common/ViewUtils';
import {EditComponent} from 'Common/components/EditComponent';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {DetailsTab} from 'Shared/backend/menu/DetailsTab';
import {calculateProductType} from 'Shared/backend/menu/MenuDesignerPage';
import {StatusBar} from 'Shared/backend/menu/StatusBar';
import {MenuDoc} from 'Shared/model/Menu';
import {MenuItem, MenuItemData} from 'Shared/view/backend/MenuItem';
import {MenuData} from 'Shared/view/backend/Menu';
import {Show, mergeProps} from 'solid-js';
import {SetStoreFunction, createStore} from 'solid-js/store';
import {menuSelector} from 'Shared/frontend/menuPage/MenuPage';
import { Id } from 'Common/Id';
import { injectGlobal } from '@emotion/css';
import { theme } from 'Shared/backend/theme';
import { ListMenu } from 'Shared/view/backend/ListMenu';
import { forms } from 'Shared/artists/Forms';


export interface IItemProps {
	docId: Id;
	store: MenuDoc;
	setStore: SetStoreFunction<MenuDoc>;
	location: Location;
	permission: string;
	component: EditComponent<MenuData,MenuDoc>;
	item: MenuItem;
}

export function MenuItemPage(props:MenuItemData)
{
	injectGlobal([theme,forms]);

	let [store,setStore] = createStore(props.menu);
	const location = createLocation(props.params);
	const itemProps = mergeProps({page:props.page, store:store,setStore:setStore,location:location,permission:'editMenu',
		docId:store._id,item:locateSubDocument(store,location) });

	const parentProductType = () => calculateProductType(store,location.slice(0,-2));
	const productType = () => calculateProductType(store,location);

	const link = () => `/`+store.slug+`#`+ menuSelector(store,location);

	return (<PageProvider page={props.page}>
		<BackendWrap>
			<breadcrumbs>
				<a href='/admin/menus'>Menus</a> {referringBreadcrumb(store,location,props.params.sectionIds)} &gt; {itemProps.item.name}
			</breadcrumbs>

			<div class='topBar'>
				<Show when={itemProps.item.status=='published'}>
					<a href={link()} class='linkInline' target='_blank'>
						<LinkOpen />
					</a>
				</Show>
			</div>

			<tab-group id='contentTabs'>
				<x-tab class='active'>Details</x-tab>
			</tab-group>

			<tab-pane-group>
				<Show when={true /*selected=='details'*/}>
					<DetailsTab itemProps={itemProps} link={link()} parentProductType={parentProductType()} productType={productType()} />
				</Show>
				<Show when={false /*selected=='marketing'*/}>
					{/* TODO include 'App/backend/menu/marketingTab.njk' */}
				</Show>
			</tab-pane-group>

			<StatusBar itemProps={itemProps} link={link()} />
		</BackendWrap>
	</PageProvider>);
}

function referringBreadcrumb(menu:MenuDoc,location:Location,sectionIds)
{
	const page = typeof window == 'undefined' ? null : window?.context?.state?.lastPageName;
	if (!page)
		return '';
	if (page == ListMenu.pageName)
		return <>&gt <a href={`/admin/listMenu/${menu.slug}`}>{menu.title} (single list)</a></>;

	let url = `/admin/menus/${menu.slug}`;
	for (const i of sectionIds) 
		url += '/' + i.name.replace(/\W/g,'').toLowerCase() +'-'+ i.index;

	const sectionTitle = locateSubDocument(menu,location.slice(0,-2)).title;

	return <>&gt <a href={url}>{sectionTitle}</a></>;
}


function createLocation(params:{sectionIds:{index:number,name:string}[],item:string})
{
	let loc:[string|number][] = [];
	for (const section of params.sectionIds)
		loc = [...loc,'sections',section.index];
	return [...loc,'items',params.item.index];
}

