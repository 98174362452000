
import { Show, Match, Switch } from 'solid-js';
import { CleanTime, formatDateTime, daysOfWeekly, weekday } from 'Shared/frontend/Macros';


export function DateTitle(props)
{
	return (
		<Switch>
			<Match when={props.frequency == 'once'}>
				<Show when={props.startSingle}>
					{formatDateTime(props.startSingle,'EEE, dd LLL')}
					<StartTime time={props.startSingle} />
				</Show>
			</Match>
			<Match when={props.frequency == 'daily'}>
				Daily
				<StartTime time={props.startTime} allDay={props.allDay} />
			</Match>
			<Match when={props.frequency == 'weekly'}>
				{daysOfWeekly(props.weekdays)}
				{' '}
				{props.days}
				<StartTime time={props.startTime} allDay={props.allDay} />
			</Match>
			<Match when={props.frequency == 'monthly'}>
				{props.week}
				{' '}
				{weekday(props.weekday)} of month
				<StartTime time={props.startTime} allDay={props.allDay} separator=', ' />
			</Match>
		</Switch>
	);
}


export function DateTitleWithEndTimes(props)
{
	return (
		<Switch>
			<Match when={props.frequency == 'once'}>
				<Show when={props.startSingle}>
					{ DateTime.fromISO(props.startSingle).toFormat('EEE, dd LLL') }
					<StartTime time={props.startSingle} />
				</Show>
				<Show when={props.endSingle} fallback=' until close'>
					{' '}
					until <CleanTime time={props.endSingle}/>
				</Show>
			</Match>
			<Match when={props.frequency == 'daily'}>
				Every Day
				<StartTime time={props.startTime} allDay={props.allDay} />
			</Match>
			<Match when={props.frequency == 'weekly'}>
				{daysOfWeekly(props.weekdays)}
				{' '}
				{props.days}
				<StartTime time={props.startTime} allDay={props.allDay} />

				<Show when={props.endTime} fallback=' until close'>
					{' '}
					until <CleanTime time={props.endTime}/>
				</Show>
			</Match>
			<Match when={props.frequency == 'monthly'}>
				{props.week}
				{' '}
				{weekday(props.weekday)} of month

				<StartTime time={props.startTime} allDay={props.allDay} separator=', ' />

				<Show when={props.endSingle} fallback=' until close'>
					{' '}
					until <CleanTime time={props.endSingle}/>
				</Show>
			</Match>
		</Switch>
	);
}

function StartTime(props)
{
	return (
		<Show when={props.time && !props.allDay}>
			{props.separator ??  ' '}
			<CleanTime time={props.time} />
		</Show>
	);
}
