import { css } from '@emotion/css';
import { actImageUrl, commonGenresStyle } from 'Shared/frontend/eventPosters/MusicPoster';
import { genreList } from 'Shared/frontend/Macros';
import { palette } from 'Shared/frontend/ThemeProvider';


const posterStyle = () => css({
	margin: '28px 6px 0 6px',
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	gap: '4px 6px'
});

const artistNameStyle = () => css({
	width: '100%',
	color: palette().feature,
	fontSize: '0.7em'
});

const artistStyle = () => css({
	minWidth: 0
});

const artistDetailsStyle = () => css({
	padding: 5
});

const pressShotStyle = () => css({
	maxWidth: '100%',
});

const genresStyle = () => css(commonGenresStyle(), {
	fontSize: '0.6em',
	lineHeight: '1.5em'  //XXX MOSTLY JUST SPACING. THERE ARE BETTER WAYS....
});


export function FourActs(props)
{
	return (
		<div class={posterStyle()}>
			<Artist {...props.lineup[0]} />
			<Artist {...props.lineup[1]} />
			<Artist {...props.lineup[2]} />
			<Artist {...props.lineup[3]} />
		</div>
	);
}

function Artist(props)
{
	return (
		<div class={artistStyle()}>
			<img class={pressShotStyle()} src={actImageUrl(props.image)} />

			<div class={artistDetailsStyle()}>
				<div class={artistNameStyle()}>{props.name}</div>
				<div class={genresStyle()}>{ genreList(props) }</div>
			</div> 
		</div>
	);
}

