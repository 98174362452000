import { css } from '@emotion/css';

const outerStyle = (shrinkDirection:'left'|'right',width:number) => css({
	flex: '0 0',
	height: '100%',
	display: 'flex',
	justifyContent: shrinkDirection=='left' ? 'flex-start' : 'flex-end',
	maxWidth: width,
	minWidth: width 
});

const imgStyle = () => css({
	objectFit: 'contain',
	maxWidth: '100%',
	maxHeight: '100%'
});


interface IShrinkingLogo {
	shrinkDirection: 'left'|'right';
	logo;
	fullHeight: number;
}

export function ShrinkingLogo(props:IShrinkingLogo)
{
	const width = () => {
		const img = props.logo.formats.full;
		const ratio = img.width / img.height;
		return props.fullHeight / ratio;
	}

	return (
		<div class={outerStyle(props.shrinkDirection,width())}>
			<img class={imgStyle()} src={props.logo.url} />
		</div>
	);
}

