import {ArtistUrls} from 'Common/ArtistUrls';
import {usePage} from 'Shared/frontend/PageProvider';
import {ArtistProps} from 'Shared/artists/ProfilePage';
import {DocumentCircle} from 'Shared/backend/icons/DocumentCircle';
import {IconColour} from 'Shared/backend/IconColour';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {Html, Text, Wrap} from 'Shared/forms/Inputs';
import {Show,createSignal} from 'solid-js';

export function Biography(artist:ArtistProps)
{
	const maxTaglineLength = 100;

	let taglineNode:HTMLInputElement;

	const urls = new ArtistUrls(usePage().build);

	const [taglineLength,setTaglineLength] = createSignal(artist.tagline?.length ?? 0);

	return <>
		<h4>Biography</h4>

		<div class='step biography'>
			<div class='stepIcon'>
				<DocumentCircle fill={artist.tagline || artist.biography ? IconColour.active : IconColour.inactive} />
			</div>
			<div>
				<Show when={artist.facebook || artist.website}>
					<label class={artist.tagline || artist.biography ? 'active' : 'inactive'} >
						Search for info on your&nbsp;

						<Show when={artist.facebook}>
							<a class='active' href={`https://www.facebook.com/${artist.facebook}`} target='_blank'>
								Facebook page
								<LinkOpen fill={IconColour.active} />
							</a>
						</Show>

						<Show when={artist.facebook && artist.website}>
							 or 
						</Show>

						<Show when={artist.website}>
							<a class='active' href={artist.website} target='_blank'>
								website 
								<LinkOpen fill={IconColour.active} />
							</a>
						</Show>
					</label>
				</Show>
		
				<x-field class='margin-bottom-wrap'>
					<Wrap label='Short description' instructionsAfterLabel='Imagine this is the only thing people will read about the artist'>
						<Text {...artist} field='tagline' ref={taglineNode!}
							onInput={value => {
								if (taglineNode.value!.length > maxTaglineLength)
									taglineNode.value = taglineNode.value.slice(0,maxTaglineLength)
								else
									setTaglineLength(value.length)
							} } 
						/>
					</Wrap>

					<x-notes>
						{maxTaglineLength - taglineLength()}/{maxTaglineLength} characters left
					</x-notes>
				</x-field>

				<Wrap label='More artist info' instructionsAfterLabel='Displayed after "Short Description" so do not repeat that'>
					<Html {...artist} field='biography' urls={urls} options={{
						height: 200,
						menubar: false,
						plugins: ['link'],
						toolbar: 'undo redo | link',
						statusbar: true,
						resize: true 
					}} />
				</Wrap>
			</div>
		</div>
	</>;
}

