import { z } from 'zod';
import {menuDoc as menuModel} from 'Shared/model/Menu';
import {special as specialModel} from 'Shared/model/Specials';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {specialsQueries} from 'Shared/view/frontend/specialsQueries';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData, frontendParams} from 'Common/FrontendPageConfig';
import {TimesFunctions} from 'Common/TimesFunctions';
import {MenuPage} from 'Shared/frontend/menuPage/MenuPage';


export const menuParams = frontendParams.extend({}).strict();	
export type MenuParams = z.infer<typeof menuParams>;

export const menuData = frontendData.extend({
	pageDisplayName: z.string(),
	menu: menuModel,
	specials: z.array(specialModel)
}).strict();
export type MenuData = z.infer<typeof menuData>;


export class Menu extends FrontendPageConfig<MenuData,MenuParams>
{
	static readonly pageName = 'frontend/menu';
	name() { return Menu.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	title() { return 'Menu'; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: MenuPage,
			pageDisplayName: 'Menu',
			htmlClasses: `${this.htmlClasses} menuPage`,
		};
	}

	documents(params:MenuParams)
	{
console.log('Menu documents()  params:',params);		

		const times = new TimesFunctions(this.venue.document.settings.general.timezone,this.venue.document.settings.general.testNow);

		return <DocumentQueries> ({
			...super.documents(params),

			menu: {
				type: 'object',
				collection: 'menu',
				aggregate: () => [
					{$match: {slug:params.slug}}
				]
			},

			specials: specialsQueries(times,'times'),
		});
	}
}

