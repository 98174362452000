import { css,keyframes } from '@emotion/css';
import { sizes, theme } from 'Shared/frontend/ThemeProvider';
import { Portal } from 'solid-js/web';

const overlayStyle = () => css({
	height: '100vh',
	width: '100vw',
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 10,
});

const backgroundStyle = () => css({
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.8)',
});

const slideIn = (contentWidth) => keyframes`
	from {left: calc( -100vw +  min((100vw - ${contentWidth}) / 2, 0px)) } 
	to {left: 0;}
`;

const fadeIn = () => keyframes`
	from {filter: opacity(0) } 
	to {filter: opacity(1)}
`;

const foregroundStyle = (animation:string|unknown) => css({
	position: 'absolute',
	width: '100%',
	top: 0,
	overflow: 'auto',
	maxHeight: '100vh',
	...(animation ? {animation: animation} : {}),

	[`@media (min-width:${sizes().flipToMobileMenu}px)`]: {
		marginTop: '2em' 
	},
});

const bodyStyle = () => css({
	margin: '0 auto',
	width: 'fit-content',
});

const closeButtonBarStyle = () => css({
	position: 'sticky',
	top: 0,
	right: 0,
	height: 0,
	opacity: 0.95,
	zIndex: 30,
});

const closeIconStyle = () => css({
	...theme().overlay.button,
	position: 'absolute',
	top: 0,
	right: 0,
});

/* Styling suitable for use over an image */
const closeIconOverImageStyle = () => css({
	textShadow: '-4px 4px 0px black',
	background: '#0000003d',
	borderRadius: '50%'
});


export function Overlay(props)
{
	/* Warning: only supports a single overlay. Otherwise cf an 'overlayed' count. cf <Portal> */

//TODO pass slideIn contentWidth in here, or better automate the calculation. cf making bodyStyle an absolute and setting right to 0 or something. Or just leave as 0.

	const animation = () => {
		if (props.animation == 'fadeIn')
			return `${fadeIn()} 1s`;
		if (props.animation == 'slideIn')
			return `${slideIn('0px')} 0.5s`;
		return undefined;
	};

	return (
		<Portal>
			<div class={overlayStyle()}>
				<div class={backgroundStyle()} />
				<div class={foregroundStyle(animation())}>
					<div class={bodyStyle()}>	
						<div class={closeButtonBarStyle()}>
							<button onClick={props.close}
								classList={{
									[closeIconStyle()]: true,
									[closeIconOverImageStyle()]: props.iconOverImage
								}} 
							>X</button>
						</div>
						{props.children}
					</div>
				</div>
			</div>
		</Portal>
	);
}

