import {IPageData} from 'Common/PageConfig';
import {ArtistNames} from 'Shared/frontend/eventPage/ArtistNames';
import {EventData} from 'Shared/view/frontend/Event';


//export function Title(data:EventData & IPageData)
export function Title(data:EventData)
{
	const venueName = data.venue.nickname ?? data.venue.name;
	let title;

	if (data.event.name!=undefined)
		title = data.event.name;
	else if (data.event.eventType=='music' && data.event.lineup?.length > 0)
		title = ArtistNames(data.event.lineup);
	else
		title = 'Events';

	return `${title} @ ${venueName}`;
}

