// TODO create a product search to insert products like we do with artist search

import {ImageSelector} from 'Shared/backend/menu/ImageSelector';
import {productTypes, sectionProductTypes, singleLineStyle} from 'Shared/backend/menu/MenuDesignerPage';
import {PreviewTab} from 'Shared/backend/menu/PreviewTab';
import {BeerDescription} from 'Shared/backend/menu/BeerDescription';
import {WineDescription} from 'Shared/backend/menu/WineDescription';
import {MultiSelect, Price, SingleSelect, Wrap, Text} from 'Shared/forms/Inputs';
import {Switch,Match} from 'solid-js';
import {StoreRepeater, addItemAndOpen} from 'Shared/forms/StoreRepeater';
import {Show} from 'solid-js';
import { mergeProps } from 'solid-js';
import { usePage } from 'Shared/frontend/PageProvider';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';
import { DeleteArrayItemMessage2 } from 'Common/Messages';
import { MenuItem } from 'Shared/view/backend/MenuItem';
import { Id } from 'Common/Id';
import { DeleteButton } from 'Shared/forms/DeleteButton';

//XXX cf injecting product type mini-templates

interface IDetailsTab {
	itemProps: IItemProps
	productType: keyof typeof productTypes
	parentProductType: keyof typeof sectionProductTypes
}

export function DetailsTab(props:IDetailsTab)
{
	let itemsNode!:HTMLElement;

	const tags = {
		GF: 'Gluten Free (GF)', 
		GFo: 'Gluten Free Option (GFO)', 
		VG: 'Vegan (VG)', 
		V: 'Vegetarian (V)', 
		DF: 'Dairy Free (DF)',
		DFO: 'Dairy Free Option (DFO)',
		'0%': 'Zero Alcohol',
		'☺': '☺'
	};

	const priceProps = (index:number) => mergeProps(props.itemProps,{location:[...props.itemProps.location,'prices',index]});
	const page = usePage();

	return (
		<tab-pane>
			<div class='fieldsAndPreviewWrap'>
				<div class='fieldsWrap'>
					<Switch>
						<Match when={props.parentProductType == 'mixed'}>
							<Wrap label='Product type' required={true}>
								<SingleSelect {...props.itemProps} field='productType' options={productTypes} required={true} />
							</Wrap>
						</Match>
						<Match when={true}>
							<Wrap classes={singleLineStyle()} label='Product type' disable={true}>
								<div>{productTypes[props.productType]}</div>
							</Wrap>
						</Match>
					</Switch>

					<header-regular>Description</header-regular>			           
					<Switch>
						<Match when={props.productType=='beers' || props.productType=='wines'}>
							<div class='brandAndName'> 
								<Wrap label='Brand Name'>
									<Text {...props.itemProps} field='brandName' />
								</Wrap>
								<Wrap label='Product Name' required={true}>
									<Text {...props.itemProps} field='name' />
								</Wrap>
							</div>
						</Match>
						<Match when={true}>
							<Wrap label='Product Name'>
								<Text {...props.itemProps} field='name' />
							</Wrap>
						</Match>
					</Switch>

					<Wrap label='Tags'>
						<MultiSelect {...props.itemProps} field='tags' options={tags} />
					</Wrap>

					<Switch>
						<Match when={props.productType=='beers'}>
							<BeerDescription itemProps={props.itemProps} />
						</Match>
						<Match when={props.productType=='wines'}>
							<WineDescription itemProps={props.itemProps} />
						</Match>
						<Match when={true}>
							<Wrap label='Short description'>
								<Text {...props.itemProps} field='tagline' />
							</Wrap>
						</Match>
					</Switch>

					<header-regular>Prices and add ons</header-regular>

					<x-field>
{/* what is this?					
						{% set selection = [prices.current[2]] if prices.current.length >= 3 else [] %}
*/}						
						<repeater-pane>
							<StoreRepeater ref={itemsNode} {...props.itemProps} field='prices'
								renderTitle={i => <>{i.size} {i.description} {i.price!=undefined ? '$' : ''}{i.price}</> }
							>
								{(item,index) => <UnitPrice unitPriceProps={priceProps(index)} productType={props.productType} />}
							</StoreRepeater>


			                <button onClick={() => addItemAndOpen({...props.itemProps,permission:'editMenu',field:'prices'},itemsNode,{})}>
								<i class='fas fa-plus'/> Add unit price
							</button>
						</repeater-pane>
					</x-field>

					<Show when={page.data.venue.settings.pages.menu.itemType!='imageless'}>
						<ImageSelector itemProps={props.itemProps} />
					</Show>

					<DeleteButton label='Delete item' delete={() => deleteItem(page,props.itemProps.docId,props.itemProps.location)} />
				</div>

				<div class='previewWrap'>
					<PreviewTab itemProps={props.itemProps} status={props.status} link={props.link} />
				</div>
			</div>
		</tab-pane>
	);
}

interface IUnitPrice {
	unitPriceProps;
	productType;
}

function UnitPrice(props:IUnitPrice)
{

//TODO test wines. Shouldnt be an add on should it?
//			{unitOptions: {glass:'Glass',bottle:'Bottle'} } :
	const beerUnits = {can:'Can',bottle:'Bottle',stubby:'Stubby',midi:'Midi',schooner:'Schooner',pint:'Pint',jug:'Jug'};

	return (
		<Switch>
			<Match when={props.productType == 'beers'}>
				<Wrap label='Size' required={true}>
					<SingleSelect {...props.unitPriceProps} field='size' options={beerUnits} />
				</Wrap>
				 
				<Wrap label='Price' required={true}>
					<Price {...props.unitPriceProps} field='price' />
				</Wrap>
			</Match>
			<Match when={props.productType == 'wines'}>
				TODO wines 
			</Match>
			<Match when={true}>
				<Wrap label='Price' required={true}>
					<Price {...props.unitPriceProps} field='price' />
				</Wrap>

				<Wrap label='Description (optional, but useful for add ons)'>
					<Text {...props.unitPriceProps} field='description' />
				</Wrap>
			</Match>
		</Switch>
	);
}

async function deleteItem(page,docId:Id,location:Location)
{
	if (!confirm('Delete this menu item?'))
		return;

//XXX if no items left cf deleting out the array entirely	
	const msg = new DeleteArrayItemMessage2(MenuItem.pageName,'editMenu',docId,location.slice(0,-1),location[location.length-1]);
	await page.server.sendOperation(msg)

//TODO need to use Urls() or links.ts to help standardise...
	window.pageJs('/admin/menus/'+page.params.slug+'/'+page.params.tail.split('/').slice(0,-1).join('/'));
}

