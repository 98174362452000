import { css } from '@emotion/css';
import { maxTextLines } from 'Shared/frontend/Poster';
import { actImageUrl, commonGenresStyle } from 'Shared/frontend/eventPosters/MusicPoster';
import { genreList } from 'Shared/frontend/Macros';
import { palette } from 'Shared/frontend/ThemeProvider';
import { Show } from 'solid-js';


const posterStyle = () => css({ });

const artistNameStyle = () => css({
	width: '100%',
	color: palette().feature,
	fontSize: '1em'
});

const pressShotStyle = (titleExists:boolean) => css({
	display: 'block',
	maxWidth: '100%',
	height: 'auto',
	marginTop: titleExists ? 0 : 29
});

const detailsStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	padding: '10px 5px 5px 5px'
});

const genresStyle = () => css(commonGenresStyle(), {
	fontSize: '0.7em',
	paddingTop: 6,		//XXX prefer not to space this way...
	lineHeight: '1.5em'  //XXX MOSTLY JUST SPACING. THERE ARE BETTER WAYS....
});

const taglineStyle = () => css({
	paddingTop: '0.5em',
	fontSize: '0.7em',
	fontStyle: 'italic',
	lineHeight: '1.5em',
	...maxTextLines(2)
});


export function OneAct(props)
{
	const artist = () => props.lineup[0];

	return (
		<div class={posterStyle()}>
			<img class={pressShotStyle(props.name!=null)} src={actImageUrl(artist().image)} />

			<div class={detailsStyle()}>
				<div class={artistNameStyle()}>{artist().name}</div>

				<Show when={artist().mainGenre}>
					<div class={genresStyle()}>{ genreList(artist()) }</div>
				</Show>

				<Show when={artist().tagline}>
					<div class={taglineStyle()}>{artist().tagline}</div>
				</Show>
			</div>
		</div>
	);
}

