import { fluidType } from 'Shared/frontend/FluidType';
import { sanitise } from 'Shared/frontend/Sanitise';
import { ITheme } from 'Shared/frontend/ThemeProvider';

export const baseSizes = {
	/* Events use a different flip point because they are so busy */
	flipToOneColumn: 700,	

	flipToMobileMenu: 600,  // TODO ==> 'flipToMobileNav'

	//TODO combine these two
	desktopWidth: 1000,
	maxPageWidth: 1250,

	maxFooterWidth: 1380,

	/* Width of an iPhone5 is 320px, iPhone6 is 375px */
	endFontShrink: 375,

	navButtonsMaxWidth: 800
};


export function baseTheme(sizes,palette,fonts,layout): ITheme
{
	const heading = {
		fontFamily: fonts.heading,
		fontWeight: 500,
		lineHeight: 1.3,
		textTransform: 'uppercase',
		fontSize: fluidType('px',sizes.endFontShrink,sizes.flipToMobileMenu,37.5,48),
		margin: '15px 0',
		color: 'inherit'
	};

//TODO create a parent for button too...	


	return <ITheme>{
		sizes: baseSizes, 	//TODO rename to 'mediaPoints', 'turningPoints', 'pageWidths', 'widths' or something

		palette: palette,

		fonts: fonts,

		layout: layout,

		/* The body settings are inherited through the page */
		body: {
			...sanitise.body,
			backgroundColor: palette.background,
			color: palette.text,
			fontFamily: fonts.text,
			fontSize: '1em',
			lineHeight: 1.5,
		},

		footer: {
			divider: {
				borderColor: 'inherit'
			},
			title: {
				fontFamily: fonts.heading,
				fontWeight: 700,
				textTransform: 'uppercase',
				margin: '8px 0',
			},
		},

		poster: {
			backgroundColor: palette.dimBackground,

			dateBar: {
				backgroundColor: palette.feature,
			},

			photoPoster: {
				inlineName: {
					color: 'inherit'
				}
			},

			detailsButton: {
				textColor: palette.text,
				backgroundColor: palette.background
			}
		},

		nav: {
			desktop: {
				button: {
					...sanitise.button,
					fontFamily: fonts.desktopNav,
					backgroundColor: 'inherit',
					color: palette.text,
					padding: '0.44em 2.25em',
					margin: '0 0.4em',
					textTransform: 'uppercase'
				},
				active: {
					color: palette.feature
				},
				hover: {
					color: palette.feature
				}
			},
			mobile: {
				button: {
					...sanitise.button,
					fontFamily: fonts.mobileNav,
					backgroundColor: palette.dimBackground,
					color: palette.text
				},
				active: {
					color: palette.feature
				},
				/* hover is bit academic on mobile: */
				hover: {
					color: palette.feature
				}
			}
		},

		widgets: {    //XXX cf ==> layout/design/pageDesign/showcase or promote slideshow 
			slideshow: {
				teaser: {
				}
			},
		},

		events: {
			heading: {
				...heading,
				fontSize: fluidType('px',sizes.endFontShrink,sizes.desktopWidth,46,64),
				textAlign: 'center',
				margin: '32px 0 24px 0'
			}
		},

		event: {
			heading: {
				...heading,
				color: palette.text 	//XXX do we want this??
			},
			artist: {
				heading: {
					...heading,
					fontSize: fluidType('px',sizes.endFontShrink,sizes.desktopWidth,27,46)
				},
				wide: {
					nameAndTime: {}
				},
				narrow: {
					nameAndTime: {
						backgroundColor: palette.text
					}
				}, 
				genresAndLocation: {} // for overriding
			}
		},

		a: {
			...sanitise.a,
			color: palette.feature
		},

		button: {
			...sanitise.button,
			fontSize: '1em',
			fontFamily: fonts.buttons,
			color: palette.text,
			borderWidth: 1,
			padding: '0.44em 2.25em',
			textTransform: 'uppercase'
		},

//XXX think this is the event page style really. Rename?		
		subNavButton: {
			...sanitise.button,
			fontFamily: fonts.subNav,
			fontSize: '0.8em',
			textTransform: 'capitalize',
			margin: '0 0.4em',
			padding: '0.5em',
			backgroundColor: palette.feature,
			color: palette.background,
		},

		overlay: {
			button: {
				...sanitise.button,
				fontFamily: fonts.subNav,
//				margin: '0 0.4em',
//				padding: '0.5em',

				fontSize: '3em',
				fontWeight: 700,
				borderRadius: '50%',
				width: '1.3em',
				height: '1.3em',
				padding: 0,
				color: palette.text,
				backgroundColor: palette.background,
			}
		},

		heading: heading,

		blockquote:  {
			...sanitise.blockquote,
			fontStyle: 'italic',
			fontWeight: 300,
			lineHeight: 1.3,
			margin: '1em 0'
		},

		iframe: {
			...sanitise.iframe
		},

		phone: {
			color:  palette.feature,
			lineHeight: 'normal'
		},

		address: {
			...sanitise.address,
			color:  palette.feature,
			lineHeight: 'normal'
		},

//FIXME use in events too...
		embeddedHtml: {
			h4: {	
				//XXX maybe inherit from subHeader instead? Note that embeddedHtml h4 probably will differ from subheader a bit...
				...heading,	
				color: palette.feature,
				fontWeight: 700,
				margin: '2em 0 1em 0' 
			},

			strong: {
				fontWeight: 700
			},

			a: {
				...sanitise.a,
				color: palette.feature
			},

			p: {
				margin: '16px 0',
				lineHeight: 1.5
			}
		},
	};
}
