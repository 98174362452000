import {ISvgColours, IconColour} from 'Shared/backend/IconColour'

export function Home(props:ISvgColours)
{
	return (
		<svg id='home' class='icon' style={`fill:${props.fill ?? IconColour.textColour}; stroke:${props.stroke ?? 'inherit'};`} viewBox='0 0 64 64' enable-background='new 0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
		  <defs></defs>
		  <title>menu</title>
		  <path d='M 60.993 29.313 L 51.9 20.22 L 51.9 9.716 C 51.9 7.816 45.022 7.816 45.022 9.716 L 45.022 13.342 L 38.251 6.571 C 34.903 3.225 29.083 3.231 25.743 6.577 L 3.007 29.313 C 1.665 30.658 1.665 32.834 3.007 34.177 C 4.35 35.521 6.53 35.521 7.872 34.177 L 30.607 11.44 C 31.347 10.703 32.653 10.703 33.389 11.438 L 56.128 34.177 C 56.802 34.849 57.681 35.184 58.56 35.184 C 59.44 35.184 60.321 34.849 60.993 34.177 C 62.336 32.834 62.336 30.658 60.993 29.313 Z M 33.195 17.967 C 32.534 17.307 31.464 17.307 30.806 17.967 L 10.807 37.961 C 10.491 38.276 10.312 38.707 10.312 39.157 L 10.312 53.739 C 10.312 57.161 13.086 59.935 16.508 59.935 L 26.41 59.935 L 26.41 44.601 L 37.588 44.601 L 37.588 59.935 L 47.49 59.935 C 50.912 59.935 53.686 57.161 53.686 53.739 L 53.686 39.157 C 53.686 38.707 53.509 38.276 53.192 37.961 L 33.195 17.967 Z' style='paint-order: fill;'></path>
		</svg>
	);
}
