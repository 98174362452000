import { IEventProps } from 'Shared/backend/eventPage/EventPage';
import { ClockCircle } from 'Shared/backend/icons/ClockCircle';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { StatusArchived } from 'Shared/backend/icons/StatusArchived';
import { StatusDraft } from 'Shared/backend/icons/StatusDraft';
import { StatusPublished } from 'Shared/backend/icons/StatusPublished';
import { Match, Switch } from 'solid-js';

export function StatusIcons(eventProps:IEventProps) 
{
	const event = () => eventProps.store;

//XXX low priority feature add status text to icons on hover

	return (
		<div class='statusIcons'>
			<Switch>
				<Match when={event().status=='draft'}>
					<StatusDraft />
				</Match>
				<Match when={event().status=='published'}>
					<StatusPublished />
				</Match>
				<Match when={event().status=='unpublished'}>
					<StatusArchived />
				</Match>
				<Match when={event().status=='scheduled'}>
					<Switch>
						<Match when={event().scheduleDate}>
							<ClockCircle />
						</Match>
						<Match when={true}>
							<ExclamationCircle />
						</Match>
					</Switch>
				</Match>
				<Match when={true}>
					<ExclamationCircle />
				</Match>
			</Switch>
		</div>
	);
}
